import React, {FC, ReactNode} from "react";
import {Grid, Paper} from "@mui/material";
import {ButtonGroup} from "@mui/material";





export const GridControls: FC<{ children: ReactNode }> = ({ children }) => {

  return (
    <GridControlsRaw justifyContent={"flex-end"}>
      <Grid item>
        <ButtonGroup variant={"contained"}>
          {children}
        </ButtonGroup>
      </Grid>
    </GridControlsRaw>
  )
}

export const GridControlsRaw: FC<{ children: ReactNode, justifyContent: string }> = ({ children, justifyContent }) => {

  const styles = {
    controlsContainer: {
      padding: "10px",
      minHeight: "38px",
      margin: "0 16px",
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      position: "sticky",
      top: 0,
      zIndex: 4,
    },
  }

  return (
    // @ts-ignore
    <Paper style={styles.controlsContainer}>
      <Grid container justifyContent={justifyContent} spacing={1}>
        {children}
      </Grid>
    </Paper>
  )
}