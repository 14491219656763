import React, {FC, ReactNode, useState} from "react";
import placeholder from '../../assets/image_holder_x.jpg'
import {Box, Button, CardMedia, FormHelperText, Paper} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GalleryPicker from "../Gallery/GalleryPicker";
import {GalleryItem} from "../Gallery/helpers";
export const InlineImageSelector: FC<{
  item: GalleryItem,
  setItem: React.Dispatch<React.SetStateAction<GalleryItem>>,
  title: string,
  type: 'image/*' | 'video/*',
  helpText?: string | ReactNode,
}> = ({
  item,
  setItem,
  title,
  type,
  helpText,
}) => {

  const [galleryAnchor, setGalleryAnchor] = useState(null)

  const styles = {
    container: {
      width: "100%",
      height: "160px",
    },
    preview: {
      borderRadius: "5px",
      width: "100%",
      height: "120px",
      objectFit: "cover",
    },
    upload: {
      width: "100%",
      height: "40px",
      backgroundColor: "rgba(255, 255, 255, 1) !important",
    }
  }

  const itemReady = (item: GalleryItem) => {
    setItem(item)
  }

  const onDismiss = () => {
    setGalleryAnchor(null)
  }

  return (
    <Paper sx={styles.container}>
      {type === 'image/*' && (
        // @ts-ignore
        <CardMedia style={styles.preview} image={item?.location ?? placeholder} alt={title}/>
      )}
      {type === 'video/*' && (
        // @ts-ignore
        <video style={styles.preview} src={item?.location ?? placeholder}/>
      )}
      <Button variant={"outlined"} color={"primary"} sx={styles.upload} startIcon={<CloudUploadIcon/>} onClick={(e) => setGalleryAnchor(e.currentTarget) }>
        Upload {title}
      </Button>
      <GalleryPicker itemReady={itemReady} onDismiss={onDismiss} anchor={galleryAnchor} type={'image/*'}/>
      { helpText && (
        <>
          { typeof helpText === 'string' ? (
            <FormHelperText>
              {helpText}
            </FormHelperText>
          ) : (
            <Box sx={{marginLeft:"8px"}}>
              {helpText}
            </Box>
          )}
        </>
      )}
    </Paper>
  )
}