import React, {FC, SetStateAction, useState} from "react";
import {BrandData} from "../../api/types";
import {GridContext} from "../Reusable/InfiniteItemGrid";
import {brandsDeleteBrand} from "../../api/services";
import {toast} from "react-toastify";
import {LayoutWithSidebarWrapper, sidebarStyles} from "../Reusable/LayoutWithSidebars";
import {DialogConfirmation} from "../Reusable/DialogConfirmation";
import {BrandUpdateDialog} from "./BrandUpdateDialog";
import {SidebarItemSummary} from "../Reusable/SidebarItemSummary";
import {Divider} from "@mui/material";
import {Button} from "@mui/material";
import {useDispatch} from "react-redux";
import {getAssignableBrandsDataThunk} from "../../redux/slices/brandSlice";


export const BrandsSidebar: FC<{
  selectedBrand: BrandData,
  setSelectedBrand: React.Dispatch<SetStateAction<BrandData>>,
  context: GridContext,
}> = ({
  selectedBrand,
  setSelectedBrand,
  context,
}) => {

  const dispatch = useDispatch()

  context.create = () => {
    setSelectedBrand(null)
    setTimeout(() => {
      setEditDialogOpen(true)
    }, 10)
  }
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)


  const deleteBrandConfirmed = async () => {
    await brandsDeleteBrand(selectedBrand.id)
    toast.success("Brand deleted successfully")
    dispatch(getAssignableBrandsDataThunk())
  }

  const onActionDismiss = () => {
    setEditDialogOpen(false)
    setDeleteDialogOpen(false)
  }

  const brandUpdated = () => {
    if (selectedBrand !== null) {
      toast.success("Brand updated successfully")
    } else {
      toast.success("Brand created successfully")
    }
    context.refreshGrid()
    dispatch(getAssignableBrandsDataThunk())
  }

  return (
    <LayoutWithSidebarWrapper>
      <DialogConfirmation
        title={"Are you sure?"}
        description={"Are you sure you want to delete this brand?"}
        yesTitle={"Delete"}
        noTitle={"Cancel"}
        open={deleteDialogOpen}
        onPressYes={deleteBrandConfirmed}
        onPressNo={onActionDismiss}
        onDismiss={onActionDismiss}
      />
      <BrandUpdateDialog
        brand={selectedBrand}
        open={editDialogOpen}
        onSave={brandUpdated}
        onDismiss={onActionDismiss}
      />
      { selectedBrand && (
        <>
          <SidebarItemSummary name={selectedBrand.name} created={selectedBrand.createdAt}/>
          <Divider style={sidebarStyles.divider}/>
          <Button variant={"outlined"} style={sidebarStyles.mainButton} onClick={() => {
            setEditDialogOpen(true)
          }}>Edit</Button>
          <Button variant={"outlined"} style={sidebarStyles.mainButton} onClick={() => {

            setDeleteDialogOpen(true)
          }}>Delete</Button>
          <Divider style={sidebarStyles.divider}/>
        </>
      )}
    </LayoutWithSidebarWrapper>
  )
}