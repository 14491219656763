import { CircularProgress } from '@mui/material'
import { Box } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import 'src/components/Dashboard/Dashboard.css'
import {
  dashboardGetCreatorActivitySummary,
  dashboardGetOrganizationDataCreatorChartsByRange,
} from '../../api/services'
import {
  CreatorActivitySummary,
  CreatorActivitySummaryIndividual,
  DashboardCharts, DashboardGetOrganizationDataCreatorChartsByRangeQueryParams,
} from '../../api/types'
import styled from '@emotion/styled'
import { CreatorsTable } from 'src/components/CreatorDashboard/CreatorsTable'
import { ProfileCard } from 'src/components/CreatorDashboard/ProfileCard'
import SummaryOverview from 'src/components/Dashboard/SummaryOverview'
import CloseSVG from '../../assets/x.svg'
import { ProfileActivity } from 'src/components/CreatorDashboard/ProfileActivity'
import { useAppContext } from 'src/contexts/PermissionContext'
import {DateRangeSummaryFilter, defaultFilterDateRange} from "../../components/Dashboard/DateRangeSummaryFilter";
import {FilterType} from "../Dashboard";
import {ExperienceStatisticsSummaryTable} from "../../components/Reusable/ExperienceStatisticsSummaryTable";

const LoadingContainer = styled.div(() => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const FilterWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& img': {
    width: 40,
    height: 40,
    marginTop: 10,
    marginRight: 10,
    cursor: 'pointer',
  },
}))

const sortings = [
  {
    value: 'visitors',
    text: 'Unique visitors',
  },
  {
    value: 'timeSpent',
    text: 'Time spent',
  },
  {
    value: 'ctr',
    text: 'CTR',
  },
  {
    value: 'conversion',
    text: 'Conversion',
  },
  {
    value: 'salesValue',
    text: 'Sales value',
  },
]

export const CreatorsList = () => {

  const { getQueryParam, setQueryParam, removeQueryParam } = useAppContext()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isUserCardOpened, setIsUserCardOpened] = useState(false)
  const [sort, setSort] = useState<string>(sortings[0].text)
  const [openSort, setOpenMenuSort] = useState<boolean>(false)

  const actionRef = useRef<any>(null)

  const [filterData, setFilterData] =
    useState<DashboardGetOrganizationDataCreatorChartsByRangeQueryParams>(defaultFilterDateRange)

  const [creator, setCreator] = useState<CreatorActivitySummaryIndividual>()
  const [dashboardCharts, setDashboardCharts] = useState<DashboardCharts>()
  const [creatorActivitySummary, setCreatorActivitySummary] = useState<CreatorActivitySummary>()

  useEffect(() => {

    setFilterData({
      ...filterData,
      creatorId: getQueryParam('id'),
    })
  }, [getQueryParam('id')])

  const getDashboardCreatorData = () => {
    dashboardGetCreatorActivitySummary({
      from: filterData.from,
      to: filterData.to,
      brandId: filterData?.brandId,
      creatorId: filterData.creatorId,
      experienceId: filterData.experienceId,
      ownerType: "Creator",
    }).then(d => setCreatorActivitySummary(d.data))
    dashboardGetOrganizationDataCreatorChartsByRange({
      from: filterData.from,
      to: filterData.to,
      brandId: filterData?.brandId,
      creatorId: filterData.creatorId,
    }).then(res => {
      setDashboardCharts(res.data)
      setIsLoading(false)
    })
  }

  const handleFilterDataChange = (type: FilterType, value: any) => {
    setFilterData({
      ...filterData,
      from: value.start,
      to: value.end,
    })
  }

  useEffect(() => {
    getDashboardCreatorData()
  }, [filterData])

  const handleUserCard = (id: string) => {
    setIsUserCardOpened(true)
    const clickedItem = creatorActivitySummary.creators.filter(
      elem => elem.id === id,
    )[0]
    setCreator(clickedItem)
    setQueryParam('id', clickedItem.id)
  }

  useEffect(() => {
    const creatorParamId = getQueryParam('id')
    if (creatorParamId && creatorActivitySummary) {
      const creatorData = creatorActivitySummary.creators.filter(
        elem => elem.id == creatorParamId,
      )[0]
      if (creatorData) {
        setIsUserCardOpened(true)
        setCreator(creatorData)
      }
    }
  }, [creatorActivitySummary?.creators])

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    )
  }
  return (
    <div className="dashboard">
      {isUserCardOpened && (
        <div className="sticky-left-bar">
          <>
            <ProfileCard data={creator} />
            <ProfileActivity data={creator} />
          </>
        </div>
      )}
      {!isUserCardOpened ? (
        <Box className="summary-data">
          {/*<FilterWrapper className="filters-wrapper">*/}
          {/*  */}
          {/*  <DateRangeSummaryFilter*/}
          {/*    filterData={filterData}*/}
          {/*    onSelected={(range) => {*/}
          {/*      handleFilterDataChange('TIMERANGE', range)*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  /!* we'll use those later, don't remove the code *!/*/}

          {/*  /!*<CreatorsSearchStyled />*!/*/}
          {/*  /!*<SortButton*!/*/}
          {/*  /!*  size="small"*!/*/}
          {/*  /!*  variant="outlined"*!/*/}
          {/*  /!*  ref={actionRef}*!/*/}
          {/*  /!*  onClick={() => setOpenMenuSort(true)}*!/*/}
          {/*  /!*  endIcon={<ExpandMoreTwoTone fontSize="small" />}*!/*/}
          {/*  /!*>*!/*/}
          {/*  /!*  {sort}*!/*/}
          {/*  /!*</SortButton>*!/*/}
          {/*  <Menu*/}
          {/*    anchorEl={actionRef.current}*/}
          {/*    onClose={() => setOpenMenuSort(false)}*/}
          {/*    open={openSort}*/}
          {/*    anchorOrigin={{*/}
          {/*      vertical: 'bottom',*/}
          {/*      horizontal: 'right',*/}
          {/*    }}*/}
          {/*    transformOrigin={{*/}
          {/*      vertical: 'top',*/}
          {/*      horizontal: 'right',*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {sortings.map(_sort => (*/}
          {/*      <MenuItem*/}
          {/*        key={_sort.value}*/}
          {/*        onClick={() => {*/}
          {/*          setSort(_sort.text)*/}
          {/*          // handleSort(_sort.value)*/}
          {/*          setOpenMenuSort(false)*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        {_sort.text}*/}
          {/*      </MenuItem>*/}
          {/*    ))}*/}
          {/*  </Menu>*/}
          {/*</FilterWrapper>*/}
          <CreatorsTable
            creators={creatorActivitySummary?.creators}
            setCreators={creators => {
              setCreatorActivitySummary({
                ...creatorActivitySummary,
                creators: creators,
              })
            }}
            setIsUserCardOpened={handleUserCard}
          />
        </Box>
      ) : (
        <>
          <div className="summary-data">
            <FilterWrapper className="filters-wrapper">
              <DateRangeSummaryFilter
                filterData={filterData}
                onSelected={(range) => {
                  handleFilterDataChange('TIMERANGE', range)
                }}
              />
              <img
                src={CloseSVG}
                alt="close"
                onClick={() => {
                  setIsUserCardOpened(false)
                  removeQueryParam('id')
                }}
              />
            </FilterWrapper>
            <SummaryOverview charts={dashboardCharts} />
            <ExperienceStatisticsSummaryTable
              from={filterData.from}
              to={filterData.to}
              experienceId={filterData.experienceId}
              creatorId={filterData.creatorId}
              brandId={filterData.brandId}
              ownerType={"Creator"}
              tableType={"Full"}
            />
          </div>
        </>
      )}
    </div>
  )
}
