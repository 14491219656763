import React from 'react'
import { Video } from 'src/redux/slices/sequenceSlice'
import styles from '../SequenceDemo.module.scss'
interface ISequenceHeaderVideo {
  currentCardVideo: Video
}

const SequenceHeaderVideo: React.FC<ISequenceHeaderVideo> = ({
  currentCardVideo,
}) => {
  return (
    <>
      {currentCardVideo?.videoLocation ? (
        <div className={styles.mobileBox__video}>
          <video
            controls
            className={styles.headingImage}
            src={currentCardVideo.videoLocation}
          ></video>
        </div>
      ) : (
        <div className={styles.mobileBox__video}>
          <p>Upload video from sidebar</p>
        </div>
      )}
    </>
  )
}

export default SequenceHeaderVideo
