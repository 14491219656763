import { useEffect } from 'react'
import { useHistory } from 'react-router'
import {instagramRevokeAuthorization} from "../../api/services";

function InstagramDeauthorize() {
  let router = useHistory()

  useEffect(() => {
    instagramRevokeAuthorization().then(r => {
      router.push("/content/gallery")
    })
  }, [])

  return (
    <>
    </>
  )
}

export default InstagramDeauthorize
