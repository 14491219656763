import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import {Typography} from "@mui/material";
import {instagramSubmitAuthorizationCode} from "../../api/services";
import {INSTAGRAM_REDIRECT_URI} from "../../constants";

function InstagramHandleCode() {
  let location = useLocation()
  let router = useHistory()

  let code = new URLSearchParams(location.search).get("code")

  useEffect(() => {
    if (code) {
      instagramSubmitAuthorizationCode({ code: code, redirectUri: INSTAGRAM_REDIRECT_URI }).then(r => {
        router.push("/content/gallery")
      })
    }
  }, [code])

  return (
    <>
      { code ? (
        <>

        </>
        ) : (
        <>
          <Typography variant={"body1"}>
            Missing code parameter...
          </Typography>
        </>
      )}
    </>
  )
}

export default InstagramHandleCode
