import React, {FC, useEffect, useState} from "react";
import {LayoutWithSidebar} from "../Reusable/LayoutWithSidebars";
import {gridContextEmpty} from "../Reusable/InfiniteItemGrid";
import {LiveStreamsSidebar} from "./LiveStreamsSidebar";
import {LiveStreamsMain} from "./LiveStreamsMain";

export const LiveStreams: FC<{
  tabOverride?: string,
}> = ({ tabOverride }) => {


  const [selectedLivestream, setSelectedLivestream] = useState(null)
  const [sidebarOpen, setSidebarOpen] = useState(true)

  // @ts-ignore
  const [context, setContext] = useState(gridContextEmpty)

  useEffect(() => {
    setSidebarOpen(Boolean(selectedLivestream))
  }, [selectedLivestream])

  useEffect(() => {
    if (!sidebarOpen) {
      setSelectedLivestream(null)
    }
  }, [sidebarOpen])

  return (
    <>
      <LayoutWithSidebar
        main={<LiveStreamsMain
          tabOverride={tabOverride}
          selectedLivestream={selectedLivestream}
          setSelectedLivestream={setSelectedLivestream}
          context={context}
        />}
        sidebar={<LiveStreamsSidebar selectedLivestream={selectedLivestream}
                                     context={context}
                                     tabOverride={tabOverride} />}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    </>
  )
}



