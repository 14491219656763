import {
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import styled from '@emotion/styled'
import Gauge from '../Dashboard/Templates/RoundChart'
import {CreatorActivitySummaryIndividual} from 'src/api/types'
import { creatorsSetRequiresApproval } from '../../api/services'
import { toast } from 'react-toastify'
import React, {FC} from 'react'


const CreatorAvatar = styled.img(() => ({
  minWidth: '45px',
  width: '45px',
  height: '45px',
  borderRadius: '50%',
  objectFit: 'cover',
}))

export const CreatorsTable: FC<{
  creators: CreatorActivitySummaryIndividual[]
  setCreators: (creators: CreatorActivitySummaryIndividual[]) => void
  setIsUserCardOpened?: (id: string) => void
}> = ({
  creators,
  setCreators,
  setIsUserCardOpened,
}) => {
  const onCreatorNeedsApprovalChecked = (creatorId, needsApproval) => {
    if (creatorId) {
      creatorsSetRequiresApproval({
        creatorId: creatorId,
        requiresApproval: needsApproval,
      }).then(() => {
        toast.success('Settings updated')
        creators.find(c => c.id === creatorId).needsApproval = needsApproval
        setCreators(creators)
      })
    }
  }

  return (
    <TableContainer component={Card}>
      <Table size={"small"}>
        <TableHead>
          <TableRow hover>
            <TableCell></TableCell>
            <TableCell>Creator</TableCell>
            <TableCell>Subscribers</TableCell>
            <TableCell>Campaigns</TableCell>
            <TableCell>Unique visitors</TableCell>
            <TableCell>Conversion</TableCell>
            <TableCell>Sales</TableCell>
            <TableCell>Needs approval</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {creators?.map(creator => (
            <TableRow hover key={creator.id} onClick={() => setIsUserCardOpened(creator.id)} className={"summary-table-clickable"}>
              <TableCell>
                <Gauge
                  circleRatio={0.8}
                  value={creator.score.current}
                  strokeWidth={10}
                  variant="SCORE"
                >
                  <CreatorAvatar src={creator.profilePicture} />
                </Gauge>
              </TableCell>
              <TableCell>{creator.displayName}</TableCell>
              <TableCell>{creator.subscribers?.displayValue}</TableCell>
              <TableCell>{creator.publishedExperiencesTotal}</TableCell>
              <TableCell>{creator.visitors?.displayValue}</TableCell>
              <TableCell>{creator.conversionRate?.displayValue}</TableCell>
              <TableCell>{creator.sales?.displayValue}</TableCell>
              <TableCell>
                <Checkbox
                  checked={creator.needsApproval}
                  onChange={(e, checked) => {
                    onCreatorNeedsApprovalChecked(creator.id, checked)
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
