import React from 'react'
import CreatorsTable from 'src/components/Account/Creators/CreatorsTable'
import MemberInvitationForm from "../OrganizationMembers/MemberInvitationForm";
import {organizationInviteCreator} from "../../../api/services";
import {useDispatch} from "react-redux";
import {getPendingInvitationsThunk} from "../../../redux/slices/organizationSlice";

interface Props {
  canInvite: boolean
}

const Creators = ({ canInvite }: Props) => {

  const dispatch = useDispatch()

  return (
    <>
      <MemberInvitationForm
        label={"Invite creators by email"}
        handleRefresh={() => dispatch(getPendingInvitationsThunk())}
        handleInvite={(emails) => organizationInviteCreator({ emails: emails })}
        canInvite={canInvite}
      />
      <CreatorsTable />
    </>
  )
}

export default Creators
