import React, { FC, ReactNode } from 'react'
import { LayoutTabDefinition } from './ContentLayoutWithTabs'
import { GridSortControl, SortConfiguration } from './GridSortControl'
import { FilterConfiguration, GridFilterControl } from './GridFilterControl'
import {Box, Grid, Tab, Tabs} from '@mui/material'
import { GridControlsRaw } from './GridControls'
import { experimentalStyled } from '@mui/material/styles'
import {useIsMobileLayout} from "../../utils/layoutConstants";
const StyledTabList = experimentalStyled(Tabs)(
  () => `
    .MuiTabs-indicator {
      box-shadow: none;
      border: none;
      background-color: #424242;
    }
    .Mui-selected {
     background-color: #424242;
  }
    `,
)

export const GridControlsWithTabs: FC<{
  tabs: LayoutTabDefinition[]
  tab: string
  onTabChange: (string) => void
  sort?: SortConfiguration
  filter?: FilterConfiguration
  actions?: ReactNode
}> = ({ tabs, tab, onTabChange, sort, filter, actions }) => {

  const isMobile = useIsMobileLayout()

  return (
    <GridControlsRaw justifyContent={'space-between'}>
      {tabs && (
        <Grid item xs={12} md={6}>
          <Box sx={{ maxWidth: { xs: 320, sm: 480 } }}>
            <StyledTabList
              variant={'standard'}
              value={tab}
              onChange={(e, v) => onTabChange(v)}
              indicatorColor={'secondary'}
              textColor={'secondary'}
              sx={{
                '& .MuiTab-root': isMobile ? {
                  paddingLeft: '8px',
                  paddingRight: '8px',
                } : {

                }
              }}
            >
              {tabs.map(t => (
                <Tab key={t.name} label={t.title} value={t.name} />
              ))}
            </StyledTabList>
          </Box>
        </Grid>
      )}
      {filter && (
        <Grid item>
          <GridFilterControl filter={filter} />
        </Grid>
      )}
      {sort && (
        <Grid item>
          <GridSortControl sort={sort} />
        </Grid>
      )}
      {actions && (
        <Grid item>
          {/*<ButtonGroup variant={"contained"}>*/}
          {actions}
          {/*</ButtonGroup>*/}
        </Grid>
      )}
    </GridControlsRaw>
  )
}
