import React, {FC, useEffect} from "react";
import {MenuItem, TextField} from "@mui/material";
import {FormControl} from "@mui/material";


export const DialogSimpleFieldDropdown: FC<{
  label: string,
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  values: {
    key: string,
    label: string,
  }[],
  props?: any,
  helperText?: string,
}> = ({
  label,
  value,
  setValue,
  values,
  props,
  helperText,
}) => {

  const styles = {
    container: {
    }
  }

  useEffect(() => {
    if (!value) {
      setValue(values[0].key)
    }
  }, [value])

  return (
    <FormControl {...props} style={styles.container}>
      <TextField
        select
        label={label}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        helperText={helperText}
      >
        {values.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
}