import {FC, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import {
  setAccessToken, setAuthEmail, setAuthName, setAuthPicture,
  setEmailVerification,
  setIsAuthenticated,
  setLogoutFunction
} from "../../redux/slices/authSlice";
import {RootState} from "../../redux/store";


export const Auth0Login: FC<{}> = ({}) => {


  const dispatch = useDispatch()


  const {
    user,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0()

  const isAppAuthenticated = useSelector((state: RootState) => state.authReducer.isAuthenticated)

  useEffect(() => {
    (async function login() {
      if (!user && !isLoading && !isAuthenticated && !isAppAuthenticated) {
        await loginWithRedirect({
          appState: {
            returnTo: window.location.pathname + window.location.search + window.location.hash,
          },
        })
      }
    })()
  }, [isLoading, isAppAuthenticated])

  const setUserToken = async () => {
    let token = await getAccessTokenSilently()
    dispatch(setAccessToken({ token }))
    dispatch(setIsAuthenticated({ isAuthenticated: true }))
    dispatch(setLogoutFunction({ logout: logout }))
  }

  if (isAuthenticated) {
    setUserToken()
  }

  useEffect(() => {
    if (!isAppAuthenticated) {
      dispatch(setEmailVerification({ verified: user?.email_verified }))
      dispatch(setAuthEmail({ email: user?.email }))
      dispatch(setAuthName({ name: user?.name }))
      dispatch(setAuthPicture({ picture: user?.picture }))
    }
  }, [user, isAppAuthenticated])

  return (
    <>
    </>
  )
}