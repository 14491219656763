import React, {FC} from "react";
import {DialogContentText} from "@mui/material";
import {Button} from "@mui/material";
import {DialogSimple} from "./DialogSimple";

export const DialogConfirmation: FC<{
  title: string,
  description: string,
  yesTitle: string,
  noTitle: string,
  open: boolean,
  onPressYes: () => void,
  onPressNo: () => void,
  onDismiss: () => void,
}
> = ({
       title, description, yesTitle, noTitle, open, onPressYes, onPressNo, onDismiss
     }) => {

  return (
    <DialogSimple
      open={open}
      onDismiss={onDismiss}
      content={
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>}
      title={title}
      maxWidth={"xs"}
      actions={
        <>
          <Button onClick={() => {
            onPressYes()
            onDismiss()
          }}>{yesTitle}</Button>
          <Button onClick={() => {
            onPressNo()
            onDismiss()
          }} autoFocus>{noTitle}</Button>
        </>
      }
    />
  )
}