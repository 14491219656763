import React, {FC, useEffect, useState} from "react";
import {
  experienceFollowerShareDoShare,
  experienceFollowerShareGetData,
  mediaStorageGetSingle,
  sequencePollGetFollowerShareMediaAssets,
  sequencePollUpdateFollowerShareMediaAssets,
} from "../../api/services";
import {GalleryItem} from "../Gallery/helpers";
import type {ExperienceFollowerShareData} from "../../api/types";
import {toast} from "react-toastify";
import {Box, Button, Grid, Tooltip, Typography} from "@mui/material";
import {InlineImageSelector} from "../Reusable/InlineImageSelector";
import {DialogSimpleFieldText} from "../Reusable/DialogSimpleFieldText";
import InfoIcon from "@mui/icons-material/Info";
import TaskIcon from "@mui/icons-material/Task";
import SendIcon from "@mui/icons-material/Send";


export const ExperienceShareWithFollowers: FC<{
  onDismiss: () => void,
  experienceId: string,
}> = ({
  onDismiss,
  experienceId,
}) => {

  const [poster, setPoster] = useState<GalleryItem | null>(null)
  const [note, setNote] = useState<string>('')
  const [followerShareData, setFollowerShareData] = useState<ExperienceFollowerShareData>()

  useEffect(() => {
    if (experienceId) {
      sequencePollGetFollowerShareMediaAssets(experienceId).then(d => {
        if (d.data.posterMediaStorageItemId) {
          mediaStorageGetSingle(d.data.posterMediaStorageItemId).then(m => {
            setPoster(m.data)
          })
        }
        if (d.data.description) {
          setNote(d.data.description)
        }
      })
      getShareData()
    }
  }, [experienceId]);

  const getShareData = () => {

    experienceFollowerShareGetData(experienceId).then(d => {
      setFollowerShareData(d.data)
    })
  }

  const onUpdateMediaAssets = () => {

    sequencePollUpdateFollowerShareMediaAssets(experienceId, {
      experienceId: experienceId,
      description: note,
      posterMediaStorageItemId: poster?.id,
    }).then(() => {
      toast.success("Poster and description updated")
    })

  }

  const onSubmit = () => {

    experienceFollowerShareDoShare(experienceId).then(d => {
      toast.success(`Shared with ${followerShareData.followersNotShared} followers`)
      getShareData()
    })
  }

  const tooltip = (text) => {
    return (
      <Tooltip title={text}>
        <InfoIcon sx={{ position: "relative", top: "8px", marginLeft: "10px" }}/>
      </Tooltip>
    )
  }

  return (
    <>
      { experienceId && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{marginBottom: "24px"}}>
              <Box sx={{ maxWidth: "400px" }}>
                <InlineImageSelector
                  item={poster}
                  setItem={setPoster}
                  title={"Poster"}
                  type={'image/*'}
                  helpText={
                    <Typography sx={{color:"rgb(34, 51, 84)"}}>
                      Upload a poster to promote the video experience
                      {tooltip("This poster is included on the email received by followers. It is recommended for it to look dynamic and interactive.")}
                    </Typography>}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <DialogSimpleFieldText
                label={"Add or paste text here"}
                value={note}
                setValue={setNote}
                error={false}
                multiline={true}
                helperText={
                  <Typography sx={{color:"rgb(34, 51, 84)"}}>
                    Add a creative note to promote the video experience
                    {tooltip("Add description about the campaign to be included on the email received by followers.")}
                  </Typography>
                }
              />
            </Grid>
            { followerShareData && (
              <Grid item xs={12}>
                <Typography>
                  You have a total of {followerShareData.followersTotal} followers. Shared with {followerShareData.followersShared} of them, can share with {followerShareData.followersNotShared}.
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}

      <Grid item xs={12} justifyContent={"end"} alignContent={"end"} container>
        <Button sx={{marginRight: "5px" }} disabled={!poster} onClick={onUpdateMediaAssets} variant={"contained"}>Save poster and description<TaskIcon sx={{height: "18px", marginLeft: "5px"}}/></Button>
        <Button onClick={onSubmit} variant={"contained"} disabled={!followerShareData?.followersNotShared || !poster}>Submit<SendIcon sx={{height: "18px", marginLeft: "5px"}}/></Button>
      </Grid>
    </>
  )
}