import React, {FC, SetStateAction, useEffect, useState} from 'react'
import {
  BrandData,
  ExperienceAvailableApprovalStatus,
  ExperienceAvailableOwnerData,
  SequencePollListData,
} from '../../api/types'
import { ContentLayoutWithTabs } from '../Reusable/ContentLayoutWithTabs'
import { InfiniteItemGrid } from '../Reusable/InfiniteItemGrid'
import {
  brandsGetAssignableBrands,
  sequencePollGetAll,
  sequencePollGetAvailableApprovalStatuses,
  sequencePollGetAvailableOwners,
  sequencePollGetTemplates,
} from '../../api/services'
import { ExperienceListGridComponent } from './ExperienceListGridComponent'
import { useAppContext } from '../../contexts/PermissionContext'
import { Avatar, Grid, useTheme } from '@mui/material'
import {
  ApprovalStatusIcon,
  approvalStatusLabel,
} from '../Approval/ApprovalStatusIcon'
import {useIsMobileLayout} from "../../utils/layoutConstants";

export const ExperienceListMain: FC<{
  tabOverride?: string
  tab: string,
  setTab: React.Dispatch<SetStateAction<string>>,
  selectedExperience: SequencePollListData
  setSelectedExperience: React.Dispatch<
    React.SetStateAction<SequencePollListData>
  >
  context: {
    refreshGrid: () => void
  }
}> = ({
  tabOverride,
  tab,
  setTab,
  selectedExperience,
  setSelectedExperience,
  context,

}) => {
  const { getQueryParam, setQueryParam, removeQueryParam, permissions } =
    useAppContext()

  const isMobile = useIsMobileLayout()

  const [filterBrandId, setFilterBrandId] = useState(getQueryParam('brandId'))
  const [filterCreatorId, setFilterCreatorId] = useState(
    getQueryParam('creatorId'),
  )
  const [filterApprovalStatus, setFilterApprovalStatus] = useState(
    getQueryParam('approvalStatus'),
  )

  const [activeExperiences, setActiveExperiences] = useState(null)
  const [draftExperiences, setDraftExperiences] = useState(null)
  const [archivedExperiences, setArchivedExperiences] = useState(null)
  const [templateExperiences, setTemplateExperiences] = useState(null)
  const [creatorExperiences, setCreatorExperiences] = useState(null)
  const [creatorManageableExperiences, setCreatorManageableExperiences] = useState(null)

  const [assignableBrands, setAssignableBrands] = useState(null)
  const [availableCreators, setAvailableCreators] =
    useState<ExperienceAvailableOwnerData[]>(null)

  const [availableApprovalStatuses, setAvailableApprovalStatuses] =
    useState<ExperienceAvailableApprovalStatus[]>(null)

  const [isExperienceFromBrandSelected, selectExperienceFromBrand] =
    useState(false)

  useEffect(() => {
    if (assignableBrands === null) {
      if (tabOverride === 'creator') {
        brandsGetAssignableBrands({
          page: 0,
          perPage: 1000,
          creatorOnly: true,
        }).then(b => setAssignableBrands(b.data.content))
      } else {
        brandsGetAssignableBrands({ page: 0, perPage: 1000 }).then(b =>
          setAssignableBrands(b.data.content),
        )
      }
    }
  }, [assignableBrands])

  useEffect(() => {
    if (availableApprovalStatuses == null) {
      if (tabOverride === 'creator') {
        sequencePollGetAvailableApprovalStatuses({ type: 'Creator' }).then(d =>
          setAvailableApprovalStatuses(d.data),
        )
      } else {
        sequencePollGetAvailableApprovalStatuses({ type: 'Member' }).then(d =>
          setAvailableApprovalStatuses(d.data),
        )
      }
    }
  }, [availableApprovalStatuses])

  useEffect(() => {
    if (availableCreators === null) {
      if (tabOverride === 'creator') {
        sequencePollGetAvailableOwners({ type: 'Creator' }).then(d =>
          setAvailableCreators(d.data),
        )
      } else {
        sequencePollGetAvailableOwners({ type: 'Member' }).then(d =>
          setAvailableCreators(d.data),
        )
      }
    }
  }, [availableCreators])

  useEffect(() => {
    let brandId = getQueryParam('brandId')
    let creatorId = getQueryParam('creatorId')
    if (selectedExperience && !brandId && !creatorId) {
      selectExperienceFromBrand(false)
      setQueryParam('id', selectedExperience.sequencePoll.id)

      updateList(activeExperiences, setActiveExperiences)
      updateList(draftExperiences, setDraftExperiences)
      updateList(archivedExperiences, setArchivedExperiences)
      updateList(templateExperiences, setTemplateExperiences)
      updateList(creatorExperiences, setCreatorExperiences)
    }
  }, [selectedExperience])

  /**
   * looks weird but the following function tries to replace list item with updated selectedExperience, which causes grids to re-render properly
   */
  const updateList = (list, setList) => {
    if (list && setList && selectedExperience) {
      let index = list.findIndex(
        i => i.sequencePoll.id == selectedExperience.sequencePoll.id,
      )
      if (index > -1) {
        list[index] = selectedExperience
        setList(list)
      }
    }
  }

  const experiencesListByTab = {
    active: activeExperiences,
    draft: draftExperiences,
    archived: archivedExperiences,
    templates: templateExperiences,
    creator: creatorExperiences,
  }

  useEffect(() => {
    let all = [
      ...(activeExperiences ?? []),
      ...(draftExperiences ?? []),
      ...(archivedExperiences ?? []),
      ...(templateExperiences ?? []),
      ...(creatorExperiences ?? []),
    ]
    if (all.length > 0) {
      let id = getQueryParam('id')
      let brandId = getQueryParam('brandId')
      let creatorId = getQueryParam('creatorId')
      if (!selectedExperience) {
        if (brandId !== null || creatorId !== null) {
          if (!isExperienceFromBrandSelected) {
            const foundByBrand = all.filter(
              e => e.brand.id === brandId || creatorId,
            )
            setSelectedExperience(foundByBrand[0])
            selectExperienceFromBrand(true)
          }
          // if only experience id is selected
        } else if (id !== null) {
          let found = all.filter(e => e.sequencePoll.id === id)
          if (found.length > 0) {
            setSelectedExperience(found[0])
          }
        } else {
          // if nothing was selected and no query param is presented -- select first item based on experience list on tab
          if (!isMobile) {
            setSelectedExperience(experiencesListByTab[tab]?.[0])
          }
        }
      } else {
        if (brandId !== null || creatorId !== null) {
          /*  if (!isExperienceFromBrandSelected) { */
          const foundByBrand = all.filter(
            e => e.brand.id === brandId || creatorId,
          )
          setSelectedExperience(foundByBrand[0])
          selectExperienceFromBrand(true)
          /*  } */
        }
        // if some experience was selected before but now query param id is another -- set this id as active
        else if (selectedExperience.sequencePoll.id !== id) {
          let found = all.filter(e => e.sequencePoll.id === id)
          if (found.length > 0) {
            setSelectedExperience(found[0])
          }
        }
      }
    }
  }, [
    activeExperiences,
    draftExperiences,
    archivedExperiences,
    templateExperiences,
    creatorExperiences,
    tab,
  ])

  context.refreshGrid = () => {
    setActiveExperiences(null)
    setDraftExperiences(null)
    setArchivedExperiences(null)
    setTemplateExperiences(null)
    setCreatorExperiences(null)
  }

  const onFilter = (name, value) => {
    if (value) {
      setQueryParam(name, value)
    } else {
      setSelectedExperience(null)
      removeQueryParam(name)
    }
    if (name === 'brandId') {
      setFilterBrandId(value)
    } else if (name === 'creatorId') {
      setFilterCreatorId(value)
    } else if (name == 'approvalStatus') {
      setFilterApprovalStatus(value)
    }

    context.refreshGrid()
  }

  const showApprovalStatus =
    permissions?.overall?.organizationCreator || tabOverride === 'creator'

  let perPage = 24

  return (
    <ContentLayoutWithTabs
      tab={tab}
      setTab={setTab}
      tabs={[
        {
          name: 'active',
          title: 'Active',
          hidden: tabOverride === 'creator',
          children: (
            <InfiniteItemGrid
              perPage={perPage}
              setItems={setActiveExperiences}
              items={activeExperiences}
              scrollRef={document.getElementById(
                'sidebar-layout-main-content-area',
              )}
              setSelectedItem={setSelectedExperience}
              selectedItem={selectedExperience}
              loadItems={(page, perPage) =>
                sequencePollGetAll({
                  page: page,
                  perPage: perPage,
                  brandId: filterBrandId,
                  ownerId: filterCreatorId,
                  approvalStatus: filterApprovalStatus,
                  status: 'Active',
                  owner: 'Member',
                }).then(d => d.data)
              }
              component={(item, selected) => (
                <ExperienceListGridComponent
                  item={item}
                  selected={selected}
                  showApprovalStatus={showApprovalStatus}
                />
              )}
            />
          ),
        },
        {
          name: 'draft',
          title: 'Draft',
          hidden: tabOverride === 'creator',
          children: (
            <InfiniteItemGrid
              perPage={perPage}
              setItems={setDraftExperiences}
              items={draftExperiences}
              scrollRef={document.getElementById(
                'sidebar-layout-main-content-area',
              )}
              setSelectedItem={setSelectedExperience}
              selectedItem={selectedExperience}
              loadItems={(page, perPage) =>
                sequencePollGetAll({
                  page: page,
                  perPage: perPage,
                  brandId: filterBrandId,
                  ownerId: filterCreatorId,
                  status: 'Draft',
                  approvalStatus: filterApprovalStatus,
                  owner: 'Member',
                }).then(d => d.data)
              }
              component={(item, selected) => (
                <ExperienceListGridComponent
                  item={item}
                  selected={selected}
                  showApprovalStatus={showApprovalStatus}
                />
              )}
            />
          ),
        },
        {
          name: 'archived',
          title: 'Archived',
          hidden: tabOverride === 'creator',
          children: (
            <InfiniteItemGrid
              perPage={perPage}
              setItems={setArchivedExperiences}
              items={archivedExperiences}
              scrollRef={document.getElementById(
                'sidebar-layout-main-content-area',
              )}
              setSelectedItem={setSelectedExperience}
              selectedItem={selectedExperience}
              loadItems={(page, perPage) =>
                sequencePollGetAll({
                  page: page,
                  perPage: perPage,
                  brandId: filterBrandId,
                  ownerId: filterCreatorId,
                  status: 'Archived',
                  approvalStatus: filterApprovalStatus,
                  owner: 'Member',
                }).then(d => d.data)
              }
              component={(item, selected) => (
                <ExperienceListGridComponent
                  item={item}
                  selected={selected}
                  showApprovalStatus={showApprovalStatus}
                />
              )}
            />
          ),
        },
        {
          name: 'templates',
          title: 'Templates',
          hidden: tabOverride === 'creator',
          children: (
            <InfiniteItemGrid
              perPage={perPage}
              setItems={setTemplateExperiences}
              items={templateExperiences}
              scrollRef={document.getElementById(
                'sidebar-layout-main-content-area',
              )}
              setSelectedItem={setSelectedExperience}
              selectedItem={selectedExperience}
              loadItems={(page, perPage) =>
                sequencePollGetTemplates({ page: page, perPage: perPage }).then(d => d.data)
              }
              component={(item, selected) => (
                <ExperienceListGridComponent
                  item={item}
                  selected={selected}
                  showApprovalStatus={showApprovalStatus}
                />
              )}
            />
          ),
        },
        {
          name: 'creator',
          title: 'Creator experiences',
          hidden: tabOverride !== 'creator',
          children: (
            <InfiniteItemGrid
              perPage={perPage}
              setItems={setCreatorExperiences}
              items={creatorExperiences}
              scrollRef={document.getElementById(
                'sidebar-layout-main-content-area',
              )}
              setSelectedItem={setSelectedExperience}
              selectedItem={selectedExperience}
              loadItems={(page, perPage) =>
                sequencePollGetAll({
                  brandId: filterBrandId,
                  ownerId: filterCreatorId,
                  status: 'Active',
                  approvalStatus: filterApprovalStatus,
                  page: page,
                  perPage: perPage,
                  owner: 'Creator',
                }).then(d => d.data)
              }
              component={(item, selected) => (
                <ExperienceListGridComponent
                  item={item}
                  selected={selected}
                  showApprovalStatus={showApprovalStatus}
                />
              )}
            />
          ),
        },
        {
          name: 'creatorManageable',
          title: 'Manageable',
          hidden: tabOverride !== 'creator',
          children: (
            <InfiniteItemGrid
              perPage={perPage}
              setItems={setCreatorManageableExperiences}
              items={creatorManageableExperiences}
              scrollRef={document.getElementById(
                'sidebar-layout-main-content-area',
              )}
              setSelectedItem={setSelectedExperience}
              selectedItem={selectedExperience}
              loadItems={(page, perPage) =>
                sequencePollGetAll({
                  brandId: filterBrandId,
                  ownerId: filterCreatorId,
                  status: 'Active',
                  approvalStatus: filterApprovalStatus,
                  page: page,
                  perPage: perPage,
                  accessType: 'Extended',
                  owner: 'Creator',
                }).then(d => d.data)
              }
              component={(item, selected) => (
                <ExperienceListGridComponent
                  item={item}
                  selected={selected}
                  showApprovalStatus={showApprovalStatus}
                />
              )}
            />
          ),
        },
      ]}
      filter={{
        onFilter: onFilter,
        filter: [
          {
            title: 'Filter by brand',
            name: 'brandId',
            values: assignableBrands,
            active:
              assignableBrands?.filter(b => b.id === filterBrandId)?.[0] ??
              null,
            valueId: b => b.id,
            valueLabel: b => b.name,
            component: value => (
              <FilterBrandIdRow
                brand={value}
                type={tab === 'creator' ? 'Creator' : 'Member'}
              />
            ),
            props: {
              sx: { width: '200px' },
            },
            visible:
              permissions?.overall?.organizationMember &&
              tabOverride !== 'creator',
          },
          {
            title: 'Filter by creator',
            name: 'creatorId',
            values: availableCreators,
            active:
              availableCreators?.filter(c => c.id === filterCreatorId)?.[0] ??
              null,
            valueId: c => c.id,
            valueLabel: c => c.name,
            component: value => <FilterCreatorIdRow creator={value} />,
            props: {
              sx: { width: '200px' },
            },
            visible: tabOverride === 'creator',
          },
          {
            title: 'Filter by approval status',
            name: 'approvalStatus',
            values: availableApprovalStatuses,
            active:
              availableApprovalStatuses?.filter(
                c => c.status === filterApprovalStatus,
              )?.[0] ?? null,
            valueId: c => c.status,
            valueLabel: c => approvalStatusLabel(c.status),
            component: value => <FilterApprovalStatus status={value} />,
            props: {
              sx: { width: '240px' },
            },
            visible:
              tabOverride === 'creator' ||
              permissions?.overall?.organizationCreator,
          },
        ],
      }}
      //  sort={{
      //   onSort: onSort,
      //   active: sort,
      //   direction: sortDirection,
      //   sort: [
      //     // {
      //     //   title: 'Created',
      //     //   name: 'createdAt',
      //     // },
      //     // {
      //     //   title: 'Updated',
      //     //   name: 'updatedAt',
      //     // }
      //   ]
      // }}
    />
  )
}

const FilterBrandIdRow: FC<{
  brand: BrandData
  type: 'Member' | 'Creator'
}> = ({ brand, type }) => {
  const theme = useTheme()

  let count =
    type === 'Member'
      ? brand.statistics.experienceCountActiveMember
      : brand.statistics.experienceCountActiveCreator

  return (
    <Grid container justifyContent={'space-between'} spacing={1}>
      <Grid item>
        <Avatar
          src={brand.logo.location}
          sx={{ width: '24px', height: '24px' }}
        />
      </Grid>
      <Grid item xs sx={{ wordBreak: 'break-word' }}>
        {brand.name}
      </Grid>
      <Grid item>
        <Avatar
          sx={{
            fontSize: '10px',
            width: '24px',
            height: '24px',
            backgroundColor:
              count > 0
                ? theme.colors.primary.main
                : theme.colors.secondary.main,
          }}
        >
          {count}
        </Avatar>
      </Grid>
    </Grid>
  )
}

const FilterApprovalStatus: FC<{
  status: ExperienceAvailableApprovalStatus
}> = ({ status }) => {
  const theme = useTheme()

  return (
    <Grid container justifyContent={'space-between'} spacing={1}>
      <Grid item>
        <ApprovalStatusIcon status={status.status} showTitle={true} />
      </Grid>
      <Grid item xs sx={{ wordBreak: 'break-word' }}>
        {approvalStatusLabel(status)}
      </Grid>
      <Grid item>
        <Avatar
          sx={{
            fontSize: '10px',
            width: '24px',
            height: '24px',
            backgroundColor:
              status.experiences > 0
                ? theme.colors.primary.main
                : theme.colors.secondary.main,
          }}
        >
          {status.experiences}
        </Avatar>
      </Grid>
    </Grid>
  )
}

const FilterCreatorIdRow: FC<{
  creator: ExperienceAvailableOwnerData
}> = ({ creator }) => {
  const theme = useTheme()

  return (
    <Grid container justifyContent={'space-between'} spacing={1}>
      <Grid item>
        <Avatar src={creator.picture} sx={{ width: '24px', height: '24px' }} />
      </Grid>
      <Grid item xs sx={{ wordBreak: 'break-word' }}>
        {creator.name}
      </Grid>
      <Grid item>
        <Avatar
          sx={{
            fontSize: '10px',
            width: '24px',
            height: '24px',
            backgroundColor:
              creator.experiences > 0
                ? theme.colors.primary.main
                : theme.colors.secondary.main,
          }}
        >
          {creator.experiences}
        </Avatar>
      </Grid>
    </Grid>
  )
}
