import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addTagsToCard,
  CardProps,
  CardType,
  changeCardHeader,
  changeCardType,
  changeImageProps,
  HeadingProps, resetCardProductOverride, setCardProductImageHint, setCardProductOverride,
  setCardRenderDirectLinkProduct,
} from 'src/redux/slices/sequenceSlice'
import styles from './SequenceOptions.module.scss'
import './SequenceOptions.css'
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid, IconButton, Pagination,
  Paper,
  TextField, Tooltip,
  Typography
} from '@mui/material'
import { RootState } from 'src/redux/store'
import {
  addLiveStreamToCard,
  removeLiveStreamFromCard,
} from 'src/redux/slices/sequenceSlice'
import CardEditor from './CardEditor/CardEditor'
import CardHeaderContainer from './CardHeaderContainer/CardHeaderContainer'
import { LiveStreamSelector } from '../../LiveStreams/LiveStreamSelector'
import {
  sequencePollGetExperienceEditorConfiguration,
  sequencePollValidateExternalUrl
} from '../../../api/services'
import {
  CardTypeConfiguration, CrossOrganizationLinkRedirect, ExperienceUpdateRequestCardProductOverride,
  Product,
  SequencePollExperienceEditorConfiguration, ShareableUserData,
  ValidateExternalUrlResponse,
} from '../../../api/types'
import { CardTypeSelector } from './CardTypeSelector'
import { DialogSimpleFieldTags } from '../../Reusable/DialogSimpleFieldTags'
import {upload} from "../../Gallery/helpers";
import {getToken} from "../../../services/apiServices";
import {API_URL} from "../../../constants";
import {toast} from "react-toastify";

import EditIcon from '@mui/icons-material/Edit';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {DialogProductOverride} from "./DialogProductOverride";
import {clipboardCopy} from "../../../utils/formUtils";
import _ from "lodash";
import {InlineImage} from "../../Reusable/InlineImage";

const SequenceOptions: React.FC<{
  link: ShareableUserData,
}> = ({
  link,
}) => {
  const dispatch = useDispatch()
  const currentCard = useSelector(
    (state: RootState) => state.sequenceReducer.currentProps.currentCard,
  )

  const liveStreamEditable = useSelector(
    (state: RootState) => state.sequenceReducer.liveStreamEditable,
  )

  const sequencePollId = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceId,
  )

  const [product, setProduct] = useState<Product>()
  const [productOverrideFormData, setProductOverrideFormData] = useState<ExperienceUpdateRequestCardProductOverride>()
  const [productOverrideDialogVisible, setProductOverrideDialogVisible] = useState(false)
  const [selectedProductImage, setSelectedProductImage] = useState(0)
  const [directLinkAffiliateData, setDirectLinkAffiliateData] = useState<CrossOrganizationLinkRedirect>()

  const [configuration, setConfiguration] =
    useState<SequencePollExperienceEditorConfiguration>()
  const [cardConfiguration, setCardConfiguration] =
    useState<CardTypeConfiguration>()

  const [isUploading, setIsUploading] = useState(false)

  useEffect(() => {
    if (product?.images) {
      let hint = product?.images[selectedProductImage]
      if (hint !== currentCard.productImageHint) {
        dispatch(setCardProductImageHint(hint))
      }
    }
  }, [selectedProductImage])

  useEffect(() => {
    if (currentCard?.productImageHint && product) {
      let index = product.images.indexOf(currentCard.productImageHint)
      if (index != selectedProductImage) {
        setSelectedProductImage(index)
      }
    } else {
      setSelectedProductImage(0)
    }
  }, [currentCard?.productImageHint, product])

  useEffect(() => {
    if (!configuration) {
      sequencePollGetExperienceEditorConfiguration().then(d =>
        setConfiguration(d.data),
      )
    } else if (currentCard) {
      setCardConfiguration(
        configuration.cardTypeConfigurations[currentCard?.cardType?.cardType],
      )
    }
  }, [configuration, currentCard])

  // @ts-ignore
  const [urlText, setUrlText] = useState(currentCard?.cardType?.directLinkUrl)
  const [urlValidation, setUrlValidation] = useState<ValidateExternalUrlResponse>()
  const [urlExplanation, setUrlExplanation] = useState<string>()
  const [isProductLoading, setIsProductLoading] = useState(false)

  const [selectedStream, setSelectedStream] = useState(null)
  const { tags } = useSelector(
    (state: RootState) => state.sequenceReducer.currentProps.currentCard,
  )

  const handleChange = (options: HeadingProps) => {
    dispatch(changeCardHeader({ cardId: currentCard.cardId, options }))
  }

  const handleCardType = (options: CardProps) => {
    dispatch(changeCardType({ cardId: currentCard.cardId, options }))
  }

  const handleSelectOneStream = (stream): void => {
    if (stream) {
      if (selectedStream?.id !== stream.id) {
        setSelectedStream(stream)
        dispatch(addLiveStreamToCard(stream))
      }
    } else {
      setSelectedStream(null)
      // @ts-ignore
      dispatch(removeLiveStreamFromCard())
    }
  }

  const useCurrentProductImageAsCardImage = async () => {

    let image = product.images[selectedProductImage]

    try {

      setIsUploading(true)

      // blobs don't work through swagger client :(

      let blob = await fetch(`${API_URL}/bo/mediastorage/proxy/${btoa(image)}`, {
        headers: {
          "Authorization": 'Bearer ' + getToken(),
        }
      }).then(r => r.blob())

      let file = new File([blob], `product-item.jpg`)
      // @ts-ignore
      file.label = `Product item: product-item.jpg`
      let uploaded = await upload([file], () => {}, 'DIRECT')
      dispatch(
        changeImageProps({
          cardId: currentCard.cardId,
          props: uploaded[0],
        }),
      )
    } catch (e) {
      toast.error("Error using specified image as card image. Try again or try a different card")

    } finally {
      setIsUploading(false)
    }

  }

  const validateUrl = async (url) => {

    setProductOverrideFormData(undefined)

    if (url) {
      setIsProductLoading(true)
      setProduct(undefined)
      setDirectLinkAffiliateData(undefined)
      if (currentCard?.cardType?.directLinkUrl != url) {
        setSelectedProductImage(0)
        dispatch(setCardProductImageHint(undefined))
      }

      let excludeOrigins = []
      if (link) {
        excludeOrigins = link.origins
      }
      let response = await sequencePollValidateExternalUrl({ url: url ?? "", excludeOrigins: excludeOrigins }, { experienceId: sequencePollId })

      setUrlValidation(response.data)

      if (response.data.canOpen) {
        if (response.data.affiliate) {
          setDirectLinkAffiliateData(response.data.affiliate)
        } else {
          setDirectLinkAffiliateData(undefined)
        }
        if (response.data.product) {
          setUrlExplanation(undefined)
          setProduct(response.data.product)
          setProductOverrideFormData(currentCard.productOverride ?? {
            imageEnabled: true,
            title: response.data.product.title,
            subtitle: response.data.product.subtitle,
            price: response.data.product.price,
            oldPrice: response.data.product.oldPrice,
          })
        } else {
          setUrlExplanation("URL can be opened")
          setProduct(undefined)
          setProductOverrideFormData(currentCard.productOverride)
        }
      } else {
        setUrlExplanation(`URL cannot be opened (${response.data.statusCode})`)
        setProduct(undefined)
        setProductOverrideFormData(currentCard.productOverride)
      }
    } else {
      setUrlExplanation(undefined)
      setProduct(undefined)
      setProductOverrideFormData(currentCard.productOverride)
      setUrlValidation(undefined)
    }
    setIsProductLoading(false)
  }

  useEffect(() => {
    // @ts-ignore
    let url = currentCard?.cardType?.directLinkUrl || ''
    setUrlText(url)
    setUrlExplanation(undefined)
    validateUrl(url)
  }, [currentCard?.cardType?.directLinkUrl])

  const onProductOverrideResetClick = () => {
    if (currentCard.productOverride) {
      dispatch(resetCardProductOverride())
    }
  }

  const onProductOverrideEditClick = () => {
    setProductOverrideDialogVisible(true)
  }

  const saveProductOverride = (productOverride: ExperienceUpdateRequestCardProductOverride) => {
    setProductOverrideDialogVisible(false)
    dispatch(setCardProductOverride(productOverride))
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      validateUrl(urlText)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [urlText]);

  return (
    <>
      {cardConfiguration && (
        <Grid container spacing={2} sx={{ marginTop: '30px', width: '100%' }}>
          {currentCard?.cardType?.cardType !== CardType.root && (
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                className={`${styles.paperWrapper} ${styles.sidebarBox}`}
              >
                <CardTypeSelector
                  cardType={currentCard?.cardType?.cardType}
                  handleCardType={handleCardType}
                />

                {currentCard?.cardType?.cardType === CardType.directLink && (
                  <>
                    <TextField
                      fullWidth
                      label={'Direct link URL'}
                      placeholder="Enter/paste the URL"
                      value={urlText || ''}
                      onChange={e => {
                        setUrlText(e.target.value)
                      }}
                      sx={{ marginTop: '10px' }}
                      onBlur={e => {
                        dispatch(
                          changeCardType({
                            cardId: currentCard?.cardId,
                            options: {
                              cardType: CardType?.directLink,
                              // @ts-ignore
                              directLinkLabel:
                              currentCard?.cardType?.directLinkLabel,
                              directLinkUrl: e.target.value,
                              styles: currentCard?.cardType?.['styles'],
                            },
                          }),
                        )
                      }}
                      error={ !urlText ? false : urlValidation?.canOpen === false }
                      helperText={!urlText ? 'Valid URL is required' : urlExplanation}
                    />
                    { directLinkAffiliateData && (
                      <Tooltip title={
                        <>
                          <Typography>
                            URL will be redirected through Awin with publisherId=<span style={{fontWeight: "bold"}}>'{directLinkAffiliateData.params.publisherId}'</span> and advertiserId=<span style={{fontWeight: "bold"}}>'{directLinkAffiliateData.params.advertiserId}'</span>.
                          </Typography>
                          <Typography>
                            <a href={"#"} onClick={() => clipboardCopy(directLinkAffiliateData.redirect)}>Click to copy</a> the actual URL in case you want to verify its correctness.
                          </Typography>
                        </>
                      }>
                        <Grid container>
                          <Grid item>
                            <SpellcheckIcon color={"success"}/>
                          </Grid>
                          {/*<Grid item>*/}
                          {/*  <Typography sx={{ fontSize: "13px"}}>*/}
                          {/*    Awin redirect configured*/}
                          {/*  </Typography>*/}
                          {/*</Grid>*/}
                        </Grid>
                      </Tooltip>
                    )}
                    { isProductLoading && (
                      <CircularProgress sx={{ margin: "10px" }}/>
                    )}
                    <Card variant={"outlined"} sx={{ padding: "10px", marginTop: "10px" }}>
                      { (product || currentCard?.productOverride?.imageId) && (
                        <>
                          <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label="Display link info"
                            checked={currentCard.renderDirectLinkProduct}
                            onChange={() => {
                              dispatch(setCardRenderDirectLinkProduct(!currentCard.renderDirectLinkProduct))
                            }}
                          />
                          <Tooltip title={"Selected image will be featured on card product"}>
                            {/*i love styling*/}
                            <InfoIcon sx={{ position: "relative", top: "8px" }}/>
                          </Tooltip>
                          <Divider sx={{ margin: "5px" }}/>
                          { product ? (
                            <>
                              <img style={{ width: "100%", maxHeight: "300px", objectFit: "contain" }} src={product?.images?.[selectedProductImage]}/>
                              <Pagination
                                count={product.images.length}
                                size={"small"}
                                onChange={(e, page) => setSelectedProductImage(page - 1) }
                                page={selectedProductImage + 1}
                                shape="rounded"
                                siblingCount={0}
                                boundaryCount={1}
                              />
                              <Button
                                sx={{ marginTop: "5px" }}
                                variant={"text"}
                                disabled={isUploading}
                                onClick={useCurrentProductImageAsCardImage}
                                startIcon={<KeyboardArrowLeftIcon/>}
                              >Use as card image</Button>
                              { isUploading && (
                                <CircularProgress/>
                              )}
                            </>
                          ) : (
                            <>
                              { currentCard.productOverride?.imageId && currentCard.productOverride?.imageEnabled !== false && (
                                <Box sx={{width: "100%", maxHeight: "300px" }}>
                                  <InlineImage id={currentCard.productOverride?.imageId} />
                                </Box>
                              )}
                            </>
                          )}

                          <Divider sx={{ margin: "5px" }}/>
                        </>
                      )}
                      <DialogProductOverride
                        open={productOverrideDialogVisible}
                        productOverride={currentCard?.productOverride}
                        productOverrideFormData={productOverrideFormData}
                        onDismiss={() => setProductOverrideDialogVisible(false)}
                        onSave={saveProductOverride}
                        />
                      <Grid container spacing={0}>
                        { (currentCard.productOverride || product) ? (
                          <Grid item xs={8}>
                            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                              { currentCard.productOverride?.title ?? product?.title }
                            </Typography>
                            <Typography sx={{ fontSize: "12px" }}>
                              { currentCard.productOverride?.subtitle ?? product?.subtitle }
                            </Typography>
                            <Typography sx={{ fontSize: "11px", fontWeight: "bold" }}>
                              { (currentCard.productOverride?.oldPrice ?? product?.oldPrice) && (<span style={{ marginRight: "5px", textDecoration: "line-through" }}>{currentCard.productOverride?.oldPrice ?? product?.oldPrice}</span>)}
                              { currentCard.productOverride?.price ?? product?.price }
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid item xs={8}>
                            <Typography sx={{ fontSize: "12px", marginTop: "5px" }}>
                              Add custom product
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={4}>
                          <IconButton color={"primary"} sx={{padding: "4px" }} onClick={onProductOverrideEditClick} >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton color={"primary"} sx={{padding: "4px" }} disabled={!currentCard.productOverride} onClick={onProductOverrideResetClick} >
                            <CancelOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Card>
                  </>
                )}
              </Paper>
            </Grid>
          )}
          {currentCard?.cardType?.cardType !== CardType.directLink && (
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                className={`${styles.paperWrapper} ${styles.sidebarBox}`}
              >
                <CardHeaderContainer
                  handleChange={handleChange}
                  currentCard={currentCard}
                  configuration={cardConfiguration}
                />
              </Paper>
            </Grid>
          )}
          {currentCard?.cardType?.cardType !== CardType.directLink && (
            <Grid item xs={12}>
              <LiveStreamSelector
                show={true}
                itemId={currentCard?.liveStreamId}
                setItem={item => handleSelectOneStream(item)}
                disabled={!liveStreamEditable}
              />
            </Grid>
          )}
          {currentCard?.cardType?.cardType !== CardType.root && (
            <>
              <Grid item xs={12}>
                <CardEditor currentCard={currentCard} />
              </Grid>
              <Grid item xs={12}>
                <DialogSimpleFieldTags
                  title={"Tags"}
                  placeholder={"ex. Dress"}
                  values={tags}
                  helperText={'Tags improve the analytics insights'}
                  onChange={newValue => {
                    if (Array.isArray(newValue))
                      dispatch(addTagsToCard(newValue))
                    else dispatch(addTagsToCard([newValue]))
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            {/*just for padding*/}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default SequenceOptions
