import React, {FC, useEffect, useState} from "react";
import {gridContextEmpty} from "../Reusable/InfiniteItemGrid";
import {LayoutWithSidebar} from "../Reusable/LayoutWithSidebars";
import {BrandsMain} from "./BrandsMain";
import {BrandsSidebar} from "./BrandsSidebar";


export const Brands: FC<{}> = ({}) => {


  const [selectedBrand, setSelectedBrand] = useState(null)
  const [sidebarOpen, setSidebarOpen] = useState(true)

  // @ts-ignore
  const [context, setContext] = useState(gridContextEmpty)

  useEffect(() => {
    setSidebarOpen(Boolean(selectedBrand))
  }, [selectedBrand])

  useEffect(() => {
    if (!sidebarOpen) {
      setSelectedBrand(null)
    }
  }, [sidebarOpen])

  return (
    <>
      <LayoutWithSidebar
        main={<BrandsMain
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          context={context}
        />}
        sidebar={<BrandsSidebar
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          context={context}
        />}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    </>
  )
}



