import { ListSubheader, List } from '@mui/material'
import { useLocation } from 'react-router-dom'
import SidebarMenuItem from './item'
import menuItems, { MenuItem } from './items'
import { experimentalStyled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { IActiveOrganizationState } from '../../../../redux/slices/organizationSlice'
import React from 'react'

const MenuWrapper = experimentalStyled(List)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(1)};
    
    .MuiList-root {
      padding: 0px;
    }
    .MuiListSubheader-root {
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(13)};
      color: ${theme.sidebar.menuItemHeadingColor};
      padding: ${theme.spacing(0.8, 2)};
      line-height: 1.4;
    }
`,
)

const SubMenuWrapper = experimentalStyled(List)(
  ({ theme }) => `
    &.MuiList-root {

      .MuiListItem-root {
        padding: 2px;
        padding-left: ${theme.spacing(0)};
        padding-right: ${theme.spacing(0)};
        &.Mui-selected {
          background-color: transparent;
        }
        .MuiButton-label {
          display: flex;
          position: relative;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(2)};

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(12)};
              color: ${theme.palette.primary.contrastText};
            }
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(14)};
          padding-top: ${theme.spacing(0.8)};
          padding-bottom: ${theme.spacing(0.8)};
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(26)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.sidebar.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(22)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;
          .Mui-active {
            background-color: transparent;
          }
        }
        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px;
            padding-left: ${theme.spacing(0)};
            padding-right: ${theme.spacing(0)};

            .MuiButton-root {
              font-size: ${theme.typography.pxToRem(13)};
              padding: ${theme.spacing(0.5, 2, 0.5, 6.5)};

              &.Mui-active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBg};
              }
              &.Mui-active {
                background-color: ${theme.sidebar.menuItemBgActive}
              }
            }
          }
        }
      }
    }
`,
)

const SidebarMenu = () => {
  const location = useLocation()

  const [activeSidebarMenu, setActiveSidebarMenu] = React.useState<string>('')
  const organizationState = useSelector(
    (state: RootState) => state.organizationReducer,
  )

  const reduceChildRoutes = ({
    ev,
    path,
    menuItem,
    state,
  }: {
    ev: any
    path: string
    menuItem: MenuItem
    state: IActiveOrganizationState
  }) => {
    const key = menuItem.name
    if (menuItem.available(state)) {
      if (menuItem.items) {
        const isUrlFromMenuActive = menuItem.items
          .map(item => item.link)
          .includes(location.pathname)
        ev.push(
          <SidebarMenuItem
            key={key}
            open={
              activeSidebarMenu
                ? activeSidebarMenu === menuItem.name
                : isUrlFromMenuActive
            }
            name={menuItem.name}
            icon={menuItem.icon}
            badge={menuItem.badge}
            setActiveSidebarMenu={setActiveSidebarMenu}
          >
            {renderSidebarMenuItems({
              path,
              items: menuItem.items,
              menuItem: menuItem,
              state: state,
            })}
          </SidebarMenuItem>,
        )
      } else {
        ev.push(
          <SidebarMenuItem
            key={key}
            resetData={menuItem.resetSequenceData}
            name={menuItem.name}
            link={menuItem.link}
            badge={menuItem.badge}
            icon={menuItem.icon}
          />,
        )
      }
    }

    return ev
  }
  const renderSidebarMenuItems = ({
    items,
    path,
    state,
  }: {
    items: MenuItem[]
    path: string
    menuItem?: MenuItem
    state: IActiveOrganizationState
  }) => {
    return (
      <SubMenuWrapper>
        {items.reduce(
          (ev, menuItem) => reduceChildRoutes({ ev, menuItem, path, state }),
          [],
        )}
      </SubMenuWrapper>
    )
  }

  return (
    <>
      {menuItems.map(section => (
        <MenuWrapper
          key={section.heading}
          subheader={
            section.heading && <ListSubheader disableSticky>{section.heading}</ListSubheader>
          }
        >
          {renderSidebarMenuItems({
            items: section.items,
            path: location.pathname,
            state: organizationState,
          })}
        </MenuWrapper>
      ))}
    </>
  )
}

export default SidebarMenu
