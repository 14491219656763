import React, { FC } from 'react'
import { LiveStreamListData } from '../../api/types'
import { GridComponent } from '../Reusable/GridComponent'
import { Grid, Typography } from '@mui/material'
import Moment from 'react-moment'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

export const LiveStreamGridComponent: FC<{
  item: LiveStreamListData
  selected: boolean
}> = ({ item, selected }) => {
  const styles = {
    title: {
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      textOveflow: 'ellipsis',
      // letterSpacing: '-1px',
    },
  }
  return (
    <GridComponent
      selected={selected}
      key={item.id}
      thumbnail={item.poster.location}
      label={
        <Grid
          container
          justifyContent={'space-between'}
          alignItems={'flex-end'}
        >
          <Grid item xs={8}>
            <Typography fontSize={14} variant={'h6'} style={styles.title}>{item.title}</Typography>

            <Typography
              variant={'body2'}
              color={'secondary'}
              sx={{ fontSize: '12px' }}
            >
              <Moment format="DD MMM YYYY[,] HH:mm">
                {item.scheduledAt * 1000}
              </Moment>
            </Typography>
          </Grid>
          <Grid item>
            <LiveIcon status={item.status} />
          </Grid>
        </Grid>
      }
    />
  )
}

export const LiveIcon: FC<{
  status: 'Scheduled' | 'Online' | 'Offline' | 'Completed',
  currentViewers?: string,
}> = ({ status, currentViewers }) => {
  let iconColor = status === 'Online' ? 'error' : 'secondary'

  return (
    <Grid container display="flex" flexDirection="row" alignItems={'center'}>
      <Grid item>
        <Typography color={iconColor} sx={{ fontSize: '10px' }}>
          LIVE
          { currentViewers ? (
              <>
                {' '}({currentViewers})
              </>
          ) : (
              <>
                {' '}
              </>
          )}
        </Typography>
      </Grid>
      <Grid item>
        <FiberManualRecordIcon
          color={iconColor}
          sx={{ width: '14px', height: '14px' }}
        />
      </Grid>
    </Grid>
  )
}
