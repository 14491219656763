import React, {FC} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {IconButton} from "@mui/material";


export const DialogCloseButton: FC<{
  onDismiss: () => void,
  canClose?: boolean,
}> = ({
  onDismiss,
  canClose = true,
}) => {

  return (
    <IconButton
      aria-label="close"
      onClick={onDismiss}
      disabled={!canClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  )
}