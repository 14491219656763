import {Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import {FC} from 'react'
import { useHistory } from 'react-router'
import { TopPerformingExperience } from '../../../api/types'
import './SummaryTable.css'

const ExperienceTable: FC<{
  experiences: TopPerformingExperience[],
  rowsToShow: number,
  defaultRowsToShow: number,
  ownerType: "Creator" | "Business",
  expand?: () => void,
  collapse?: () => void,
}> = ({
  experiences,
  rowsToShow,
  defaultRowsToShow,
  ownerType,
  expand,
  collapse,
}) => {
  const router = useHistory()

  const handleSequencePollClick = (id: string) => {
    if (ownerType === "Creator") {
      router.push('/creators/experiences?id=' + id)
    } else {
      router.push('/content/experiences?id=' + id)
    }
  }

  return (
    <>
      <TableContainer component={Card}>
        <Table>
          <TableHead>
            <TableRow hover>
              <TableCell>Top performing experiences</TableCell>
              <TableCell>Unique visitors</TableCell>
              <TableCell>Avg Time Spent</TableCell>
              <TableCell>Sales</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {experiences?.slice(0, rowsToShow).map(experience => (
              <TableRow hover key={experience.id}
                        className="summary-table-clickable"
                        onClick={() => handleSequencePollClick(experience?.id)}
              >
                <TableCell>
                  <div style={{float: "left"}}>
                    <img
                      className="table-avatar"
                      src={
                        experience.brand.imageLink
                      }
                    />
                  </div>
                  <div>
                    <div>
                      {experience?.campaignName}
                    </div>
                    <div style={{fontSize: "14px"}}>
                    {experience.brand.title}
                    </div>
                  </div>
                </TableCell>
                <TableCell>{experience?.statistics.visitors?.displayValue}</TableCell>
                <TableCell>{experience?.statistics.averageTimeSpent?.displayValue}</TableCell>
                <TableCell>{experience?.statistics?.transactionSummary?.totalSales?.displayValue}</TableCell>
              </TableRow>
            ))}
            {(expand || collapse) && (
              <>
                {defaultRowsToShow === rowsToShow && experiences?.length > rowsToShow && expand && (
                  <TableRow hover className={"table-show-more"}>
                    <TableCell colSpan={5} align={"center"} onClick={expand} sx={{color:"rgba(34, 51, 84, 0.7) !important"}}>
                      Show more
                    </TableCell>
                  </TableRow>
                )}
                {defaultRowsToShow !== rowsToShow && collapse && (
                  <TableRow hover className={"table-show-more"}>
                    <TableCell colSpan={5} align={"center"} onClick={collapse} sx={{color:"rgba(34, 51, 84, 0.7) !important"}}>
                      Show less
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ExperienceTable
