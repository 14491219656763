import {FC, useEffect, useState} from "react";
import {AdminOrganizationDetailsProjection} from "../../api/types";
import {
  adminOrganizationAddAdmin, adminOrganizationAddCreator, adminOrganizationAddMember,
  adminOrganizationFindSingle,
  adminOrganizationLinkOrigin,
  adminOrganizationReassignOwner,
  adminOrganizationRemoveUser,
  adminOrganizationUnlinkOrigin,
  adminOrganizationUpdateSingle
} from "../../api/services";
import {DialogSimpleFieldText} from "../Reusable/DialogSimpleFieldText";
import {Button, Grid, Typography} from "@mui/material";
import {DialogSimpleFieldDropdown} from "../Reusable/DialogSimpleFieldDropdown";
import {toast} from "react-toastify";
import { DialogModifyCreatorProfile } from './DialogModifyCreatorProfile'


export const AdminOrganizationSingle: FC<{
  id: string
}> = ({
  id,
}) => {

  const [organization, setOrganization] = useState<AdminOrganizationDetailsProjection>()
  const [origins, setOrigins] = useState<string[]>()
  const [edited, setEdited] = useState(false)

  const [member, setMember] = useState("")
  const [origin, setOrigin] = useState("")

  const [modifyingCreatorProfile, setModifyingCreatorProfile] = useState(false)

  const reloadOrganization = () => {
    adminOrganizationFindSingle(id).then(d => {
      setOrganization(d.data.organization)
      setOrigins(d.data.origins)
    })
  }
  useEffect(() => {
    if (id) {
      reloadOrganization()
    } else {
      setOrganization(undefined)
    }
    setEdited(false)
  }, [id]);

  const set = (func: (AdminOrganizationDetailsProjection) => void) => {
    let o = {
      ...organization
    }
    func(o)
    setOrganization(o)
    setEdited(true)
  }

  const save = () => {
    setEdited(false)
    adminOrganizationUpdateSingle(id, organization).then(() => {
      toast.success("Organization updated")
    }).catch(e => {
      setEdited(false)
    })
  }

  let xs = 12
  let md = 4

  return (
    <>
      { organization && (
        <Grid container spacing={2} sx={{ marginBottom: "40px" }}>
          <DialogModifyCreatorProfile
            organizationId={id}
            open={modifyingCreatorProfile}
            onDismiss={() => setModifyingCreatorProfile(false)}
            onSave={() => {
              setModifyingCreatorProfile(false)
              toast.success("Owner creator profile updated")
            }}
          />
          <Grid item xs={12}>
            <Typography variant={"h3"}>
              Basic information
            </Typography>
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Id"}
                                   value={organization.id}
                                   setValue={() => {}}
                                   error={false}
                                   disabled={true}
            />
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Owner"}
                                   value={organization.ownerEmail}
                                   setValue={(v) => {}}
                                   error={false}
                                   disabled={true}
            />
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Name"}
                                   value={organization.name}
                                   setValue={(v) => set((o) => o.name = v)}
                                   error={false}
            />
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldDropdown label={"Type"}
                                       props={{ sx: { width: "100%" }}}
                                       value={organization.type}
                                       setValue={(v) => set((o) => o.type = v)} values={[
              { key: "IndividualRoot", label: "IndividualRoot" },
              { key: "AffiliateAdvertiser", label: "AffiliateAdvertiser" },
              { key: "AffiliatePublisher", label: "AffiliatePublisher" },
              { key: "AffiliatePublisherMultiModel", label: "AffiliatePublisherMultiModel" },
            ]}
            />
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldDropdown label={"Subscription status"}
                                       props={{ sx: { width: "100%" }}}
                                       value={organization.subscriptionStatus}
                                       setValue={(v) => set((o) => o.subscriptionStatus = v)} values={[
              { key: "Trial", label: "Trial" },
              { key: "Active", label: "Active" },
              { key: "Suspended", label: "Suspended" },
              { key: "Cancelled", label: "Cancelled" },
              { key: "PendingBusinessStandard", label: "PendingBusinessStandard" },
              { key: "PendingBusinessCreator", label: "PendingBusinessCreator" },
            ]}
            />
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldDropdown label={"Subscription tier"}
                                       props={{ sx: { width: "100%" }}}
                                       value={organization.subscriptionTier}
                                       setValue={(v) => set((o) => o.subscriptionTier = v)} values={[
              { key: "PendingSelection", label: "PendingSelection" },
              { key: "BusinessStandard", label: "BusinessStandard" },
              { key: "BusinessCreator", label: "BusinessCreator" },
              { key: "BusinessEnterprise", label: "BusinessEnterprise" },
              { key: "CreatorStandard", label: "CreatorStandard" },
            ]}
            />
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldDropdown label={"Subscription managed by"}
                                       props={{ sx: { width: "100%" }}}
                                       value={organization.subscriptionManagedBy}
                                       setValue={(v) => set((o) => o.subscriptionManagedBy = v)} values={[
              { key: "Customer", label: "Customer" },
              { key: "Platform", label: "Platform" },
            ]}
            />
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Currency"}
                                   value={organization.currency}
                                   setValue={(v) => set((o) => o.currency = v)}
                                   error={false}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h3"}>
              Limits
            </Typography>
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Experience limit"}
                                   helperText={"How many experiences can organization create"}
                                   value={organization.experienceLimit.toString()}
                                   setValue={(v) => set((o) => o.experienceLimit = v)}
                                   error={false}
            />
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Brand limit"}
                                   helperText={"How many brands can organization create"}
                                   value={organization.brandLimit.toString()}
                                   setValue={(v) => set((o) => o.brandLimit = v)}
                                   error={false}
            />
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Origin limit"}
                                   helperText={"How many origins can organization widgets be embedded on"}
                                   value={organization.originLimit.toString()}
                                   setValue={(v) => set((o) => o.originLimit = v)}
                                   error={false}
            />
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Creator account limit"}
                                   helperText={"How many creators can organization invite and work with"}
                                   value={organization.creatorAccountLimit.toString()}
                                   setValue={(v) => set((o) => o.creatorAccountLimit = v)}
                                   error={false}
            />
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Creator experience limit"}
                                   helperText={"How many active experiences in total organization creators can have"}
                                   value={organization.creatorExperienceLimit.toString()}
                                   setValue={(v) => set((o) => o.creatorExperienceLimit = v)}
                                   error={false}
            />
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Member account limit"}
                                   helperText={"How many regular members can organization have"}
                                   value={organization.memberAccountLimit.toString()}
                                   setValue={(v) => set((o) => o.memberAccountLimit = v)}
                                   error={false}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h3"}>
              Affiliate information
            </Typography>
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Awin advertiser id"}
                                   value={organization.advertiserId}
                                   setValue={(v) => set((o) => o.advertiserId = v)}
                                   error={false}
            />
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Awin publisher id"}
                                   value={organization.publisherId}
                                   setValue={(v) => set((o) => o.publisherId = v)}
                                   error={false}
            />
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Awin commission scheme id"}
                                   value={organization.commissionSchemeId}
                                   setValue={(v) => set((o) => o.commissionSchemeId = v)}
                                   error={false}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant={"contained"} disabled={!edited} onClick={save}>Save</Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h3"}>
              Current origins
            </Typography>
            { origins && origins.length > 0 ? (
              <>
                { origins.map(o => (
                <Grid item xs={12}>
                  <Typography variant={"h6"}>
                    {o}
                  </Typography>
                </Grid>
                ))}
              </>
              ) : (
              <>
                <Typography>None</Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h3"}>
              Actions
            </Typography>
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Add member"}
                                   helperText={"Adds existing system user as member in this organization without switching them over. No email invitations"}
                                   value={member}
                                   setValue={(v) => setMember(v)}
                                   error={false}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant={"contained"} disabled={!member} onClick={() => {
              adminOrganizationAddMember(id, { emails: member }).then(() => {
                setMember("")
                toast.success("Member added")
              })
            }} sx={{margin: "5px"}}>Add as member</Button>
            <Button variant={"contained"} disabled={!member} onClick={() => {
              adminOrganizationAddCreator(id, { emails: member }).then(() => {
                setMember("")
                toast.success("Creator added")
              })
            }} sx={{margin: "5px"}}>Add as creator</Button>
            <Button variant={"contained"} disabled={!member} onClick={() => {
              adminOrganizationAddAdmin(id, { emails: member }).then(() => {
                setMember("")
                toast.success("Admin added")
              })
            }} sx={{margin: "5px"}}>Add as admin</Button>
            <Button variant={"contained"} disabled={!member} onClick={() => {
              adminOrganizationRemoveUser(id, { emails: member }).then(() => {
                setMember("")
                toast.success("User removed")
              })
            }} sx={{margin: "5px"}}>Remove user</Button>
            <Button variant={"contained"} disabled={!member} onClick={() => {
              adminOrganizationReassignOwner(id, { email: member }).then(() => {
                setMember("")
                toast.success("Owner reassigned")
              })
            }} sx={{margin: "5px"}}>Replace owner with this user</Button>
          </Grid>
          <Grid item md={md} xs={xs}>
            <DialogSimpleFieldText label={"Link origin"}
                                   helperText={"Links specified origin to organization and invalidates related caches (enter without https://. For example contester.net)"}
                                   value={origin}
                                   setValue={(v) => setOrigin(v)}
                                   error={false}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant={"contained"} disabled={!origin} onClick={() => {
              adminOrganizationLinkOrigin(id, { organizationId: id, origin: origin, source: "Normal" }).then(() => {
                setOrigin("")
                reloadOrganization()
                toast.success("Origin linked")
              })
            }} sx={{margin: "5px"}}>Link</Button>
            <Button variant={"contained"} disabled={!origin} onClick={() => {
              adminOrganizationUnlinkOrigin(id, { organizationId: id, origin: origin, source: "Normal" }).then(() => {
                setOrigin("")
                reloadOrganization()
                toast.success("Origin unlinked")
              })
            }} sx={{margin: "5px"}}>Unlink</Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant={"contained"} onClick={() => setModifyingCreatorProfile(true)}>Modify owner creator profile</Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}