import { Button, FormControl } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { makeStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import isEmpty from 'lodash.isempty'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  CardType,
  changeCardHeader,
  HeadingProps,
  HeadingType,
  ITreeItem,
} from '../../../../redux/slices/sequenceSlice'
import styles from '../SequenceOptions.module.scss'
import HeaderEditor from './HeaderEditor'
import GalleryPicker from "../../../Gallery/GalleryPicker";
import { GalleryItem } from 'src/components/Gallery/helpers'
import {CardTypeConfiguration} from "../../../../api/types";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    width: '100%',
  },
  cardOption: {
    width: '100%',
    marginTop: '15px',
  },
  paperWrapper: {
    padding: '10px 8px',
    marginBottom: '25px',
    marginTop: '85px',
  },
  title: {
    marginBottom: '20px',
    fontSize: '15px',
  },
  divMargin: {
    marginTop: '15px',
  },
}))

const CardHeaderContainer = (props: {
  handleChange: (options: HeadingProps) => void
  currentCard: ITreeItem,
  configuration: CardTypeConfiguration,
}) => {
  const { handleChange, currentCard, configuration } = props
  const currentHeadingType = currentCard.heading?.type
  const dispatch = useDispatch()
  const classes = useStyles()
  const [headingText, setHeadingText] = useState(
    currentCard?.heading?.titleText,
  )
  //const [loadingProgress, setLoadingProgress] = useState(0);

  const itemReadyCallback = (response: GalleryItem) => {
    let cardHeader: HeadingProps = {
      type: HeadingType.noHeading,
      titleText: '',
    }
    if (getCurrentHeadingType() === HeadingType.image)
      cardHeader = {
        type: HeadingType.image,
        imageId: response.id,
        imageLocation: response.location,
      }
    if (getCurrentHeadingType() === HeadingType.video) {
      cardHeader = {
        type: HeadingType.video,
        videoId: response.id,
        videoLocation: response.location,
      }
    }
    dispatch(
      changeCardHeader({
        cardId: currentCard.cardId,
        options: cardHeader,
      }),
    )
  }

  const getCurrentHeadingType = () => {
    if (currentCard?.cardType?.cardType === CardType.videoOnly) {
      return HeadingType.video
    } else if (
      currentCard?.heading?.isAsPrevious ||
      currentCard?.headingType === 'INHERIT_PARENT'
    ) {
      return HeadingType.asPrevious
    } else {
      return currentHeadingType ?? HeadingType.noHeading
    }
  }

  useEffect(() => {
    setHeadingText(currentCard?.heading?.titleText || '')
  }, [currentCard])

  const [galleryPickerAnchor, setGalleryPickerAnchor] = useState(null)

  return (
    <>
      <GalleryPicker itemReady={itemReadyCallback}
                     onDismiss={() => { setGalleryPickerAnchor(null) }}
                     anchor={galleryPickerAnchor}
                     type={getCurrentHeadingType() === HeadingType.video ? 'video/*' : 'image/*'}
      />
      <FormControl
        variant="outlined"
        className={classes.formControl}
        disabled={isEmpty(currentCard)}
      >
        <InputLabel>Header type</InputLabel>
        <Select
          onChange={e => {
            handleChange({
              type: e.target.value,
              titleText: '',
            })
          }}
          label="Header type"
          defaultValue={currentCard?.cardType?.cardType === CardType.videoOnly ? HeadingType.video : HeadingType.noHeading}
          value={getCurrentHeadingType()}
        >
          {currentCard?.cardType?.cardType !== CardType.videoOnly && (
            <MenuItem value={HeadingType.noHeading}>No heading</MenuItem>
          )}
          {currentCard?.cardType?.cardType !== CardType.videoOnly && (
            <MenuItem value={HeadingType.title}>Title heading</MenuItem>
          )}
          {currentCard?.cardType?.cardType !== CardType.videoOnly && (
            <MenuItem value={HeadingType.image}>Image heading</MenuItem>
          )}
          <MenuItem value={HeadingType.video}>Video heading</MenuItem>
          {currentCard?.cardType?.cardType !== CardType.root && currentCard?.cardType?.cardType !== CardType.videoOnly && (
            <MenuItem value={HeadingType.asPrevious}>
              {' '}
              ⃪ Previous heading
            </MenuItem>
          )}
        </Select>
      </FormControl>
      {!currentCard?.heading?.isAsPrevious && (
        <>
          {getCurrentHeadingType() === HeadingType.title && (
            <div className={classes.divMargin}>
              <TextField
                fullWidth
                value={headingText || ''}
                onBlur={e => {
                  dispatch(
                    changeCardHeader({
                      cardId: currentCard?.cardId,
                      options: {
                        type: HeadingType.title,
                        titleText: e.target.value,
                      },
                    }),
                  )
                }}
                onChange={e => {
                  setHeadingText(e.target.value)
                }}
                label="Enter heading"
              />
              <HeaderEditor currentCard={currentCard} />
            </div>
          )}
          {(getCurrentHeadingType() === HeadingType.video ||
            getCurrentHeadingType() === HeadingType.image) && (
            <div className={styles.cardOption}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
                className={styles.fullWidth}
                onClick={(e) => setGalleryPickerAnchor(e.currentTarget) }
              >
                Upload{' '}
                {getCurrentHeadingType() === HeadingType.video ? 'video' : 'image'}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default CardHeaderContainer
