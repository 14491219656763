import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  lighten,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material'
import { experimentalStyled } from '@mui/material/styles'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone'
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone'
import { useContext, useRef, useState } from 'react'
import { SidebarContext } from 'src/contexts/SidebarContext'
import { SwitchOrganizationDropdown } from '../../../Account/OrganizationData/SwitchOrganizationDropdown'
import { NotificationsBox } from 'src/components/SidebarLayout/Header/NotificationsBox'
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";
import {formatLabelLength} from "../../../Reusable/GridComponent";

const UserBoxButton = experimentalStyled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`,
)

const MenuUserBox = experimentalStyled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`,
)

const UserBoxText = experimentalStyled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
)

const UserBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`,
)

const UserBoxDescription = experimentalStyled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`,
)

function HeaderUserbox() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext)

  const ref = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  const userName = useSelector(
    (state: RootState) => state.authReducer.name,
  )

  const userPicture = useSelector(
    (state: RootState) => state.authReducer.picture,
  )

  const logout = useSelector(
    (state: RootState) => state.authReducer.logout,
  )

  const activeOrganizationData = useSelector(
    (state: RootState) => state.organizationReducer.activeOrganization,
  )

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  return (
    <>
      { userName && (
        <>
          <Tooltip
            sx={{
              display: { lg: 'none', xs: 'flex' },
              marginLeft: { xs: '20px', md: 0 },
            }}
            arrow
            title="Search"
          >
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
            </IconButton>
          </Tooltip>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              justifyContent: { xs: 'end' },
              flexDirection: { xs: 'row', md: 'row' },
              width: { xs: '100%', md: 'auto' },
            }}
          >
            <NotificationsBox />
            <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
              <Avatar variant="rounded" alt={userName} src={userPicture} />
              <UserBoxText sx={{ display: { md: 'block', xs: 'none' } }}>
                <UserBoxLabel variant="body1">{userName}</UserBoxLabel>
                { activeOrganizationData && (
                  <UserBoxDescription variant="body2">
                    {formatLabelLength(activeOrganizationData.name, 30)}
                  </UserBoxDescription>
                )}
              </UserBoxText>
              <ExpandMoreTwoToneIcon
                sx={{ display: { sm: 'block', xs: 'none' }, ml: 1 }}
              />
            </UserBoxButton>
            <Popover
              anchorEl={ref.current}
              onClose={handleClose}
              open={isOpen}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuUserBox sx={{ minWidth: 210 }} display="flex">
                <Avatar variant="rounded" alt={userName} src={userPicture} />
                <UserBoxText>
                  <UserBoxLabel variant="body1">{userName}</UserBoxLabel>
                  <UserBoxDescription variant="body2">

                  </UserBoxDescription>
                </UserBoxText>
              </MenuUserBox>
              <Divider sx={{ mb: 0 }} />
              <List sx={{ p: 1 }} component="nav">
                <ListItem>
                  <SwitchOrganizationDropdown />
                </ListItem>
              </List>
              <Box sx={{ m: 1 }}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={() => {
                    logout({ returnTo: window.location.origin })
                  }}
                >
                  <LockOpenTwoToneIcon sx={{ mr: 1 }} />
                  Sign out
                </Button>
              </Box>
            </Popover>
          </Box>
        </>
      )}
    </>
  )
}

export default HeaderUserbox
