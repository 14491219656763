import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import React, { useEffect, useState } from 'react'
import {
  getBillingPricingThunk,
  getBillingSubscriptionThunk,
} from '../../../redux/slices/billingSlice'
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import {
  billingCancelSubscription,
  billingSubscribe, organizationGetUsage,
} from '../../../api/services'
import ContentWrapper from '../../ContentWrapper/ContentWrapper'
import './Subscription.css'
import {OrganizationUsageData} from "../../../api/types";

const Subscription = () => {
  let dispatch = useDispatch()

  const subscription = useSelector(
    (state: RootState) => state.billingReducer.subscription,
  )

  const [usage, setUsage] = useState<OrganizationUsageData>(null)

  const subscriptionType = useSelector((state: RootState) =>
    state.billingReducer.subscription?.tier == 'CreatorStandard'
      ? 'Creator'
      : state.billingReducer.subscription?.tier == 'PendingSelection'
      ? 'Pending'
      : 'Business',
  )

  const pricing = useSelector(
    (state: RootState) => state.billingReducer.pricing,
  )

  const permissions = useSelector(
    (state: RootState) => state.organizationReducer.permissions.billing,
  )

  let unsubscribe = function () {
    billingCancelSubscription().then(() => {
      dispatch(getBillingSubscriptionThunk())
    })
  }
  let subscribe = function (params: {
    tier: 'BusinessStandard' | 'BusinessCreator'
    periodType: 'Monthly' | 'Yearly'
  }) {
    billingSubscribe({ periodType: params.periodType, tier: params.tier }).then(
      response => {
        window.location.replace(response.data.confirmationUrl)
      },
    )
  }

  const [currency, setCurrency] = useState('€')

  useEffect(() => {
    if (!subscription) {
      dispatch(getBillingSubscriptionThunk())
    }
    if (!pricing) {
      dispatch(getBillingPricingThunk())
    }

    if (!usage) {
      organizationGetUsage().then(d => setUsage(d.data))
    }

    if (subscription) {
      if (subscription.billingProvider == 'Shopify') {
        setCurrency('$')
      }
    }
  }, [subscription, pricing, usage, dispatch])

  return (
    <ContentWrapper title="Subscription">
      <>
        {subscription && subscriptionType === 'Creator' && (
          <>
            <Card>
              <CardContent>
                <h3>
                  Your subscription type is <b>Creator</b>. Service is
                  completely free of charge with limited features.
                </h3>
                <Divider />
                <h4>
                  Contact{' '}
                  <a href="mailto:info@contester.net" target="_blank">
                    <b>us</b>
                  </a>{' '}
                  if you want to switch to Business account instead.
                </h4>
              </CardContent>
            </Card>
          </>
        )}
        {subscription && subscriptionType === 'Business' && (
          <>
            {pricing && (
              <>
                <Card>
                  <Box
                    p={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Typography variant="h4" gutterBottom></Typography>
                      <Typography variant="subtitle1">
                        {subscription.billingProvider == 'Shopify' && (
                          <span>
                            It appears that you're using Contester as part of
                            Shopify. That's great! It also means that your
                            billing will be handled through Shopify Billing
                            system.
                          </span>
                        )}
                        {subscription.billingProvider == 'Stripe' && (
                          <span>
                            Your payments will be handled through Stripe.
                          </span>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Card>

                <Card>
                  <Box
                    p={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Typography variant="h4" gutterBottom></Typography>
                      <Typography variant="subtitle1">
                        {subscription.status != 'Active' && (
                          <span>
                            Your account has a <b>free Trial</b> with Contester
                            watermark
                            {/*until <span className={"highlighted-text"}>{subscription.nextBillingDate}</span>*/}
                          </span>
                        )}
                        {subscription.status == 'Active' && (
                          <span>
                            You have a <b>{subscription.tier} subscription</b>
                            {/*until <span className={"highlighted-text"}>{subscription.nextBillingDate}</span>*/}
                          </span>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Card>

                {subscription.status != 'Active' && permissions.subscribe && (
                  <>
                    <h4>Upgrade your subscription</h4>

                    <Card>
                      <Box
                        p={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box>
                          <Typography variant="h4" gutterBottom>
                            Business package
                          </Typography>
                          <Typography variant="subtitle1">
                            <p>
                              With a Business subscription you enhance your web
                              store experience with pre-recorded and Live
                              shoppable videos on any page of your store.
                            </p>
                            <p>
                              Up to 10 active video experiences, no limit and
                              charge on the number of visitors or sales. We help
                              you get started.
                            </p>
                            <p>
                              <a
                                href="https://contester.net/index.php/pricing/"
                                target="_blank"
                              >
                                Learn more {'>'}
                              </a>
                            </p>
                          </Typography>
                        </Box>
                        <Box style={{ width: '50%' }}>
                          <p>
                            <Button
                              color={'primary'}
                              variant={'contained'}
                              onClick={() =>
                                subscribe({
                                  tier: 'BusinessStandard',
                                  periodType: 'Monthly',
                                })
                              }
                            >
                              {currency}
                              {pricing.business.monthly.total.amount} + VAT
                              Upgrade now
                            </Button>
                          </p>
                          <p>
                            <a
                              onClick={() =>
                                subscribe({
                                  tier: 'BusinessStandard',
                                  periodType: 'Yearly',
                                })
                              }
                              href="#"
                            >
                              Get 15% discount on the yearly billing {'>'}
                            </a>
                          </p>
                        </Box>
                      </Box>
                    </Card>

                    <Card>
                      <Box
                        p={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box>
                          <Typography variant="h4" gutterBottom>
                            Creator mode
                          </Typography>
                          <Typography variant="subtitle1">
                            <p>
                              With a Creator subscription you will have a
                              Business package with up to 100 active video
                              experiences. Multiple channels and collections.
                            </p>
                            <p>
                              You can invite influencers to go live on your
                              website, only visible to their audience! so you
                              grow the sales autonomously. We help you get
                              started.
                            </p>
                            <p>
                              <a
                                href="https://contester.net/index.php/pricing/"
                                target="_blank"
                              >
                                Learn more {'>'}
                              </a>
                            </p>
                          </Typography>
                        </Box>
                        <Box style={{ width: '50%' }}>
                          <p>
                            <Button
                              color={'primary'}
                              variant={'contained'}
                              onClick={() =>
                                subscribe({
                                  tier: 'BusinessCreator',
                                  periodType: 'Monthly',
                                })
                              }
                            >
                              {currency}
                              {pricing.creator.monthly.total.amount} + VAT
                              Upgrade now
                            </Button>
                          </p>
                          <p>
                            <a
                              onClick={() =>
                                subscribe({
                                  tier: 'BusinessCreator',
                                  periodType: 'Yearly',
                                })
                              }
                              href="#"
                            >
                              Get 15% discount on the yearly billing {'>'}
                            </a>
                          </p>
                        </Box>
                      </Box>
                    </Card>

                    <Box
                      p={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box>
                        <Typography variant="subtitle1">
                          <p>
                            <a
                              href="https://contester.net/index.php/pricing/"
                              target="_blank"
                            >
                              Learn more about the pricing on our website {'>'}
                            </a>
                          </p>
                        </Typography>
                      </Box>
                      <Box style={{ width: '50%' }}>
                        <p>
                          <Button color={'primary'} variant={'outlined'}>
                            <a
                              href="https://contester.net/index.php/book-a-demo/"
                              target="_blank"
                            >
                              Need a consultation? Book a free call here {'>'}
                            </a>
                          </Button>
                        </p>
                      </Box>
                    </Box>
                  </>
                )}
                {subscription.status == 'Active' &&
                  permissions.cancelSubscription && (
                    <>
                      <p>
                        <a
                          href="https://contester.net/index.php/pricing/"
                          target="_blank"
                        >
                          Learn more about your package limits, here {'>'}
                        </a>
                      </p>
                      <p>
                        <a href="mailto:info@contester.net" target="_blank">
                          For increasing your account limits, contact us {'>'}
                        </a>
                      </p>
                      <p>
                        <a href="#" onClick={() => unsubscribe()}>
                          Cancel your subscription here {'>'}
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://contester.net/index.php/book-a-demo/"
                          target="_blank"
                        >
                          Need a consultation? Book a free call here {'>'}
                        </a>
                      </p>
                    </>
                  )}
              </>
            )}
          </>
        )}
        {subscription && subscriptionType !== 'Pending' && usage && (
          <>
            <Card>
              <CardContent>
                <h3>Account limits</h3>
                <Divider />
                <TableContainer>
                  <TableBody>
                    <TableRow hover>
                      <TableCell>Experience limit</TableCell>
                      <TableCell>{usage.experienceActiveCount}/{usage.experienceActiveLimit}</TableCell>
                      <TableCell>
                        How many experiences can be created by members of your
                        organization
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Brand limit</TableCell>
                      <TableCell>{usage.brandCount}/{usage.brandLimit}</TableCell>
                      <TableCell>
                        How many brands can be created by members of your
                        organization
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Origin limit</TableCell>
                      <TableCell>{usage.originCount}/{usage.originLimit}</TableCell>
                      <TableCell>
                        How many websites can you embed your content on
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Member account limit</TableCell>
                      <TableCell>{usage.memberAccountCount}/{usage.memberAccountLimit}</TableCell>
                      <TableCell>
                        How many people can be members of your organization
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Creator account limit</TableCell>
                      <TableCell>{usage.creatorAccountCount}/{usage.creatorAccountLimit}</TableCell>
                      <TableCell>
                        How many people can be creators for your organization
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell>Creator experience limit</TableCell>
                      <TableCell>
                        {usage.creatorExperienceCount}/{usage.creatorExperienceLimit}
                      </TableCell>
                      <TableCell>
                        How many experience your organization creators can make
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </TableContainer>
              </CardContent>
            </Card>
          </>
        )}
      </>
    </ContentWrapper>
  )
}

export default Subscription
