import * as React from 'react'

import SequenceHeader from './SequenceHeader/SequeceHeader'
import SequenceImages from './Sequenceimages/SequenceImages'
import SequenceTitle from './SequenceTitle/SequenceTitle'

import { useSelector } from 'react-redux'
import { HeadingType, Image, Video } from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import styles from './SequenceDemo.module.scss'
import SequenceHeaderImage from './SequenceHeader/SequenceHeaderImage'
import SequenceHeaderVideo from './SequenceHeader/SequenceHeaderVideo'

export interface SequenceDemoProps {}

const SequenceDemo: React.FC<SequenceDemoProps> = () => {
  const { currentCard } = useSelector(
    (state: RootState) => state.sequenceReducer.currentProps,
  )

  const hasTitle = currentCard.heading?.type === HeadingType.title
  const hasVideoOrImage =
    currentCard.heading?.type === HeadingType.video ||
    currentCard.heading?.type === HeadingType.image

  const titleStyle = hasTitle ? styles.hasTitle : ''
  const hasSixItems = currentCard.children && currentCard.children.length > 4
  const sixItemsStyle = hasSixItems ? styles.hasSixItems : ''
  const hasVideoStyle = hasVideoOrImage ? styles.hasVideoOrImage : ''

  return (
    <div className={styles.sequenceDemo}>
      <div
        className={`${styles.mobileBox} ${titleStyle} ${sixItemsStyle} ${hasVideoStyle}`}
      >
        <>
          {/*  <SequenceHeader /> */}
          {hasTitle && <SequenceTitle name={currentCard?.heading?.titleText} />}
          {currentCard?.heading?.type === HeadingType.image && (
            <SequenceHeaderImage
              currentCardImage={currentCard.heading as Image}
            />
          )}
          {currentCard?.heading?.type === HeadingType.video && (
            <SequenceHeaderVideo
              currentCardVideo={currentCard.heading as Video}
            />
          )}
        </>
        <SequenceImages data={currentCard} type={currentCard.cardTreeType} />
      </div>
    </div>
  )
}

export default SequenceDemo
