import React, {FC, SetStateAction} from "react";
import {DialogContentText} from "@mui/material";
import {Box, Button, TextField} from "@mui/material";
import {DialogSimple} from "./DialogSimple";

export const DialogTextField: FC<{
  title: string,
  description: string,
  yesTitle: string,
  noTitle: string,
  open: boolean,
  textFieldLabel: string,
  value: string,
  setValue: React.Dispatch<SetStateAction<string>>,
  onPressYes: () => void,
  onPressNo: () => void,
  onDismiss: () => void,
}
> = ({
  title,
  description,
  yesTitle,
  noTitle,
  open,
  textFieldLabel,
  value,
  setValue,
  onPressYes,
  onPressNo,
  onDismiss
}) => {

  return (
    <DialogSimple
      open={open}
      onDismiss={onDismiss}
      title={title}
      maxWidth={"xs"}
      content={
        <Box>
          <DialogContentText id="alert-dialog-description">
                  {description}
          </DialogContentText>
          <TextField
            inputRef={(input) => input && input?.focus()}
            autoFocus
            margin="dense"
            id="textField"
            label={textFieldLabel}
            value={value}
            type="text"
            fullWidth
            multiline
            variant="standard"
            onChange={(e) => setValue(e.target.value)}
          />
        </Box>
      }
      actions={
        <>
          <Button disabled={value === null || value.length === 0 } onClick={() => {
            onPressYes()
            onDismiss()
          }}>{yesTitle}</Button>
          <Button onClick={() => {
            onPressNo()
            onDismiss()
          }} autoFocus>{noTitle}</Button>
        </>
      }
    />
  )
}