import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";

export const Authorized: React.FC = props => {

  const location = useLocation()

  let unauthorizedPaths = ['/external/login']

  const [authorized, setAuthorized] = useState(false)

  useEffect(() => {
    let a = true
    unauthorizedPaths.forEach(p => {
      if (location.pathname.indexOf(p) > -1) {
        a = false
      }
    })
    setAuthorized(a)
  }, [location]);

  return (
    <>
      { authorized && (
        <>
          {props.children}
        </>
      )}
    </>
  )
}