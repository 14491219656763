import React, {FC, useEffect, useState} from "react";
import {ExperienceStatisticsSummary} from "../../api/types";
import {dashboardGetExperienceStatisticsSummary} from "../../api/services";
import ExperienceTable from "../Dashboard/Tables/ExperienceTable";
import UserChoiceTable from "../Dashboard/Tables/UserChoiceTable";


export const ExperienceStatisticsSummaryTable: FC<{
  creatorId: string,
  experienceId: string,
  brandId: string,
  from: string,
  to: string,
  ownerType: "Creator" | "Business",
  tableType: "Full" | "UserChoices" | "TopExperiences",
  rowsToShow?: number,
}> = ({
  creatorId,
  experienceId,
  brandId,
  from,
  to,
  ownerType,
  tableType,
  rowsToShow = 3,
}) => {

  const [summary, setSummary] = useState<ExperienceStatisticsSummary>()

  const [experiencesRows, setExperiencesRows] = useState(rowsToShow)
  const [userChoicesRows, setUserChoicesRows] = useState(rowsToShow)

  useEffect(() => {
    dashboardGetExperienceStatisticsSummary({
      creatorId: creatorId,
      experienceId: experienceId,
      brandId: brandId,
      from: from,
      to: to,
      ownerType: ownerType,
    }).then(d => {
      setSummary(d.data)
    })
  }, [from, to, experienceId, creatorId, brandId, ownerType]);

  return (
    <>
      { summary && (
        <>
          { (tableType === "Full" || tableType === "TopExperiences") && (
            <ExperienceTable
              ownerType={ownerType}
              experiences={summary.topPerformingExperiences}
              rowsToShow={experiencesRows}
              defaultRowsToShow={rowsToShow}
              expand={() => setExperiencesRows(20)}
              collapse={() => setExperiencesRows(rowsToShow)}
            />
          )}
          { tableType === "Full" && (
            <div style={{ margin: "10px"}}></div>
          )}
          { (tableType === "Full" || tableType === "UserChoices") && (
            <UserChoiceTable
              userChoices={summary.topUserChoices}
              rowsToShow={userChoicesRows}
              defaultRowsToShow={rowsToShow}
              expand={() => setUserChoicesRows(20)}
              collapse={() => setUserChoicesRows(rowsToShow)}
            />
          )}
        </>
      )}
    </>
  )
}