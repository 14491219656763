import React, {FC, ReactNode} from "react";
import {DialogTitle, Drawer, Grid, useTheme} from "@mui/material";
import {Box, Paper, useMediaQuery} from "@mui/material";
import {DialogCloseButton} from "./DialogCloseButton";
import {HEADER_HEIGHT, useIsMobileLayout} from "../../utils/layoutConstants";

interface LayoutWithSidebarParams {
  main: ReactNode,
  sidebar: ReactNode,
  sidebarOpen: boolean,
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

export const sidebarStyles = {

  mainButton: {
    margin: "5px 0",
    width: "100%",

  },

  divider: {
    margin: "10px 0px",
  },
}

export const LayoutWithSidebar: FC<LayoutWithSidebarParams> = ({ main, sidebar, sidebarOpen, setSidebarOpen }) => {

  const isMobile = useIsMobileLayout()
  const styles = {
    rootContainer: {
      height: "100%",
      direction: "row",
      justifyContent: "space-between",
      alignItems: "stretch",
    },
    mainContent: {
      height: `calc(100vh - ${HEADER_HEIGHT})`,
      overflowY: "auto",
      overflowX: "hidden",
    },
    sidebar: {
      height: `calc(100vh - ${HEADER_HEIGHT})`,
      overflowY: "auto",
      overflowX: "hidden",
      top: 0,
      padding: 0,
    },
    sidebarDrawer: {
      padding: 16,
    },
  }

  return (
    <Grid style={styles.rootContainer} container spacing={2}>
      <Grid style={styles.mainContent} id={"sidebar-layout-main-content-area"} item xs={12} sm md lg xl>
        {main}
      </Grid>

      { isMobile ? (
        <Grid item>
          <Drawer
            open={sidebarOpen}
            container={window.document.body}
            anchor={"bottom"}
            keepMounted={true}
            onClose={() => setSidebarOpen(false) }
          >
            <DialogTitle>
              <DialogCloseButton onDismiss={() => setSidebarOpen(false)}/>
            </DialogTitle>
            <Box style={styles.sidebarDrawer}>
              {sidebar}
            </Box>
          </Drawer>
        </Grid>
      ) : (
        <Grid item sm={4} md={3} lg={3} xl={2} display={{ xs: "none", sm: "block" }} style={{ paddingLeft: "0px" }}>
          <Paper elevation={3} style={styles.sidebar}>
            {sidebar}
          </Paper>
        </Grid>
      )}

    </Grid>
  )
}

export const LayoutWithSidebarWrapper: FC<{
  children: ReactNode,
}> = ({
  children
}) => {

  const isMobile = useIsMobileLayout()

  const styles = {
    reset: {
      padding: isMobile ? "0px" : "75px 25px 25px 25px",
      // position: "fixed",
      // height: "auto",
    }
  }

  return (
    <Box style={styles.reset}>
      {children}
    </Box>
  )
}