import React, {FC, useEffect, useState} from "react";
import {useAppContext} from "../../contexts/PermissionContext";
import {LiveStreamPickerDialog} from "./LiveStreamPickerDialog";
import {LiveStreamListData} from "../../api/types";
import {Button, CardMedia, Divider} from "@mui/material";
import {liveStreamsGetSingle} from "../../api/services";
import {Card, CardContent, Typography} from "@mui/material";
import Moment from "react-moment";
import CastIcon from "@mui/icons-material/Cast";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {LiveStreamUpdateDialog} from "./LiveStreamUpdateDialog";

export const LiveStreamSelector: FC<{
  show: boolean,
  itemId: string,
  setItem: React.Dispatch<React.SetStateAction<LiveStreamListData>>,
  disabled: boolean,
}> = ({
  show,
  itemId,
  setItem,
  disabled,
}) => {

  let { permissions } = useAppContext()
  let [pickerDialogOpen, setPickerDialogOpen] = useState(false)
  let [createDialogOpen, setCreateDialogOpen] = useState(false)
  
  let [selectedLiveStream, setSelectedLiveStream] = useState<LiveStreamListData | null>(null)

  useEffect(() => {
    if (itemId) {
      liveStreamsGetSingle(itemId).then(d => setSelectedLiveStream(d.data))
    }
  }, [itemId])

  const dismiss = () => {
    setPickerDialogOpen(false)
    setCreateDialogOpen(false)
    setSelectedLiveStream(null)
  }

  const remove = () => {
    setSelectedLiveStream(null)
    setItem(null)
  }

  const onCreateLiveStream = (liveStream) => {
    setSelectedLiveStream(liveStream)
    setItem(liveStream)
  }

  const styles = {
    container: {
      marginBottom: "10px",
    },
    image: {
      width: "100%",
      height: "100px",
      objectFit: "cover",
    },
    button: {
      width: "100%",
      borderColor: "rgba(0, 0, 0, 0.12)",
    }
  }

  return (
    <>
      { permissions && permissions.liveStream.assignLiveStreams && show && (
        <Card style={styles.container} variant={"outlined"}>
          <LiveStreamPickerDialog
            open={pickerDialogOpen}
            itemReady={(item) => setItem(item) }
            onDismiss={dismiss}
          />
          <LiveStreamUpdateDialog liveStream={null} open={createDialogOpen} onSave={onCreateLiveStream} onDismiss={dismiss}/>
          { itemId && selectedLiveStream ? (
            <>
              <CardMedia style={styles.image} image={selectedLiveStream.poster.location}/>
              <CardContent>
                <Typography variant={"h5"}>Live stream</Typography>
                <Typography variant={"h6"}>{selectedLiveStream.title}</Typography>
                <Typography variant={"body1"}>
                  <Moment format="DD MMM YYYY[,] HH:mm">
                    {selectedLiveStream.scheduledAt * 1000}
                  </Moment>
                </Typography>
                <Button startIcon={<DeleteIcon />} disabled={disabled} variant={"outlined"} onClick={remove} style={styles.button}>Remove</Button>
              </CardContent>
            </>
            ) : (
            <>
                <Button startIcon={<CastIcon />} disabled={disabled} onClick={() => setPickerDialogOpen(true) } style={styles.button}>Select live stream</Button>
                <Divider/>
                <Button startIcon={<AddCircleOutlineIcon />} disabled={disabled} onClick={() => setCreateDialogOpen(true) } style={styles.button}>Or create one</Button>
            </>
          )}
        </Card>
      )}
    </>
  )
}