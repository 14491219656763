import React, {FC} from "react";
import {Typography} from "@mui/material";
import Moment from "react-moment";


export const SidebarItemSummary: FC<{
  name: string,
  created?: string | number,
  extra1?: string,
  extra2?: string,
  extra3?: string,
}> = ({
  name,
  created,
  extra1,
  extra2,
  extra3,
}) => {

  return (
    <>
      <Typography variant={"h3"} sx={{ wordWrap: "break-word" }}>
        {name}
      </Typography>
      {created && (
        <Typography variant={"body2"} color={"secondary"}>
          Created on <Moment format="DD MMM YYYY[,] HH:mm">{created * 1000}</Moment>
        </Typography>
      )}
      {extra1 && (
        <Typography variant={"body2"} color={"secondary"}>
          {extra1}
        </Typography>
      )}
      {extra2 && (
        <Typography variant={"body2"} color={"secondary"}>
          {extra2}
        </Typography>
      )}
      {extra3 && (
        <Typography variant={"body2"} color={"secondary"}>
          {extra3}
        </Typography>
      )}
    </>
  )
}