import * as React from 'react'
import SequenceImage from '../SequenceImage/SequenceImage'

import { useSelector } from 'react-redux'
import {
  CardTreeType,
  HeadingType,
  ITreeItem,
} from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import styles from '../SequenceDemo.module.scss'
import SequenceImageSingle from '../SequenceImageSingle/SequenceImageSingle'

export interface SequenceImagesProps {
  data: any
  type: CardTreeType
}

const SequenceImages: React.FC<SequenceImagesProps> = ({ data, type }) => {
  const { currentCard } = useSelector(
    (state: RootState) => state.sequenceReducer.currentProps,
  )
  return (
    <div
      className={(
        (data?.children?.length === 1 || data?.children === undefined
          ? styles.splitOne
          : '') +
        ' ' +
        (data?.children?.length === 2 ? styles.splitTwo : '') +
        ' ' +
        (data?.children?.length === 3 ? styles.splitThree : '') +
        ' ' +
        (data?.children?.length === 4 ? styles.splitFour : '') +
        ' ' +
        (data?.children?.length === 5 ? styles.splitFive : '') +
        ' ' +
        (data?.children?.length === 6 ? styles.splitSix : '') +
        (data?.children?.length > 6 && data?.children?.length % 2 === 1 ? `${styles.splitFive} ${styles.splitOdd}` : '') +
        (data?.children?.length > 6 && data?.children?.length % 2 === 0 ? `${styles.splitSix} ${styles.splitEven}` : '') +
        ' ' +
        ([undefined, HeadingType.noHeading].includes(currentCard?.heading?.type)
          ? styles.splitS
          : styles.splitHead)
      ).trim()}
    >
      {type === CardTreeType.parent &&
        data?.children?.map((item: ITreeItem) => (
          <SequenceImage
            key={item.cardId}
            item={item}
            image={item.media?.location}
          />
        ))}
      {type === CardTreeType.child && (
        <SequenceImageSingle image={data.media?.location} />
      )}
    </div>
  )
}

export default SequenceImages
