import React, {FC, useEffect, useState} from "react";
import {DialogSimple} from "../../Reusable/DialogSimple";
import {Button, Checkbox, FormControlLabel, Grid, Tooltip, Typography} from "@mui/material";
import {ExperienceUpdateRequestCardProductOverride} from "../../../api/types";
import {DialogSimpleFieldText} from "../../Reusable/DialogSimpleFieldText";
import InfoIcon from "@mui/icons-material/Info";
import {InlineImageSelector} from "../../Reusable/InlineImageSelector";
import {GalleryItem} from "../../Gallery/helpers";
import {mediaStorageGetSingle} from "../../../api/services";


export const DialogProductOverride: FC<{
  open: boolean,
  productOverride: ExperienceUpdateRequestCardProductOverride,
  productOverrideFormData: ExperienceUpdateRequestCardProductOverride,
  onSave: (s: ExperienceUpdateRequestCardProductOverride) => void,
  onDismiss: () => void,
}> = ({
  open,
  productOverride,
  productOverrideFormData,
  onSave,
  onDismiss,
}) => {

  const [overrideTitle, setOverrideTitle] = useState(productOverride?.title && productOverride?.title !== productOverrideFormData?.title)
  const [overrideSubtitle, setOverrideSubtitle] = useState(productOverride?.subtitle && productOverride?.subtitle !== productOverrideFormData?.subtitle)
  const [overridePrice, setOverridePrice] = useState(productOverride?.price && productOverride?.price !== productOverrideFormData?.price)
  const [overrideOldPrice, setOverrideOldPrice] = useState(productOverride?.oldPrice && productOverride?.oldPrice !== productOverrideFormData?.oldPrice)
  const [overrideImage, setOverrideImage] = useState(productOverride?.imageId && productOverride?.imageId !== productOverrideFormData?.imageId)

  const [imageEnabled, setImageEnabled] = useState(productOverride?.imageEnabled ?? true)

  const [title, setTitle] = useState(productOverrideFormData?.title)
  const [subtitle, setSubtitle] = useState(productOverrideFormData?.subtitle)
  const [price, setPrice] = useState(productOverrideFormData?.price)
  const [oldPrice, setOldPrice] = useState(productOverrideFormData?.oldPrice)
  const [imageId, setImageId] = useState(productOverrideFormData?.imageId)
  const [galleryItem, setGalleryItem] = useState<GalleryItem>()

  useEffect(() => {
    if (imageId) {
      mediaStorageGetSingle(imageId).then(d => {
        setGalleryItem(d.data)
      })
    }
  }, [imageId]);

  const save = () => {
    onSave({
      imageEnabled: imageEnabled,
      title: overrideTitle ? title : null,
      subtitle: overrideSubtitle ? subtitle : null,
      price: overridePrice ? price : null,
      oldPrice: overrideOldPrice ? oldPrice : null,
      imageId: overrideImage ? galleryItem.id : null,
    })
  }

  const reset = () => {
    setImageEnabled(productOverrideFormData?.imageEnabled)
    setTitle(productOverrideFormData?.title)
    setSubtitle(productOverrideFormData?.subtitle)
    setPrice(productOverrideFormData?.price)
    setOldPrice(productOverrideFormData?.oldPrice)
    setImageId(productOverrideFormData?.imageId)
  }

  useEffect(() => {
    reset()
  }, [productOverrideFormData]);

  const dismiss = () => {
    reset()
    onDismiss()
  }

  return (
    <DialogSimple
      open={open}
      onDismiss={dismiss}
      title={"Override product information"}
      maxWidth={"xs"}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel control={<Checkbox checked={imageEnabled} onChange={(event) => setImageEnabled(event.target.checked) }/>} label={"Display the product image"}/>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Check to override, uncheck to use default
              <Tooltip title={"Consider, if you change the field manually it doesn't update the new info from the website anymore and you need to update manually"}>
                <InfoIcon sx={{ position: "relative", top: "8px", marginLeft: "10px" }}/>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel control={<Checkbox checked={overrideImage} onChange={(event) => setOverrideImage(event.target.checked) }/>} label={""}/>
          </Grid>
          <Grid item xs={11}>
            <InlineImageSelector item={galleryItem} setItem={setGalleryItem} title={"Image"} type={"image/*"}/>
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel control={<Checkbox checked={overrideTitle} onChange={(event) => setOverrideTitle(event.target.checked) }/>} label={""}/>
          </Grid>
          <Grid item xs={11}>
            <DialogSimpleFieldText disabled={!overrideTitle} label={"Title"} value={title} setValue={setTitle} error={false} />
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel control={<Checkbox checked={overrideSubtitle} onChange={(event) => setOverrideSubtitle(event.target.checked) }/>} label={""}/>
          </Grid>
          <Grid item xs={11}>
            <DialogSimpleFieldText disabled={!overrideSubtitle} label={"Subtitle"} value={subtitle} setValue={setSubtitle} error={false} />
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel control={<Checkbox checked={overridePrice} onChange={(event) => setOverridePrice(event.target.checked) }/>} label={""}/>
          </Grid>
          <Grid item xs={11}>
            <DialogSimpleFieldText disabled={!overridePrice} label={"Price"} value={price} setValue={setPrice} error={false} />
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel control={<Checkbox checked={overrideOldPrice} onChange={(event) => setOverrideOldPrice(event.target.checked) }/>} label={""}/>
          </Grid>
          <Grid item xs={10}>
            <DialogSimpleFieldText disabled={!overrideOldPrice} label={"Old price"} value={oldPrice} setValue={setOldPrice} error={((price ?? "") + (oldPrice ?? "")).length > 16 && overrideOldPrice} errorText={"not displayed due to the long text"} />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={"Pricing line including old price and current price must fit into 16 characters, otherwise old price is hidden to avoid breaking UI for users."}>
              {/*i love styling*/}
              <InfoIcon sx={{ position: "relative", top: "8px" }}/>
            </Tooltip>
          </Grid>
        </Grid>
    }
      actions={
        <>
          <Button onClick={save}>Save</Button>
          <Button onClick={dismiss}>Cancel</Button>
        </>
      }
    />
  )
}