import React, { FC, useEffect, useRef, useState } from 'react'
import {
  ExperienceRevisionData,
  SequencePollListData,
  SequencePollPreviewData,
} from '../../api/types'
import { DialogSimple } from '../Reusable/DialogSimple'
import {
  Avatar,
  Button,
  experimentalStyled,
  Grid,
  Typography,
} from '@mui/material'
import {
  experienceRevisionsGetForExperience,
  experienceRevisionsSubmitCreatorComment,
  experienceRevisionsSubmitMemberRevision,
  sequencePollGetPreview,
} from '../../api/services'
import { DialogSimpleFieldText } from '../Reusable/DialogSimpleFieldText'
import { useAppContext } from '../../contexts/PermissionContext'
import { toast } from 'react-toastify'
import moment from 'moment'
import { ApprovalStatusIcon } from '../Approval/ApprovalStatusIcon'

import RateReviewRoundedIcon from '@mui/icons-material/RateReviewRounded'
import ReviewsRoundedIcon from '@mui/icons-material/ReviewsRounded'
import SpeakerNotesOffRoundedIcon from '@mui/icons-material/SpeakerNotesOffRounded'
import Chip from '@mui/material/Chip'
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

const StyledApprovedButton = experimentalStyled(Button)(
  () => `
    color: #11F6B8;
    border-color: #11F6B8;
    &:hover {
      background-color: rgb(17, 246, 184,.2);
      border-color: #11F6B8;
    }
`,
)
const StyledRejectedButton = experimentalStyled(Button)(
  () => `
    color: #FF0054;
    border-color: #FF0054;
    
`,
)
const StyledEditButton = experimentalStyled(Button)(
  () => `
    color: #CB00FF;
    border-color: #CB00FF;
    &:hover {
      background-color: rgba(203,0,255,.2);
      border-color: #CB00FF;
    }
`,
)

export const DialogRevisionHistory: FC<{
  item: SequencePollListData
  setItem: React.Dispatch<React.SetStateAction<SequencePollListData>>
  open: boolean
  onDismiss: (anyChanges: boolean) => void
}> = ({ item, setItem, open, onDismiss }) => {
  const [anyChanges, setAnyChanges] = useState(false)
  const [revisions, setRevisions] = useState<ExperienceRevisionData[]>()
  const [message, setMessage] = useState<string>(null)
  const [previewData, setPreviewData] = useState<SequencePollPreviewData>()

  const { permissions } = useAppContext()

  const authName = useSelector(
    (state: RootState) => state.authReducer.name
  )
  const authPicture = useSelector(
    (state: RootState) => state.authReducer.picture
  )

  const divRef = useRef()

  const loadRevisions = () => {
    experienceRevisionsGetForExperience(item.sequencePoll.id, {
      page: 0,
      perPage: 1000,
    }).then(r => setRevisions(r.data.content))
  }

  useEffect(() => {
    if (open && item?.sequencePoll?.id) {
      loadRevisions()
      sequencePollGetPreview(item.sequencePoll.id).then(d =>
        setPreviewData(d.data),
      )
    }
  }, [open, item?.sequencePoll?.id])

  useEffect(() => {
    if (divRef.current) divRef.current.scrollTop = divRef.current.scrollHeight
  }, [revisions])

  const creatorSubmitComment = () => {
    if (permissions?.overall?.organizationCreator) {
      experienceRevisionsSubmitCreatorComment(item.sequencePoll.id, {
        comment: message,
      }).then(() => {
        toast.success('Comment submitted')
        setMessage('')
        setAnyChanges(true)
        loadRevisions()
      })
    }
  }

  const memberSubmitPendingChanges = () => {
    if (permissions?.overall?.organizationMember) {
      experienceRevisionsSubmitMemberRevision(item.sequencePoll.id, {
        comment: message,
        outcome: 'PendingChanges',
      }).then(() => {
        toast.success('Comment submitted')
        setMessage('')
        setAnyChanges(true)
        loadRevisions()
        setItem({
          ...item,
          sequencePoll: {
            ...item.sequencePoll,
            approvalStatus: 'InReviewWaitingCreator',
          },
        })
      })
    }
  }

  const memberSubmitApproved = () => {
    if (permissions?.overall?.organizationMember) {
      experienceRevisionsSubmitMemberRevision(item.sequencePoll.id, {
        comment: message,
        outcome: 'Approved',
      }).then(() => {
        toast.success('Comment submitted')
        setMessage('')
        setAnyChanges(true)
        loadRevisions()
        setItem({
          ...item,
          sequencePoll: {
            ...item.sequencePoll,
            approvalStatus: 'Approved',
          },
        })
      })
    }
  }

  const memberSubmitRejected = () => {
    if (permissions?.overall?.organizationMember) {
      experienceRevisionsSubmitMemberRevision(item.sequencePoll.id, {
        comment: message,
        outcome: 'Rejected',
      }).then(() => {
        toast.success('Comment submitted')
        setMessage('')
        setAnyChanges(true)
        loadRevisions()
        setItem({
          ...item,
          sequencePoll: {
            ...item.sequencePoll,
            approvalStatus: 'Rejected',
          },
        })
      })
    }
  }

  const onDismissInternal = () => {
    onDismiss(anyChanges)
    setAnyChanges(false)
  }

  const messageValid = message && message.length > 0
  const currentOriginator = permissions?.overall?.organizationCreator
    ? 'Creator'
    : 'Member'

  return (
    <DialogSimple
      maxWidth={'sm'}
      title={'Revision dialog'}
      onDismiss={onDismissInternal}
      open={open}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {item?.sequencePoll?.name}{' '}
                    <a href={previewData?.resolvedUrl} target="_blank">
                      Preview here {'>'}
                    </a>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      Last edit{' '}
                      {moment(item?.sequencePoll?.updatedAt * 1000).format(
                        'yyyy-MM-DD HH:mm',
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2} justifyContent={'flex-end'}>
                  <Grid item>
                    <ApprovalStatusIcon
                      startText={'Status'}
                      status={item?.sequencePoll?.approvalStatus}
                      showTitle={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            ref={divRef}
            sx={{
              maxHeight: '400px',
              overflowY: 'scroll',
              backgroundColor: '#F5F5F5',
              borderRadius: '5px',
              padding: '10px',
              marginTop: '10px',
            }}
          >
            <Grid container spacing={2}>
              <>
                {revisions &&
                  revisions.map(r => (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        direction={
                          currentOriginator == r.originator
                            ? 'row'
                            : 'row-reverse'
                        }
                      >
                        <Grid item>
                          <Avatar
                            src={
                              r.originator == 'Creator'
                                ? r.creatorProfilePicture
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item>
                          <Grid container direction={'column'}>
                            <Grid item>
                              <ApprovalStatusIcon
                                startText={
                                  r.originator == 'Creator' ? r.creatorName : ''
                                }
                                status={
                                  r.outcome == 'PendingChanges'
                                    ? r.originator == 'Creator'
                                      ? 'InReviewWaitingMember'
                                      : 'InReviewWaitingCreator'
                                    : r.outcome
                                }
                                showTitle={true}
                              />
                            </Grid>
                            <Grid
                              item
                              sx={{ color: '#5F5F5F', fontSize: '12px' }}
                            >
                              {r.originator},{' '}
                              {moment(r.createdAt * 1000).format(
                                'yyyy-MM-DD HH:mm',
                              )}
                            </Grid>
                            <Grid item sx={{ marginTop: '5px' }}>
                              <Chip
                                label={r.comment}
                                sx={{
                                  height: 'auto',
                                  maxWidth: '300px',
                                  '& .MuiChip-label': {
                                    display: 'block',
                                    whiteSpace: 'normal',
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
              </>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item>
                <Avatar variant="circular" alt={authName} src={authPicture} />
              </Grid>
              <Grid item xs>
                <DialogSimpleFieldText
                  multiline={true}
                  label={'Your message'}
                  value={message}
                  setValue={setMessage}
                  error={false}
                  helperText={'Message is required'}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent={'flex-end'} spacing={1}>
                  {permissions?.overall?.organizationCreator && (
                    <Grid item>
                      <Button
                        color={'primary'}
                        variant={'outlined'}
                        disabled={!messageValid}
                        onClick={creatorSubmitComment}
                      >
                        Submit for review
                      </Button>
                    </Grid>
                  )}
                  {permissions?.overall?.organizationMember && (
                    <>
                      <Grid item>
                        <StyledApprovedButton
                          color={'success'}
                          variant={'outlined'}
                          disabled={!messageValid}
                          onClick={memberSubmitApproved}
                          startIcon={<ReviewsRoundedIcon />}
                        >
                          Approve
                        </StyledApprovedButton>
                      </Grid>
                      <Grid item>
                        <StyledRejectedButton
                          color={'error'}
                          variant={'outlined'}
                          disabled={!messageValid}
                          onClick={memberSubmitRejected}
                          startIcon={<SpeakerNotesOffRoundedIcon />}
                        >
                          Reject
                        </StyledRejectedButton>
                      </Grid>
                      <Grid item>
                        <StyledEditButton
                          color={'warning'}
                          variant={'outlined'}
                          disabled={!messageValid}
                          onClick={memberSubmitPendingChanges}
                          startIcon={<RateReviewRoundedIcon />}
                        >
                          Request more changes
                        </StyledEditButton>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  )
}
