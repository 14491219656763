import {FC, useEffect, useState} from "react";
import {CreatorProfileData} from "../../../api/types";
import {creatorProfileGet, creatorProfileUpdate} from "../../../api/services";
import {toast} from "react-toastify";
import {CreatorProfileForm} from "./CreatorProfileForm";


export const CreatorProfile: FC<{}> = ({}) => {


    const [creatorProfileData, setCreatorProfileData] = useState<CreatorProfileData>()

    const getData = () => {
        creatorProfileGet().then(d => {
            setCreatorProfileData(d.data)
        })
    }

    useEffect(() => {
        if (!creatorProfileData) {
            getData()
        }
    }, [creatorProfileData])


    const updateData = async (data: CreatorProfileData) => {
        creatorProfileUpdate(data).then(() => {
            getData()
            toast.success("Creator profile updated")
        })
    }

    return (
        <CreatorProfileForm
            creatorProfileData={creatorProfileData}
            updateData={updateData}
        />
    )
}