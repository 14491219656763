import {useMediaQuery, useTheme} from "@mui/material";


export const SIDEBAR_WIDTH = '280px'
export const HEADER_HEIGHT = '72px'

export const useIsMobileLayout = () => {

  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up('sm'))
}