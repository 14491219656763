import React, {FC, useEffect, useState} from "react";
import {autopilotLiveFocus, sequencePollGetForUpdate} from "../../api/services";
import ReactFlow, {Controls, Background, ReactFlowInstance} from 'reactflow';
import 'reactflow/dist/style.css';
import {SequencePollUpdateRequest, SequencePollUpdateRequestCard} from "../../api/types";
import {useAppContext} from "../../contexts/PermissionContext";

export const Autopilot: FC<{}> = ({}) => {

  let { getQueryParam } = useAppContext()

  const [experience, setExperience] = useState(null)

  useEffect(() => {
    if (experience === null) {
      sequencePollGetForUpdate(getQueryParam("experienceId") ?? "cea98eeb-28f7-4ff1-b481-b7b3f9bba7d3").then(d => setExperience(d.data))
    }
  }, [experience])

  return (
    <>
      { experience && (
        <div style={{ width: '500px', height: '500px' }}>
          <AutopilotGrid experience={experience}/>
        </div>
      )}
    </>
  )
}

export const AutopilotGrid: FC<{
  experience: SequencePollUpdateRequest,
}> = ({
  experience,
}) => {

  const [nodes, setNodes] = useState(null)
  const [edges, setEdges] = useState(null)

  useEffect(() => {
    if (experience !== null && (nodes == null || edges == null)) {
      let tempNodes = []
      let tempEdges = []
      experience.roots.forEach((item, index) => {
        let { nodes, edges } = recurse(null, item, 0, index)
        tempNodes = tempNodes.concat(nodes)
        tempEdges = tempEdges.concat(edges)
      })
      setNodes(tempNodes)
      setEdges(tempEdges)
    }
  }, [experience, nodes, edges])

  const recurse = (parent: SequencePollUpdateRequestCard | null, item: SequencePollUpdateRequestCard, level: number, index: number): { nodes: any[], edges: any[] } => {

    let node = { id: item.id, draggable: true, parentNode: parent?.id, position: { x: 200, y: index * 100 }, data: { label: `level=${level}, index=${index} ${item.type}`, payload: item } }

    let currentNodes = [node]
    let currentEdges = []

    if (parent !== null) {
      currentEdges.push(
        {
          id: `${parent.id}-${item.id}`,
          source: parent.id,
          target: item.id,
          type: 'straight',
        })
    }

    // @ts-ignore
    let items = item.items as SequencePollUpdateRequestCard[]

    items.forEach((child, childIndex) => {
      let { nodes, edges } = recurse(item, child, level + 1, index + childIndex)
      currentNodes = currentNodes.concat(nodes)
      currentEdges = currentEdges.concat(edges)
    })

    return {
      nodes: currentNodes,
      edges: currentEdges,
    }
  }

  const onNodeClick = async (event, node) => {
    let cardId = node.data.payload.id
    let experienceId = experience.sequencePollId
    console.log(`focus ${experienceId} ${cardId}`)

    await autopilotLiveFocus(experienceId, cardId)
  }

  const onInit = (instance: ReactFlowInstance) => {
    instance.fitView()
  }

  return (
    <>
      { experience && nodes && edges && (
        <ReactFlow nodes={nodes} edges={edges} onNodeClick={onNodeClick} onInit={onInit}>
          <Background/>
          <Controls/>
        </ReactFlow>
      )}
    </>
  )
}