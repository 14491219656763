import {
  Grid,
  Typography,
  Divider,
  Button,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {UserData} from "../../../api/types";
import {userGetProfile, userUpdateProfile} from "../../../api/services";
import {toast} from "react-toastify";
import {DialogSimpleFieldText} from "../../Reusable/DialogSimpleFieldText";

const MemberProfile: React.FC<{}> = ({ }) => {

  const [profileData, setProfileData] = useState<UserData>()

  const [firstName, setFirstName] = useState(profileData?.firstName || '')
  const [lastName, setLastName] = useState(profileData?.lastName || '')

  const [anyChanges, setAnyChanges] = useState(false)

  useEffect(() => {
    setAnyChanges(profileData && (profileData.firstName !== firstName || profileData.lastName !== lastName))
  }, [firstName, lastName, profileData]);

  useEffect(() => {
    if (!profileData) {
      getData()
    } else {
      setFirstName(profileData?.firstName ?? '')
      setLastName(profileData?.lastName ?? '')
    }
  }, [profileData])

  const getData = () => {
    userGetProfile().then(d => setProfileData(d.data))
  }

  const updateData = async () => {
    userUpdateProfile({
      ...profileData,
      firstName: firstName,
      lastName: lastName,
    }).then(() => {
      getData()
      toast.success("User profile updated")
    })
  }

  return (
    <Grid container spacing={2}>
      { profileData && (
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Your profile details
          </Typography>
          <Divider/>
          <DialogSimpleFieldText label={"First name"} value={firstName} setValue={setFirstName} error={false} />
          <DialogSimpleFieldText label={"Last name"} value={lastName} setValue={setLastName} error={false} />
          <DialogSimpleFieldText label={"Email"} value={profileData.email} setValue={()=> {}} disabled={true} error={false} />
          <Button disabled={!anyChanges} onClick={updateData}>Save</Button>
        </Grid>
      )}
    </Grid>
  )
}

export default MemberProfile
