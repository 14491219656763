import React, {FC, useEffect, useState} from "react";
import {GuidelinesData} from "../../api/types";
import {organizationGuidelinesGet} from "../../api/services";
import {Button, Typography} from "@mui/material";
import {DialogSimple} from "../Reusable/DialogSimple";
import ReactQuill from "react-quill";
import {useAppContext} from "../../contexts/PermissionContext";


export const GuidelinesPreview: FC<{
  showGuidelinesHeading: boolean,
}> = ({
  showGuidelinesHeading,
}) => {

  const { permissions} = useAppContext()

  const [guidelines, setGuidelines] = useState<GuidelinesData>()
  const [previewOpen, setPreviewOpen] = useState(false)

  useEffect(() => {
    if (!guidelines && (permissions?.creatorManagement?.manageCreatorGuidelines || permissions?.overall?.organizationCreator)) {
      organizationGuidelinesGet().then(g => setGuidelines(g.data))
    }
  }, [guidelines, permissions])

  return (
    <>
      <DialogSimple open={previewOpen} onDismiss={() => setPreviewOpen(false)} title={"Organization defined content guidelines"} content={
        <>
          <ReactQuill theme="snow" readOnly={true} value={guidelines?.guidelines} style={{ marginTop: "16px", marginBottom: "16px" }} modules={{
            toolbar: [],
            clipboard: {
              matchVisual: false
            }
          }} />
          <Typography variant={"body1"}>
            Make sure to follow the guidelines when creating content
          </Typography>
        </>
      } maxWidth={"md"}/>
      { guidelines && guidelines.approvalType !== 'PreApproved' && guidelines.guidelines && guidelines.guidelines.length > 0 && (
        <>
          { showGuidelinesHeading && (
            <Typography variant={"h6"}>
              Make sure that your content follows the organization guidelines before sharing with your audience.
            </Typography>
          )}
          <Button onClick={() => setPreviewOpen(true) }>
            Check predefined guidelines
          </Button>
        </>
      )}
    </>
  )
}