import React, {FC} from "react";
import './InteractiveModeLegend.css'
import {Box, Tooltip, Typography} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoIcon from "@mui/icons-material/Info";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
export const InteractiveModeLegend: FC<{

}> = ({

}) => {

  return (
    <Box className={"interactiveModeLegendContainer"}>
      <Tooltip title={
        <>
          <Typography variant={"h5"}>
            Use this mode to interact with your live audience
          </Typography>
          <Typography>
            <ArrowForwardIosIcon sx={{position: "relative", top: "6px", color: "#ffffff", fontSize: "20px"}}/> - Navigate users
          </Typography>
          <Typography>
            <FullscreenIcon sx={{position: "relative", top: "6px", color: "#ffffff", fontSize: "20px"}}/> - Maximize card
          </Typography>
        </>
      }>
        <InfoIcon/>
      </Tooltip>
    </Box>
  )
}