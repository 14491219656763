import React, {FC, useEffect} from "react";
import {FormLabel} from "@mui/material";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";


export const DialogSimpleFieldRadioGroup: FC<{
  label: string,
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  values: {
    key: string,
    label: string,
  }[],
  disabled?: boolean,
}> = ({
  label,
  value,
  setValue,
  values,
  disabled,
}) => {

  const styles = {
    container: {
    }
  }

  useEffect(() => {
    if (!value) {
      setValue(values[0].key)
    }
  }, [value])

  return (
    <FormControl disabled={disabled} style={styles.container}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup value={value} onChange={(v) => setValue(v.target.value) }>
        {values.map(v => (
          <FormControlLabel key={v.key} control={<Radio/>} value={v.key} label={v.label}/>))}
      </RadioGroup>
    </FormControl>
  )
}