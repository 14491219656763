import {FC} from "react";
import ReactApexChart from "react-apexcharts";
import {KeyValueObject} from "../../api/types";


export interface PieChartDefinition {
  title: string,
  series: KeyValueObject[],
}

export const PieChart: FC<PieChartDefinition>
  = ({
  title,
  series,
}) => {

  return (
    <ReactApexChart
      type={'pie'}
      options={{
        chart: {
          type: 'pie',
        },
        labels: series.map(s => s.key),
        theme: {
          monochrome: {
            enabled: true
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          }
        },
        title: {
          text: title,
          margin: 20,
          align: 'center',
        },
        dataLabels: {
          enabled: false,
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            // return [name, val.toFixed(1) + '%']
            return name
          },
        },
        legend: {
          height: 60,
          fontSize: '11px',
          show: true,
          position: "bottom",
          formatter(legendName: string, opts?: any): string {
            const percentage = opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(1)
            return `${legendName} (${percentage}%)`
          }
        }
      }}
      series={series.map(s => s.value)}
      height={"300px"}
      width={"100%"}
    />
  )
}