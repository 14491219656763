import React, {FC, SetStateAction, useEffect, useState} from "react";
import {LiveStreamAvailableOwnerData, LiveStreamListData} from "../../api/types";
import {GridContext, InfiniteItemGrid} from "../Reusable/InfiniteItemGrid";
import {useAppContext} from "../../contexts/PermissionContext";
import {ContentLayoutWithTabs} from "../Reusable/ContentLayoutWithTabs";
import {liveStreamsGetAvailableOwners, liveStreamsGetAll} from "../../api/services";
import {LiveStreamGridComponent} from "./LiveStreamGridComponent";
import {Avatar, Button, Grid, useTheme} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

export const LiveStreamsMain: FC<{
  tabOverride?: string,
  selectedLivestream: LiveStreamListData,
  setSelectedLivestream: React.Dispatch<SetStateAction<LiveStreamListData>>,
  context: GridContext,
}> = ({
    tabOverride,
    selectedLivestream,
    setSelectedLivestream,
    context,
  }) => {

  const { getQueryParam, setQueryParam, removeQueryParam, permissions } =
    useAppContext()

  const [tab, setTab] = useState(tabOverride === "creator" ? "creatorAccessibleLivestreams" : "scheduled")

  const [scheduledLivestreams, setScheduledLivestreams] = useState<LiveStreamListData[] | null>(null)
  const [completedLivestreams, setCompletedLivestreams] = useState<LiveStreamListData[] | null>(null)
  const [creatorAccessibleLivestreams, setCreatorAccessibleLivestreams] = useState<LiveStreamListData[] | null>(null)
  const [creatorLivestreams, setCreatorLivestreams] = useState<LiveStreamListData[] | null>(null)
  const [availableCreators, setAvailableCreators] = useState<LiveStreamAvailableOwnerData[]>(null)
  const [filterCreatorId, setFilterCreatorId] = useState(getQueryParam('creatorId'))

  useEffect(() => {
    if (availableCreators === null) {
      if (tabOverride === 'creator') {
        liveStreamsGetAvailableOwners({ type: 'Creator' }).then(d =>
          setAvailableCreators(d.data),
        )
      } else {
        liveStreamsGetAvailableOwners({ type: 'Member' }).then(d =>
          setAvailableCreators(d.data),
        )
      }
    }
  }, [availableCreators])

  const onFilter = (name, value) => {
    if (value) {
      setQueryParam(name, value)
    } else {
      setSelectedLivestream(null)
      removeQueryParam(name)
    }
    if (name === 'creatorId') {
      setFilterCreatorId(value)
    }

    context.refreshGrid()
  }

  context.refreshGrid = () => {
    setScheduledLivestreams(null)
    setCompletedLivestreams(null)
  }

  let perPage = 24

  return (
    <ContentLayoutWithTabs tab={tab} setTab={setTab} tabs={[
      {
        name: 'scheduled',
        title: 'Scheduled',
        hidden: tabOverride == 'creator',
        children: <InfiniteItemGrid
          perPage={perPage}
          scrollRef={document.getElementById("sidebar-layout-main-content-area")}
          loadItems={(page, perPage) => liveStreamsGetAll({ status: "Scheduled", owner: "Member", page: page, perPage: perPage }).then(d => d.data)}
          setItems={setScheduledLivestreams}
          items={scheduledLivestreams}
          setSelectedItem={setSelectedLivestream}
          selectedItem={selectedLivestream}
          component={(item, selected) => <LiveStreamGridComponent selected={selected} item={item} />}
        />
      },
      {
        name: 'completed',
        title: 'Completed',
        hidden: tabOverride == 'creator',
        children: <InfiniteItemGrid
          perPage={perPage}
          scrollRef={document.getElementById("sidebar-layout-main-content-area")}
          loadItems={(page, perPage) => liveStreamsGetAll({ status: "Completed", owner: "Member", page: page, perPage: perPage }).then(d => d.data)}
          items={completedLivestreams}
          setItems={setCompletedLivestreams}
          setSelectedItem={setSelectedLivestream}
          selectedItem={selectedLivestream}
          component={(item, selected) => <LiveStreamGridComponent selected={selected} item={item} />}
        />
      },
      {
        name: 'creatorLivestreams',
        title: 'All',
        hidden: tabOverride != 'creator' || true,
        children: <InfiniteItemGrid
          perPage={perPage}
          scrollRef={document.getElementById("sidebar-layout-main-content-area")}
          loadItems={(page, perPage) => liveStreamsGetAll({ status: "Scheduled", owner: "Creator", page: page, perPage: perPage, ownerId: filterCreatorId }).then(d => d.data)}
          items={creatorAccessibleLivestreams}
          setItems={setCreatorAccessibleLivestreams}
          setSelectedItem={setSelectedLivestream}
          selectedItem={selectedLivestream}
          component={(item, selected) => <LiveStreamGridComponent selected={selected} item={item} />}
        />
      },
      {
        name: 'creatorAccessibleLivestreams',
        title: 'Creator live streams',
        hidden: tabOverride != 'creator',
        children: <InfiniteItemGrid
          perPage={perPage}
          scrollRef={document.getElementById("sidebar-layout-main-content-area")}
          loadItems={(page, perPage) => liveStreamsGetAll({ status: "Scheduled", owner: "Creator", accessType: "Extended", page: page, perPage: perPage, ownerId: filterCreatorId }).then(d => d.data)}
          items={creatorLivestreams}
          setItems={setCreatorLivestreams}
          setSelectedItem={setSelectedLivestream}
          selectedItem={selectedLivestream}
          component={(item, selected) => <LiveStreamGridComponent selected={selected} item={item} />}
        />
      },
    ]} filter={{
      onFilter: onFilter,
      filter: [
        {
          title: 'Filter by creator',
          name: 'creatorId',
          values: availableCreators,
          active:
            availableCreators?.filter(c => c.id === filterCreatorId)?.[0] ??
            null,
          valueId: c => c.id,
          valueLabel: c => c.name,
          component: value => <FilterCreatorIdRow creator={value} />,
          props: {
            sx: { width: '200px' },
          },
          visible: tabOverride === 'creator',
        },
      ]
    }} controls={
        permissions?.liveStream?.createLiveStream && !tabOverride && (
          <Button variant={"contained"} onClick={context.create} startIcon={<AddIcon/>}>Create</Button>
        )
    } />
  )
}

const FilterCreatorIdRow: FC<{
  creator: LiveStreamAvailableOwnerData
}> = ({ creator }) => {
  const theme = useTheme()

  return (
    <Grid container justifyContent={'space-between'} spacing={1}>
      <Grid item>
        <Avatar src={creator.picture} sx={{ width: '24px', height: '24px' }} />
      </Grid>
      <Grid item xs sx={{ wordBreak: 'break-word' }}>
        {creator.name}
      </Grid>
      <Grid item>
        <Avatar
          sx={{
            fontSize: '10px',
            width: '24px',
            height: '24px',
            backgroundColor:
              creator.liveStreams > 0
                ? theme.colors.primary.main
                : theme.colors.secondary.main,
          }}
        >
          {creator.liveStreams}
        </Avatar>
      </Grid>
    </Grid>
  )
}