import { API_URL_BO_SEQUENCE_POLL, routes } from 'src/constants'
import { ITreeItem } from 'src/redux/slices/sequenceSlice'
import { prepareSequencePollForBackend } from 'src/utils/sequenceSliceHelper'
import { request } from '../apiServices'
import {
  SequencePollPreviewData, Settings,
} from '../../api/types'

export const saveSequencePoll = async (
  items: ITreeItem[],
  contentName: string,
  brandId: string,
  sequenceId: string,
  status: string,
  settings: Settings,
  originHint: string | null,
  refresh: boolean,
): Promise<SequencePollPreviewData> => {
  try {
    let sequencePoll = prepareSequencePollForBackend(
      items,
      contentName,
      brandId,
      status,
      settings,
      originHint,
    )
    return await request({
      url: routes.SAVE_UPDATE_SEQUENCE_POLL + `?refresh=${refresh}`, // if true, this will trigger the refresh for end users
      method: 'POST',
      data: {
        sequencePollId: sequenceId,
        ...sequencePoll,
      },
    })
  } catch (err) {
    throw err
  }
}

export const setFeaturedListForSequencePoll = async (id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request({
        url: `${API_URL_BO_SEQUENCE_POLL}/${id}/IS_IN_BRAND_FEATURED_LIST/set`,
        method: 'POST',
      })
      resolve(res)
    } catch (err) {
      reject(err)
    }
  })
}

export const unsetFeaturedListForSequencePoll = async (id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request({
        url: `${API_URL_BO_SEQUENCE_POLL}/${id}/IS_IN_BRAND_FEATURED_LIST/unset`,
        method: 'POST',
      })
      resolve(res)
    } catch (err) {
      reject(err)
    }
  })
}
