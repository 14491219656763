import React from 'react'
import { ITreeItem } from 'src/redux/slices/sequenceSlice'
import { ImageWrapper } from './SequenceImageSingleStyles'
import styles from '../SequenceDemo.module.scss'

interface Props {
  currentCard: ITreeItem
  image: string
  sampleImage: string
  className?: string
}

const SequenceImageCard: React.FC<Props> = ({
  currentCard,
  image,
  sampleImage,
  className,
}) => {
  let cardStyles = currentCard?.cardType?.styles
  return (
    <>
      <ImageWrapper
        className={`imageWrapper ${className}`}
        opacity={cardStyles?.['opacity'] / 100 || '0'}
        background={cardStyles?.['backgroundColor'] || '#000000'}
      >
        <img
          alt="initial"
          className={`${styles.directLinkImage} ${className}`}
          src={image || sampleImage}
        />
      </ImageWrapper>
      <div
        className={styles?.directLinkLabel}
        style={{
          ...currentCard?.cardType?.styles,
          backgroundColor: 'transparent',
          width: '95%',
          textAlign:
            cardStyles?.['aligment'] === 'right'
              ? 'end'
              : cardStyles?.['aligment'] === 'left'
              ? 'start'
              : 'center',
          margin: 'auto',
          opacity: 1,
          left:
            cardStyles?.['aligment'] === 'left'
              ? '0%'
              : cardStyles?.['aligment'] === 'center' ||
                cardStyles?.['aligment'] === undefined
              ? '50%'
              : 'unset',
          right: cardStyles?.['aligment'] === 'right' ? '0%' : 'unset',
          transform:
            cardStyles?.['aligment'] === 'center' ||
            cardStyles?.['aligment'] === undefined
              ? 'translate(-50%, -50%)'
              : 'translate(0%, -50%)',
        }}
      >
        {currentCard?.cardType?.directLinkLabel}
      </div>
    </>
  )
}

export default SequenceImageCard
