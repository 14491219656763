import React, {FC, ReactNode} from "react";
import {Autocomplete, Divider, Grid} from "@mui/material";
import {Box, TextField} from "@mui/material";

export interface FilterConfiguration {
  onFilter: (name: string, value: string) => void,
  filter: Filter[]
}

interface Filter {
  title: string,
  name: string,
  values: any[],
  active: any,
  valueId: (any) => string,
  valueLabel: (any) => string,
  component: (any) => ReactNode,
  props?: any,
  visible?: boolean,
}

export const GridFilterControlAutocomplete: FC<{
  filter: Filter,
  onFilter: (name: string, value: string) => void,
  disabled?: boolean,
}> = ({
  filter,
  onFilter,
  disabled = false,
}) => {

  return (
    <Autocomplete
      key={`grid-filter-control-${filter.name}`}
      size={"small"}
      sx={{ width: "120px" }}
      options={filter.values}
      disabled={disabled}
      autoHighlight={true}
      getOptionLabel={filter.valueLabel}
      value={filter.active}
      onChange={(e, v) => onFilter(filter.name, (v && filter.valueId(v)) ?? null)}
      renderOption={(props, option) => (
        <>
          <Box {...props} key={filter.valueId(option)} component={"li"} sx={{ padding: "5px" }}>
            {filter.component(option)}
          </Box>
          <Divider/>
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={filter.title}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
          }}
        />
      )}
      {...filter.props}
    />
  )
}

export const GridFilterControl: FC<{ filter: FilterConfiguration }> = ({ filter }) => {

  return (
    <Grid container spacing={1}>
      { filter && filter.filter && filter.filter.filter(f => f.values && f.values.length > 0 && f.visible !== false).map(f => (
        <Grid item key={f.name}>
          <GridFilterControlAutocomplete filter={f} onFilter={filter.onFilter}/>
        </Grid>
      ))}
    </Grid>
  )
}