import {
  Checkbox,
  FormControl,
  FormControlLabel,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import '../WidgetSettings.css'
import React, {FC} from 'react'
import {DisplayDeviceTypeSettings} from "../../../api/types";

const WidgetSettingsDisplayDeviceType: FC<{
  deviceTypeSettings: DisplayDeviceTypeSettings,
  setDeviceTypeSettings: (DisplayDeviceTypeSettings) => void,
}> = ({
  deviceTypeSettings,
  setDeviceTypeSettings,
}) => {

  return (
    <>
      <FormControl className="device-type-settings-wrapper">
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label="Display by device"
          checked={deviceTypeSettings.enabled}
          onChange={() => {
            let value = !deviceTypeSettings.enabled
            if (value == false) {
              // reset values
              setDeviceTypeSettings({
                ...deviceTypeSettings,
                mobile: true,
                tablet: true,
                desktop: true,
              })
            }
            setDeviceTypeSettings({
              ...deviceTypeSettings,
              enabled: value,
            })
          }}
          style={{ marginLeft: '10px' }}
        />
        {deviceTypeSettings.enabled && (
          <>
            <FormControlLabel
              control={<Checkbox key={uuidv4()} color="primary" />}
              label="Display on mobile"
              value={deviceTypeSettings.mobile}
              checked={deviceTypeSettings.mobile}
              onChange={(event, value) =>
                setDeviceTypeSettings({
                  ...deviceTypeSettings,
                  mobile: value,
                })
              }
              style={{ marginLeft: '50px' }}
            />
            <FormControlLabel
              control={<Checkbox key={uuidv4()} color="primary" />}
              label="Display on tablet"
              value={deviceTypeSettings.tablet}
              checked={deviceTypeSettings.tablet}
              onChange={(event, value) =>
                setDeviceTypeSettings({
                  ...deviceTypeSettings,
                  tablet: value,
                })
              }
              style={{ marginLeft: '50px' }}
            />
            <FormControlLabel
              control={<Checkbox key={uuidv4()} color="primary" />}
              label="Display on desktop"
              value={deviceTypeSettings.desktop}
              checked={deviceTypeSettings.desktop}
              onChange={(event, value) =>
                setDeviceTypeSettings({
                  ...deviceTypeSettings,
                  desktop: value,
                })
              }
              style={{ marginLeft: '50px', marginBottom: '20px' }}
            />
          </>
        )}
      </FormControl>
    </>
  )
}

export default WidgetSettingsDisplayDeviceType
