import React, {FC, useEffect, useState} from "react";
import {OrganizationMemberData} from "../../../api/types";
import {Card, Checkbox, FormControlLabel, Grid, Popover, Typography} from "@mui/material";
import {
  OrganizationMemberSettingsIsNotificationsExperienceReviewSubmissionEnabled,
  OrganizationMemberSettingsSetNotificationsExperienceReviewSubmissionEnabled
} from "../../../api/services";
import {toast} from "react-toastify";


export const OrganizationMemberSettings: FC<{
  anchor: HTMLButtonElement | null,
  onDismiss: () => void,
  member: OrganizationMemberData,
}> = ({
  anchor,
  onDismiss,
  member,
}) => {

  const [experienceReviewSubmissionNotificationEnabled, setExperienceReviewSubmissionNotificationEnabled] = useState(false)

  useEffect(() => {
    if (member) {
      OrganizationMemberSettingsIsNotificationsExperienceReviewSubmissionEnabled(member.organizationId, member.id).then(s => {
        setExperienceReviewSubmissionNotificationEnabled(s.data.value)
      })
    }
  }, [member])

  const handleChangeExperienceReviewSubmissionNotificationEnabled = () => {

    OrganizationMemberSettingsSetNotificationsExperienceReviewSubmissionEnabled(member.organizationId, member.id, !experienceReviewSubmissionNotificationEnabled).then(() => {
      setExperienceReviewSubmissionNotificationEnabled(!experienceReviewSubmissionNotificationEnabled)
      toast.success("Experience review submission notification settings updated")
    })
  }

  return (
    <Popover
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={onDismiss}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Card elevation={2} sx={{padding: "10px"}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={"h5"}>Settings</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Send email notifications on new experience reviews"
              checked={experienceReviewSubmissionNotificationEnabled}
              onChange={handleChangeExperienceReviewSubmissionNotificationEnabled}
            />
          </Grid>
        </Grid>
      </Card>
    </Popover>
  )
}