import {Box, Button, Checkbox, Divider, FormControlLabel, ToggleButton, ToggleButtonGroup} from '@mui/material'
import { experimentalStyled } from '@mui/material/styles'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import HeaderUserbox from './Userbox/HeaderUserbox'

import RedoIcon from '@mui/icons-material/Redo'
import UndoIcon from '@mui/icons-material/Undo'
import {selectMode, selectStep, setMode} from 'src/redux/slices/creatorSlice'
import {
  redoChange,
  setAutosave,
  undoChange,
} from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import './Header.css'
import { useAppContext } from '../../../contexts/PermissionContext'
import {SIDEBAR_WIDTH} from "../../../utils/layoutConstants";
import {alphaFeaturesEnabled} from "../../../constants";

const HeaderWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 0)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: flex-end;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            width: auto;
        }
        @media (max-width: ${theme.breakpoints.values.lg - 1}px) {
            left: 0;
        }
`,
)

const StyledButton = experimentalStyled(Button)(
  ({ theme }) => `
  position: absolute;
  left: 0px;
  top: 30px;
  color: #6E759F;
  &:hover: {
    background-color: #fff;
  }
  @media (max-width: ${theme.breakpoints.values.md - 1}px) {
   display: none;
  }
`,
)

function Header({ layoutWithoutSidebar = false }) {
  const router = useHistory()
  const { permissions, constants } = useAppContext()
  const dispatch = useDispatch()
  const step = useSelector(selectStep)
  const sequencePollId = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceId,
  )
  const campaignName = useSelector(
    (state: RootState) => state.sequenceReducer.contentName,
  )
  const brands = useSelector((state: RootState) => state.brandsReducer.brands)
  const currentBrandId = useSelector(
    (state: RootState) => state.sequenceReducer.brandId,
  )
  const isEmailVerified = useSelector(
    (state: RootState) => state.authReducer.isEmailVerified,
  )

  const mode = useSelector(selectMode)

  const changeMode = (e, m) => {
    dispatch(setMode(m))
  }

  const isAutosave = useSelector(
    (state: RootState) => state.sequenceReducer.autosave,
  )

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'z') {
        e.preventDefault()
        return dispatch(undoChange())
      }
      if ((e.metaKey || e.ctrlKey) && e.key === 'y') {
        e.preventDefault()
        return dispatch(redoChange())
      }
    },
    [dispatch],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <>
      <HeaderWrapper
        sx={{
          left: layoutWithoutSidebar ? '0' : SIDEBAR_WIDTH,
        }}
        display="flex"
      >
        {layoutWithoutSidebar && (
          <StyledButton variant={'text'} onClick={() => router.goBack()}>
            {'<'} Back
          </StyledButton>
        )}
        <div className={step === 2 ? 'root-single' : 'root-double'}>
          {step === 2 && (
            <>
              <div className="undo-redo-wrapper">
                <Button
                  className="button-undo"
                  variant="outlined"
                  onClick={() => dispatch(undoChange())}
                >
                  <UndoIcon/>
                </Button>
                <Button
                  size="small"
                  className="button-redo"
                  variant="outlined"
                  onClick={() => dispatch(redoChange())}
                >
                  <RedoIcon/>
                </Button>
                {permissions?.overall?.organizationMember && (
                  <>
                  <span className="header-brand-campaign-info">
                    {brands.find(brand => brand.id === currentBrandId)?.name} /{' '}
                    {campaignName}
                  </span>
                    <Divider orientation={'vertical'}/>
                  </>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAutosave}
                      onChange={(event, checked) =>
                        dispatch(setAutosave(checked))
                      }
                      style={{marginLeft: 10}}
                      color={'primary'}
                    />
                  }
                  label={'Autosave'}
                />
                {sequencePollId && alphaFeaturesEnabled() && (
                  <Box sx={{width: "200px"}}>
                    <ToggleButtonGroup
                      size={"small"}
                      color={"primary"}
                      exclusive={true}
                      value={mode}
                      onChange={changeMode}
                    >
                      <ToggleButton value={"Editor"}>Editor</ToggleButton>
                      <ToggleButton value={"Interactive"}>Interactive</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                )}
              </div>
            </>
          )}
          {!isEmailVerified && (
            <p className="email-verification">Email is not verified</p>
          )}
          {(constants?.maintenanceNotice) && (
            <p className="maintenance-notice">{constants.maintenanceNotice}</p>
          )}
          <Box sx={{display: 'flex', width: {xs: '100%', md: 'auto'}}}>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                justifyContent: {xs: 'space-between'},
                flexDirection: {xs: 'row', md: 'row-reverse'},
                width: {xs: '100%', md: 'auto'},
              }}
            >
              <HeaderUserbox/>
            </Box>
          </Box>
        </div>
      </HeaderWrapper>
    </>
  )
}

export default Header
