import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  TextField,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import {
  CardType,
  changeCardLabelStyles,
  changeCardType,
  ITreeItem,
} from 'src/redux/slices/sequenceSlice'
import styles from '../SequenceOptions.module.scss'
import CardEditorTooltips from './CardEditorTooltips/CardEditorTooltips'
import ColorPicker from './CardEditorTooltips/ColorPicker'

import { RootState } from 'src/redux/store'
import demoStyles from "../../../SequenceDemo/SequenceDemo.module.scss";
import SequenceImageSingle from "../../../SequenceDemo/SequenceImageSingle/SequenceImageSingle";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  cardOption: {
    width: '100%',
    marginTop: '15px',
  },
  paperWrapper: {
    padding: '10px 8px',
  },
  title: {
    marginBottom: '20px',
    fontSize: '15px',
  },
}))

interface Props {
  currentCard: ITreeItem
}

const CardEditor: React.FC<Props> = ({
  currentCard,
}) => {
  const dispatch = useDispatch()
  const fonts = useSelector((state: RootState) => state.helperReducer.fonts)
  const classes = useStyles()
  const disabledStatus = currentCard?.cardType?.cardType === CardType.root
  // || currentCard?.cardTreeType === CardTreeType.parent;
  const [tempFontSlider, setTempFontSlider] = useState(25)
  const [tempOpacitySlider, setTempOpacitySlider] = useState(30)
  const [labelText, setLabelText] = useState(
    currentCard?.cardType?.directLinkLabel,
  )

  useEffect(() => {
    setLabelText(currentCard?.cardType?.directLinkLabel || '')
    setTempFontSlider(currentCard?.cardType?.['styles']?.['fontSize'] || 25)
    setTempOpacitySlider(currentCard?.cardType?.['styles']?.['opacity'] || 0)
  }, [currentCard])

  return (
    <Paper
      variant="outlined"
      className={`${classes.paperWrapper} ${styles.sidebarBox}`}
    >
      { currentCard?.cardType?.cardType === 'INTERMEDIATE' && (
        <div className={demoStyles.sequenceDemoPreview}>
          <SequenceImageSingle image={currentCard.media?.location} />
        </div>
      )}
      <div className={classes.cardOption}>
        <TextField
          fullWidth
          disabled={disabledStatus}
          placeholder="Enter card label..."
          label={"Card label"}
          value={labelText || ''}
          onBlur={e => {
            dispatch(
              changeCardType({
                cardId: currentCard?.cardId,
                options: {
                  cardType: currentCard?.cardType?.cardType,
                  directLinkUrl: currentCard?.cardType?.directLinkUrl,
                  directLinkLabel: e.target.value,
                  styles: currentCard?.cardType?.['styles'],
                },
              }),
            )
          }}
          onChange={e => {
            setLabelText(e.target.value)
          }}
        />
      </div>
      <div className={classes.cardOption}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Font</InputLabel>
          <Select
            id="card-type"
            onChange={e =>
              dispatch(
                changeCardLabelStyles({
                  fontFamily: e.target.value,
                }),
              )
            }
            disabled={disabledStatus}
            value={
              currentCard?.cardType?.['styles']?.['fontFamily'] || 'Segoe UI'
            }
            label="Font"
          >
            {fonts.map(font => (
              <MenuItem key={uuidv4()} value={font.name}>
                {font.name}
              </MenuItem>
            ))}

            {/* <MenuItem value="Segoe UI">Segoe UI</MenuItem>
            <MenuItem value="sans-serif">sans-serif</MenuItem>
            <MenuItem value="monospace">monospace</MenuItem>
            <MenuItem value="ITC Avant Garde Pro Bk">ITC Avant</MenuItem>
            <MenuItem value="Roboto">Roboto</MenuItem> */}
          </Select>
        </FormControl>
      </div>
      <div className={classes.cardOption}>
        <Typography gutterBottom>Font size</Typography>
        <Slider
          value={tempFontSlider}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={1}
          disabled={disabledStatus}
          min={2}
          max={75}
          onChange={(e, v) => {
            if (Array.isArray(v)) setTempFontSlider(v[0])
            else setTempFontSlider(v)
          }}
          onChangeCommitted={(e, v) => {
            dispatch(
              changeCardLabelStyles({
                fontSize: v,
              }),
            )
          }}
        />
        <CardEditorTooltips
          currentCard={currentCard}
          disabledStatus={disabledStatus}
        />
      </div>
      <div className={`${classes.cardOption} ${styles.inputColorMr}`}>
        <Typography gutterBottom>Opacity</Typography>
        <div className={styles.pickerSlider}>
          <ColorPicker
            currentCard={currentCard}
            disabledStatus={disabledStatus}
            styleProp="backgroundColor"
          />
          <Slider
            value={tempOpacitySlider}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            disabled={disabledStatus}
            min={0}
            max={100}
            onChange={(e, v) => {
              if (Array.isArray(v)) setTempOpacitySlider(v[0])
              else setTempOpacitySlider(v)
            }}
            onChangeCommitted={(e, v) => {
              dispatch(
                changeCardLabelStyles({
                  opacity: v,
                }),
              )
            }}
          />
        </div>
      </div>
    </Paper>
  )
}

export default CardEditor
