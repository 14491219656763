import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IAuthSliceState {
  token: string
  email: string
  isEmailVerified: boolean
  isAuthenticated: boolean
  name: string
  picture: string
  logout: ({ returnTo: string }) => void
}


let initialState: IAuthSliceState = {
  token: '',
  email: '',
  name: '',
  picture: '',
  isEmailVerified: false,
  isAuthenticated: false,
  logout: () => {},
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken(state, action: PayloadAction<{ token: string }>) {
      state.token = action.payload.token
    },
    setAuthEmail(state, action: PayloadAction<{ email: string }>) {
      state.email = action.payload.email
    },
    setEmailVerification(state, action: PayloadAction<{ verified: boolean }>) {
      state.isEmailVerified = action.payload.verified
    },
    setAuthName(state, action: PayloadAction<{ name: string }>) {
      state.name = action.payload.name
    },
    setAuthPicture(state, action: PayloadAction<{ picture: string }>) {
      state.picture = action.payload.picture
    },
    setIsAuthenticated(state, action: PayloadAction<{ isAuthenticated: boolean }>) {
      state.isAuthenticated = action.payload.isAuthenticated
    },
    setLogoutFunction(state, action: PayloadAction<{ logout: ({ returnTo: string }) => void }>) {
      state.logout = action.payload.logout
    },
  },
})

export const {
  setAccessToken,
  setEmailVerification,
  setAuthEmail,
  setAuthPicture,
  setAuthName,
  setIsAuthenticated,
  setLogoutFunction,
} = authSlice.actions
export default authSlice.reducer
