import React, { FC, ReactNode } from 'react'
import { GridControls } from './GridControls'
import { Box } from '@mui/material'

export const ContentLayout: FC<{
  controls?: ReactNode
  children: ReactNode
}> = ({ controls, children }) => {
  const styles = {
    container: {
      padding: '16px',
    },
  }

  return (
    <Box>
      <GridControls>{controls}</GridControls>
      <Box style={styles.container}>{children}</Box>
    </Box>
  )
}
