import React, {FC, useEffect, useRef, useState} from "react";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import {Menu, MenuItem} from "@mui/material";
import {Button} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {userSwitchOrganization} from "../../../api/services";
import {getAvailableOrganizationsThunk} from "../../../redux/slices/organizationSlice";
import {useAppContext} from "../../../contexts/PermissionContext";
import SpellcheckIcon from '@mui/icons-material/Spellcheck';

export const SwitchOrganizationDropdown: FC<{

}> = ({

}) => {

  let { permissions } = useAppContext()
  const dispatch = useDispatch()

  const actionRef = useRef<any>(null)
  const [openSwitchOrganization, setOpenSwitchOrganization] = useState<boolean>(false)

  const availableOrganizations = useSelector(
    (state: RootState) => state.organizationReducer.availableOrganizations,
  )

  const activeOrganizationData = useSelector(
    (state: RootState) => state.organizationReducer.activeOrganization,
  )

  useEffect(() => {
    if (!availableOrganizations) {
      dispatch(getAvailableOrganizationsThunk())
    }
  }, [dispatch, availableOrganizations])

  const handleSwitch = async (value: string) => {
    await userSwitchOrganization(value)
    setOpenSwitchOrganization(false)
    window.location.reload()
  }

  return (
    <>
      <Button
        sx={{ mr: 1, minWidth: "125px", maxWidth: "225px" }}
        variant="outlined"
        color="secondary"
        ref={actionRef}
        onClick={() => setOpenSwitchOrganization(true)}
        endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
      >
        Switch organization
      </Button>
      <Menu
        anchorEl={actionRef.current}
        onClose={() => setOpenSwitchOrganization(false)}
        open={openSwitchOrganization}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {availableOrganizations?.map(_org => (
          <MenuItem
            selected={activeOrganizationData?.id === _org.id}
            key={_org.id}
            onClick={() => {
              handleSwitch(_org.id)
            }}
          >
            {permissions?.admin?.manageOrganizations && (
              <SpellcheckIcon sx={{fontSize:"14px", marginRight: "5px", color: _org.affiliate?.status === "Deactivated" ? "red" : _org.affiliate?.status === "Activated" ? "green" : "gray" }}/>
            )}
            {_org.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}