import React, {FC} from "react";
import {Box, LinearProgress} from "@mui/material";
import {Divider, Typography} from "@mui/material";


export const ProgressIndicator: FC<{
  isUploading: boolean,
  progress: number,
}> = ({
  isUploading,
  progress
}) => {
  return (
    <>
      {isUploading && (
        <Box style={{ padding: "10px" }}>
          <Divider style={{ marginBottom: "10px" }}/>
          <Typography style={{ marginBottom: "10px" }}>Uploading ({progress}%)</Typography>
          <LinearProgress variant={"determinate"} value={Math.round(progress)}/>
        </Box>
      )}
    </>
  )
}