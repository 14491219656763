import React, {FC, useEffect, useState} from "react";
import {Button, ButtonGroup} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SortIcon from "@mui/icons-material/Sort";

export interface SortConfiguration {
  onSort: (name: string, direction: 'asc' | 'desc') => void,
  active: string | undefined,
  direction: 'asc' | 'desc' | undefined,
  sort: Sort[],
}

interface Sort {
  title: string,
  name: string,
}

export const GridSortControl: FC<{ sort: SortConfiguration }> = ({ sort }) => {

  const [active, setActive] = useState(sort.active)
  const [direction, setDirection] = useState(sort.direction)

  useEffect(() => {
    if (!active) {
      setActive(sort.sort[0]?.name)
    }
  }, [sort])

  const doSort = (s: Sort) => {
    let newDirection = direction
    if (s.name === active) {
      newDirection = direction === 'asc' ? 'desc' : 'asc'
      setDirection(newDirection)
    } else {
      setActive(s.name)
    }
    sort.onSort(s.name, newDirection)
  }

  return (
    <ButtonGroup variant={"outlined"}>
      { sort && sort.sort && sort.sort.length > 0 && sort.sort.map(s => (
        <Button
          key={`grid-sort-control-${s.name}`}
          sx={s.name === active ? {backgroundColor: "#e7f0ff", textDecoration: "underline"} : {}}
          startIcon={s.name === active ? (direction === 'asc' ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>) : <SortIcon/>}
          onClick={() => doSort(s) }
        >
          {s.title}
        </Button>
      ))}
    </ButtonGroup>
  )
}