import React, { useContext } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { SidebarContext } from '../../../contexts/SidebarContext'
import ContesterLogo from '../../../assets/Logo-text-white.jpg'

import { Box, Divider, Drawer } from '@mui/material'

import { experimentalStyled } from '@mui/material/styles'
import SidebarMenu from './SidebarMenu/SidebarMenu'
import SidebarBottomLinks from './SidebarBottomLinks'

const SidebarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
            border-top-right-radius: ${theme.general.borderRadius};
            border-bottom-right-radius: ${theme.general.borderRadius};
        }
`,
)

const TopSection = experimentalStyled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2, 2)};
`,
)

const SidebarDivider = experimentalStyled(Divider)(
  ({ theme }) => `
        background: ${theme.sidebar.dividerBg};
`,
)

function Sidebar({ layoutWithoutSidebar }) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext)
  const closeSidebar = () => toggleSidebar()

  const sidebarContent =
    <Scrollbars autoHide>
      <TopSection>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flexStart"
          pb={0.5}
        >
          <img className="logo" src={ContesterLogo} />
        </Box>
      </TopSection>
      {/*<SidebarDivider sx={{ my: 2, mx: 2 }} />*/}
      <SidebarMenu />
      <Divider style={{ marginBottom: '1em' }} />
      <SidebarBottomLinks />
    </Scrollbars>


  return (
    <>
      <SidebarWrapper
        sx={{
          display: { md: layoutWithoutSidebar ? 'none' : 'block', xs: 'none' },
        }}
      >
        { sidebarContent }
      </SidebarWrapper>
      <Drawer
        sx={{ display: { lg: 'none', xs: 'block' } }}
        anchor="left"
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          { sidebarContent }
        </SidebarWrapper>
      </Drawer>
    </>
  )
}

export default Sidebar
