import React, {FC, useEffect, useState} from "react";
import {LayoutWithSidebar} from "../Reusable/LayoutWithSidebars";

import {ExperienceListSidebar} from "./ExperienceListSidebar";
import {ExperienceListMain} from "./ExperienceListMain";

export const ExperienceList: FC<{
  tabOverride?: string,
}> = ({
  tabOverride,
}) => {

  const [selectedExperience, setSelectedExperience] = useState(null)
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [tab, setTab] = useState(tabOverride ?? 'active')

  useEffect(() => {
    setSidebarOpen(Boolean(selectedExperience))
  }, [selectedExperience])

  useEffect(() => {
    if (!sidebarOpen) {
      setSelectedExperience(null)
    }
  }, [sidebarOpen])

  // @ts-ignore
  const [context, setContext] = useState({
    refreshGrid: () => {}
  })

  return (
    <LayoutWithSidebar
      main={<ExperienceListMain
        tabOverride={tabOverride}
        tab={tab}
        setTab={setTab}
        selectedExperience={selectedExperience}
        setSelectedExperience={setSelectedExperience}
        context={context}
      />}
      sidebar={<ExperienceListSidebar
        tab={tab}
        selectedExperience={selectedExperience}
        setSelectedExperience={setSelectedExperience}
        context={context}
        tabOverride={tabOverride}
      />}
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
    />
  )
}


