import React, {FC} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {CardProps, CardType} from "../../../redux/slices/sequenceSlice";


export const CardTypeSelector: FC<{
  cardType: CardType,
  handleCardType: (CardProps) => void,
}> = ({
  cardType,
  handleCardType,
}) => {

  return (
    <FormControl
      fullWidth
      variant="outlined"
    >
      <InputLabel>Card type</InputLabel>
      <Select
        disabled={cardType === 'ROOT' || cardType === 'INTERMEDIATE'}
        id="card-type"
        onChange={e =>
          handleCardType({
            cardType: e.target.value,
            directLinkUrl: '',
            directLinkLabel: '',
          })
        }
        value={cardType}
        label="Card type"
      >
        { cardType === 'ROOT' && (
          <MenuItem value={CardType.root}>Root</MenuItem>
        )}
        <MenuItem value={CardType.directLink}>Direct link</MenuItem>
        <MenuItem value={CardType.videoOnly}>Video card</MenuItem>
        { cardType === 'INTERMEDIATE' && (
          <MenuItem value={CardType.intermediate}>Intermediate</MenuItem>
        )}
      </Select>
    </FormControl>
  )
}