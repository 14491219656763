import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import 'src/components/Dashboard/Dashboard.css'
import SummaryOverview from 'src/components/Dashboard/SummaryOverview'
import {
  dashboardGetAvailableCreators,
  dashboardGetAvailableBrands,
  dashboardGetOrganizationDataCreatorChartsByRange, dashboardGetCreatorActivitySummary,
} from '../../api/services'
import {
  BrandData, CreatorActivitySummary,
  CreatorListData, DashboardCharts, DashboardGetOrganizationDataCreatorChartsByRangeQueryParams,
} from '../../api/types'
import SummaryFilter from '../../components/Dashboard/SummaryFilter'
import styled from '@emotion/styled'
import UpperCard from 'src/components/Dashboard/UpperCard'
import RecentlyCard from 'src/components/Dashboard/RecentlyCard'
import GuidelinesCard from "../../components/Dashboard/GuidelinesCard";
import {FilterType} from "../Dashboard";

import {DateRangeSummaryFilter, defaultFilterDateRange} from "../../components/Dashboard/DateRangeSummaryFilter";
import {ExperienceStatisticsSummaryTable} from "../../components/Reusable/ExperienceStatisticsSummaryTable";

const LoadingContainer = styled.div(() => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const CreatorsDashboard = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [dashboardCharts, setDashboardCharts] = useState<DashboardCharts>()
  const [filterData, setFilterData] =
    useState<DashboardGetOrganizationDataCreatorChartsByRangeQueryParams>({
      brandId: undefined,
      ...defaultFilterDateRange,
      creatorId: undefined,
    })

  const [availableBrands, setAvailableBrands] = useState<BrandData[]>([])

  const [availableCreators, setAvailableCreators] = useState<CreatorListData[]>()

  const [creatorActivitySummary, setCreatorActivitySummary] = useState<CreatorActivitySummary>()

  const getDashboardCreatorData = () => {
    dashboardGetCreatorActivitySummary({
      from: filterData.from,
      to: filterData.to,
      brandId: filterData?.brandId,
      creatorId: filterData?.creatorId,
      ownerType: "Creator",
      experienceId: filterData?.experienceId,
    }).then(r => setCreatorActivitySummary(r.data))
    dashboardGetOrganizationDataCreatorChartsByRange({
      from: filterData.from,
      to: filterData.to,
      brandId: filterData?.brandId,
      creatorId: filterData?.creatorId,
    }).then(d => {
      setDashboardCharts(d.data)
      setIsLoading(false)
    })
  }

  const handleFilterDataChange = (type: FilterType, value: any) => {
    if (type === 'TIMERANGE') {
      setFilterData({
        ...filterData,
        from: value.start,
        to: value.end,
      })
    } else if (type === 'CREATOR') {

      setFilterData({
        ...filterData,
        creatorId: value,
      })
    } else if (type === 'BRAND') {
      setFilterData({
        ...filterData,
        brandId: value,
      })
    }
  }

  const getAvailableBrands = async () => {
    const res = await dashboardGetAvailableBrands({ creatorOnly: true })
    setAvailableBrands(res?.data)
  }

  const getAvailableCreators = async () => {
    const res = await dashboardGetAvailableCreators()
    setAvailableCreators(res.data)
  }

  useEffect(() => {
    getAvailableBrands()
    getAvailableCreators()
    getDashboardCreatorData()
  }, [filterData])

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    )
  }
  return (
    <div className="dashboard">
      <div className="sticky-left-bar">
        { creatorActivitySummary && (
          <>
            <UpperCard
              title="Usage"
              fields={[
                {
                  key: 'Active accounts',
                  value:
                  creatorActivitySummary?.accountSummary?.creatorAccounts?.displayValue,
                },

                {
                  key: 'Active experiences',
                  value:
                  creatorActivitySummary?.accountSummary?.creatorActiveExperiences?.displayValue,
                },
                {
                  key: 'Subscribers',
                  value:
                  creatorActivitySummary?.subscribers?.displayValue,
                },
                {
                  key: 'Sales',
                  value:
                  creatorActivitySummary?.sales?.displayValue,
                },
              ]}
            />
            <GuidelinesCard/>
            <RecentlyCard
              variant="CREATOR"
              recentlyEdited={creatorActivitySummary?.recentlyEdited}
            />
          </>
        )}
      </div>
      <div className="summary-data">
        <div className="filters-wrapper">
          <DateRangeSummaryFilter
            filterData={filterData}
            onSelected={(range) => {
              handleFilterDataChange('TIMERANGE', range)
            }}
          />
          <SummaryFilter
            type="BRAND"
            availableFilters={availableBrands}
            filterData={filterData}
            setFilterData={handleFilterDataChange}
          />
          <SummaryFilter
            type="CREATOR"
            availableFilters={availableCreators}
            filterData={filterData}
            setFilterData={handleFilterDataChange}
          />
        </div>
        <SummaryOverview charts={dashboardCharts} />
        <ExperienceStatisticsSummaryTable
          from={filterData.from}
          to={filterData.to}
          experienceId={filterData.experienceId}
          creatorId={filterData.creatorId}
          brandId={filterData.brandId}
          ownerType={"Creator"}
          tableType={"Full"}
        />
      </div>
    </div>
  )
}
