import creatorSlice from 'src/redux/slices/creatorSlice'
import helperSlice from 'src/redux/slices/helperSlice'
import authSlice from './slices/authSlice'
import billingSlice from './slices/billingSlice'
import brandSlice from './slices/brandSlice'
import modalSlice from './slices/modalSlice'
import organizationSlice from './slices/organizationSlice'
import sequenceSlice from './slices/sequenceSlice'

export const rootReducer = {
  authReducer: authSlice,
  sequenceReducer: sequenceSlice,
  brandsReducer: brandSlice,
  organizationReducer: organizationSlice,
  modalReducer: modalSlice,
  billingReducer: billingSlice,
  creatorReducer: creatorSlice,
  helperReducer: helperSlice,
}
