import React, {FC, useEffect, useState} from "react";
import {CreatorProfileData} from "../../../api/types";
import {Box, Button, Divider, FormHelperText, Grid, Typography} from "@mui/material";
import {DialogSimpleFieldText} from "../../Reusable/DialogSimpleFieldText";
import {InlineImageSelector} from "../../Reusable/InlineImageSelector";
import {GalleryItem} from "../../Gallery/helpers";
import {DialogSimpleFieldTags} from "../../Reusable/DialogSimpleFieldTags";
import {mediaStorageGetSingle} from "../../../api/services";


export const CreatorProfileForm: FC<{

  creatorProfileData: CreatorProfileData,
  updateData: (CreatorProfileData) => void,
}> = ({
  creatorProfileData,
  updateData,
}) => {

  const [logo, setLogo] = useState<GalleryItem | null>()
  const [profileName, setProfileName] = useState<string>("")
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [note, setNote] = useState<string>("")
  const [socialFollowers, setSocialFollowers] = useState<number>(0)
  const [industries, setIndustries] = useState<string[]>([])
  const [locations, setLocations] = useState<string[]>([])

  const [anyChanges, setAnyChanges] = useState(false)


  const buildUpdatedObject = () => {
    return {
      ...creatorProfileData,
      firstName: firstName,
      lastName: lastName,
      note: note,
      socialMediaFollowers: socialFollowers,
      interests: industries,
      locations: locations,
      displayName: profileName,
      pictureId: logo?.id,
    }
  }

  useEffect(() => {
    let updated = buildUpdatedObject()
    setAnyChanges(JSON.stringify(updated) !== JSON.stringify(creatorProfileData))
  }, [logo, profileName, firstName, lastName, note, socialFollowers, industries, locations]);

  useEffect(() => {
    if (creatorProfileData) {
      setLogo({ id: creatorProfileData.pictureId })
      setProfileName(creatorProfileData.displayName)
      setFirstName(creatorProfileData.firstName)
      setLastName(creatorProfileData.lastName)
      setNote(creatorProfileData.note)
      setSocialFollowers(creatorProfileData.socialMediaFollowers)
      setIndustries(creatorProfileData.interests)
      setLocations(creatorProfileData.locations)
      mediaStorageGetSingle(creatorProfileData.pictureId).then(d => setLogo(d.data))
    }
  }, [creatorProfileData])

  return (
    <Grid container spacing={2}>
      { creatorProfileData && (
        <>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Your profile details
            </Typography>
            <FormHelperText>
              Please make sure to fill in at least picture, profile name and a note to followers.
            </FormHelperText>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{maxWidth: "200px"}}>
              <InlineImageSelector item={logo} setItem={setLogo} title={"Picture"} type={'image/*'} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DialogSimpleFieldText label={"First name"} value={firstName} setValue={setFirstName} error={false} />
            <DialogSimpleFieldText label={"Last name"} value={lastName} setValue={setLastName} error={false} />
            <DialogSimpleFieldText maxLength={24} label={"Profile name"} value={profileName} setValue={setProfileName} error={false} />
            <DialogSimpleFieldText label={"Your message to followers"} value={note} setValue={setNote} error={false} helperText={"Displayed on end user widget"} />
            <DialogSimpleFieldText type={"number"} label={"Social followers"} value={socialFollowers} setValue={setSocialFollowers} error={socialFollowers === ""} helperText={"How many people are following you across social media channels? (approximately)"}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DialogSimpleFieldTags title={"Industries"} placeholder={""} values={industries} onChange={setIndustries} helperText={"What are your industries and active interests"}/>
            <DialogSimpleFieldTags title={"Locations"} placeholder={""} values={locations} onChange={setLocations} helperText={"Where are you based mostly? you can add multiple locations"}/>
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid>
          <Grid item xs={12}>
            <Button disabled={!anyChanges} onClick={() => {
              updateData(buildUpdatedObject())
            }}>Save</Button>
          </Grid>
        </>
      )}
    </Grid>
  )
}