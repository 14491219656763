import {
  Autocomplete,
  Box,
  Button,
  Checkbox, Divider,
  FormControl,
  FormControlLabel,
  Grid,
  TextField, Typography,
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import { toast } from 'react-toastify'
import WidgetSettingsOverlay from 'src/components/Home/WidgetSettingsOverlay/WidgetSettingsOverlay'

import MoreIconSvg from '../../assets/more-icon.svg'
import WidgetSettingsCheckbox from './WidgetSettingsCheckbox/WidgetSettingsCheckbox'
import WidgetSettingsUTMParams from './WidgetSettingsUTMParams/WidgetSettingsUTMParams'
import WidgetSettingsTemplate from './WidgetSettingsTemplate/WidgetSettingsTemplate'
import WidgetSettingsDisplayDeviceType from './WidgetSettingsDisplayDeviceType/WidgetSettingsDisplayDeviceType'
import {Settings} from '../../api/types'
import { sequencePollValidateExternalUrl } from '../../api/services'
import {useAppContext} from "../../contexts/PermissionContext";
import CopyIcon from "@mui/icons-material/FileCopy";
import _ from "lodash";

interface Props {
  onDismiss: () => void
  handleSaveChanges: () => void
  settings: Settings,
  setSettings: React.Dispatch<React.SetStateAction<Settings>>,
  experienceId: string,
}
const ExperienceSettingsComponent: React.FC<Props> = ({
  onDismiss,
  handleSaveChanges,
  settings,
  setSettings,
  experienceId,
}) => {

  const [widgetSettings, setWidgetSettings] = useState({
    ...settings.widget
  })

  const [overlaySettings, setOverlaySettings] = useState({
    ...settings.overlay,
  })

  const [templateSettings, setTemplateSettings] = useState({
    ...settings.template,
  })

  useEffect(() => {
    setSettings({
      ...settings,
      widget: widgetSettings,
      overlay: overlaySettings,
      template: templateSettings,
    })
  }, [widgetSettings, overlaySettings, templateSettings])

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const utmParams = widgetSettings.utm

  const showUrls = widgetSettings.includeUrls

  const hideUrls = widgetSettings.excludeUrls

  const showOnAll = widgetSettings.showOnAllLastCardURLs

  const openLastCardLinksInNewTab = widgetSettings.openLastCardURLsInNewTab
  const renderCardDirectLinkProducts = widgetSettings.renderCardDirectLinkProducts

  const { permissions } = useAppContext()

  const [isWidgetSettingsEditable, setIsWidgetSettingsEditable] = useState(false)

  const [previewUrl, setPreviewUrl] = useState<string>()
  const [generatedAlternativeUrl, setGeneratedAlternativeUrl] = useState('')

  useEffect(() => {
    let url = widgetSettings.includeUrls?.[0] ?? "{{URL}}"
    setPreviewUrl(url)
    setGeneratedAlternativeUrl(url + '?contester_display=true&contester_pid=' + experienceId)
  }, [widgetSettings.includeUrls?.[0]])

  useEffect(() => {
    if (permissions) {
      setIsWidgetSettingsEditable(permissions.experience.updateWidgetSettings)
    }
  }, [permissions])

  const validateUrl = async (
    url: string,
    key: 'includeUrls' | 'excludeUrls',
  ) => {

    let remove = () => {
      if (key === 'includeUrls') {
        setWidgetSettings({
          ...widgetSettings,
          includeUrls: widgetSettings.includeUrls.filter(u => u !== url)
        })
      } else if (key === 'excludeUrls') {
        setWidgetSettings({
          ...widgetSettings,
          excludeUrls: widgetSettings.excludeUrls.filter(u => u !== url)
        })
      }
    }
    try {
      let res = await sequencePollValidateExternalUrl({ url: url, excludeOrigins: [] })
      if (!res.data.isValid) {
        toast.warning(
          'Please check if the URL is correct or valid for your organization',
        )
        remove()
      }
    } catch (err) {
      remove()
      toast.warning(
        'Please check if the URL is correct or valid for your organization',
      )
    }
  }

  const handleSaveChangesSubmit = () => {

    handleSaveChanges()
  }

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text)
    toast.info('URL copied to clipboard')
  }

  const handleShowUrlKeyDown = event => {
    switch (event.key) {
      case ',':
      case ' ':
      case 'Enter': {
        let url = event?.target?.value?.trim()
        if (!url.includes('https://')) url = 'https://' + url
        if (widgetSettings.includeUrls.indexOf(url) === -1) {
          validateUrl(url, 'includeUrls')
          event.preventDefault()
          event.stopPropagation()
          setWidgetSettings({
            ...widgetSettings,
            includeUrls: widgetSettings.includeUrls.concat([url])
          })
        }
        // setShowUrls([...showUrls, url]);
        break
      }
      default:
    }
  }

  const handleHideUrlKeyDown = event => {
    switch (event.key) {
      case ',':
      case ' ':
      case 'Enter': {
        let url = event?.target?.value?.trim()
        if (!url.includes('https://')) url = 'https://' + url
        if (widgetSettings.excludeUrls.indexOf(url) === -1) {
          validateUrl(url, 'excludeUrls')

          event.preventDefault()
          event.stopPropagation()
          setWidgetSettings({
            ...widgetSettings,
            excludeUrls: widgetSettings.excludeUrls.concat([url])
          })
        }
        break
      }
      default:
    }
  }

  const handleCancel = () => {
    onDismiss()
  }

  return (
    <div className="home-widget-settings">
      <div className="widget-form-settings">
        <FormControl className="home-form-wrapper">
          {isWidgetSettingsEditable && (
            <>
              <p>
                <b>Display settings</b>
              </p>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Display on the last card URLs (Co-browse)"
                checked={showOnAll}
                onChange={() => setWidgetSettings({
                  ...widgetSettings,
                  showOnAllLastCardURLs: !widgetSettings.showOnAllLastCardURLs
                })}
                style={{ marginBottom: '-15px', marginLeft: '10px' }}
              />
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Open last card URLs in a new tab"
                checked={openLastCardLinksInNewTab}
                onChange={() => setWidgetSettings({
                  ...widgetSettings,
                  openLastCardURLsInNewTab: !widgetSettings.openLastCardURLsInNewTab,
                })}
                style={{ marginBottom: '-15px', marginLeft: '10px' }}
              />
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Display product feed items on cards if available"
                checked={renderCardDirectLinkProducts}
                onChange={() => setWidgetSettings({
                  ...widgetSettings,
                  renderCardDirectLinkProducts: !widgetSettings.renderCardDirectLinkProducts,
                })}
                style={{ marginBottom: '-15px', marginLeft: '10px' }}
              />
              { permissions?.experience?.updateIncludeUrls && (
                <Grid className="home-widget__urls">
                  <Box p={1}>
                    <Autocomplete
                      multiple
                      freeSolo
                      id="tags-outlined"
                      options={[]}
                      getOptionLabel={option => {
                        if (typeof option === 'string') {
                          return option
                        }
                        return option.toString()
                      }}
                      onChange={(event, newValue) => {
                        if (Array.isArray(newValue)) {
                          setWidgetSettings({
                            ...widgetSettings,
                            includeUrls: _.uniq(newValue.map(n => n.trim()))
                          })
                        }
                      }}
                      value={showUrls}
                      filterSelectedOptions
                      renderInput={params => {
                        params.inputProps.onKeyDown = handleShowUrlKeyDown
                        return (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Page URL where you want this experience"
                            placeholder="ex. https://contester.net/"
                            margin="normal"
                            fullWidth
                            helperText="You can add multiple URLs. You can also use wildcards to display on all URLs matching a pattern (i.e. https://contester.net/*)"
                          />
                        )
                      }}
                    />
                  </Box>
                </Grid>
              )}

            </>
          )}
        </FormControl>
        {isWidgetSettingsEditable && (
          <>
            <FormControl className="home-form-wrapper">
              { permissions?.experience?.updateExcludeUrls && (
                <>
                  <Grid className="home-widget__urls">
                    <Box p={1}>
                      <Autocomplete
                        multiple
                        freeSolo
                        id="tags-outlined"
                        options={[]}
                        getOptionLabel={option => {
                          if (typeof option === 'string') {
                            return option
                          }
                          return option.toString()
                        }}
                        onChange={(event, newValue) => {
                          if (Array.isArray(newValue)) {
                            setWidgetSettings({
                              ...widgetSettings,
                              excludeUrls: _.uniq(newValue)
                            })
                          }
                        }}
                        value={hideUrls}
                        filterSelectedOptions
                        renderInput={params => {
                          params.inputProps.onKeyDown = handleHideUrlKeyDown
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Page URL where you don't want to show this experience"
                              placeholder="ex. https://contester.net/"
                              margin="normal"
                              helperText="You can add multiple URLs"
                              fullWidth
                            />
                          )
                        }}
                      />
                    </Box>
                  </Grid>
                  <Divider/>
                </>
              )}

              <Box sx={{ marginBottom: "20px" }}>
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Display dynamically"
                  checked={utmParams.enabled}
                  onChange={() => {
                    let val = !widgetSettings.utm.enabled
                    setWidgetSettings({
                      ...widgetSettings,
                      utm: {
                        ...widgetSettings.utm,
                        enabled: val,
                      }
                    })
                    // do not disable save button
                    // if (!val) setIsButtonDisabled(false)
                  }}
                  style={{ marginBottom: '-15px', marginLeft: '10px' }}
                />

                {utmParams.enabled && (
                  <Grid>


                    <Box sx={{ padding: "8px" }}>
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Unique URL (works irregardless of provided UTM query parameters. Useful for ad campaigns)"
                        value={generatedAlternativeUrl}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <CopyIcon
                              className="copy-icon"
                              onClick={() => copyToClipboard(generatedAlternativeUrl)}
                            />
                          ),
                        }}
                      />
                    </Box>

                    <Typography variant={"h5"} sx={{ marginTop: "20px", marginLeft: "20px" }}>
                      Or display if the link contains UTM(s)
                    </Typography>
                    <Box>
                      <WidgetSettingsUTMParams
                        utmSettings={widgetSettings.utm}
                        setUtmSettings={(utm) => setWidgetSettings({
                          ...widgetSettings,
                          utm: utm,
                        })}
                        url={previewUrl ?? "{{URL}}"}
                        setUTMSettingsInvalid={setIsButtonDisabled}
                        experienceId={experienceId}
                      />
                    </Box>
                    <Divider/>
                  </Grid>
                )}
              </Box>
            </FormControl>
          </>
        )}
        { permissions?.experience?.updateWidgetSettings && (
          <WidgetSettingsDisplayDeviceType deviceTypeSettings={widgetSettings.displayDeviceTypeSettings} setDeviceTypeSettings={(deviceTypeSettings) => {
            setWidgetSettings({
              ...widgetSettings,
              displayDeviceTypeSettings: deviceTypeSettings,
            })
          }} />
        )}
        <WidgetSettingsTemplate templateSettings={templateSettings} setTemplateSettings={setTemplateSettings} />
        <WidgetSettingsOverlay overlaySettings={overlaySettings} setOverlaySettings={setOverlaySettings} />
        <div className="home-buttons-wrapper-settings">
          <Button variant="outlined">
            Learn more{' '}
            <img
              width={16}
              height={16}
              style={{ marginLeft: '8px' }}
              src={MoreIconSvg}
            />
          </Button>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            disabled={isButtonDisabled}
            variant="contained"
            onClick={() => handleSaveChangesSubmit()}
          >
            Save changes
          </Button>
        </div>
      </div>
      { permissions?.experience?.updateWidgetSettings && (
        <div className="widget-settings-checkbox-wrapper">
          <WidgetSettingsCheckbox displaySettings={widgetSettings.displaySettings} setDisplaySettings={(displaySettings) => setWidgetSettings({
            ...widgetSettings,
            displaySettings: displaySettings,
          })} />
        </div>
      )}
    </div>
  )
}

export default ExperienceSettingsComponent
