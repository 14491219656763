import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material'
import React, {FC} from 'react'
import {UIPermissions, WidgetDisplaySettings} from "../../../api/types";
import {useAppContext} from "../../../contexts/PermissionContext";


const availableDisplayTypes = [
  {
    key: 'MaximizedExpanded',
    label: 'Maximized and expanded',
  },
  {
    key: 'Maximized',
    label: 'Maximized',
  },
  {
    key: 'Minimized',
    label: 'Minimized',
  },
  // {
  //   key: 'Small',
  //   label: 'Small'
  // },
  // {
  //   key: 'Notification',
  //   label: 'Notification'
  // },
]

export const getAvailableDisplayTypes = (permissions: UIPermissions): {key: string, label: string}[] => {
  if (permissions.overall.organizationTypeAffiliate) {
    return availableDisplayTypes.filter(t => t.key !== "MaximizedExpanded")
  } else {
    return availableDisplayTypes
  }
}

export const availablePlacements = [
  {
    key: 'BottomRight',
    label: 'Bottom right',
  },
  {
    key: 'BottomLeft',
    label: 'Bottom left',
  },
  {
    key: 'TopLeft',
    label: 'Top left',
  },
  {
    key: 'TopRight',
    label: 'Top right',
  },
]

const WidgetSettingsCheckbox: FC<{
  displaySettings: WidgetDisplaySettings,
  setDisplaySettings: (WidgetDisplaySettings) => void,
}> = ({
  displaySettings,
  setDisplaySettings,
}) => {

  let { permissions } = useAppContext()

  let availableDisplayTypes = getAvailableDisplayTypes(permissions)

  return (
    <div>
      <p>
        <b>Widget behaviour</b>
      </p>
      <FormGroup style={{ width: '300px' }}>
        <TextField
          select
          onChange={e => {

            setDisplaySettings({
              ...displaySettings,
              // @ts-ignore
              defaultDisplayType: e.target.value
            })
          }}
          value={displaySettings.defaultDisplayType}
          label="Select default display type"
          InputLabelProps={{
            shrink: true,
          }}
        >
          {availableDisplayTypes?.map((type, i) => {
            return (
              <MenuItem key={i} value={type.key}>
                {type.label}
              </MenuItem>
            )
          })}
        </TextField>
        <FormHelperText>
          <span>Initial size of a widget on your website.</span>
        </FormHelperText>

        <TextField
          style={{ marginTop: '1em' }}
          select
          onChange={e => {
            setDisplaySettings({
              ...displaySettings,
              // @ts-ignore
              defaultPlacement: e.target.value,
            })
          }}
          value={displaySettings.defaultPlacement}
          label="Select default widget placement"
          InputLabelProps={{
            shrink: true,
          }}
        >
          {availablePlacements?.map((placement, i) => {
            return (
              <MenuItem key={i} value={placement.key}>
                {placement.label}
              </MenuItem>
            )
          })}
        </TextField>
        <FormHelperText>
          <span>Initial placement of a widget on your website.</span>
        </FormHelperText>
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label="Remember when user closes the widget"
          value={displaySettings?.rememberClose}
          checked={displaySettings?.rememberClose}
          onChange={() =>
            setDisplaySettings({
              ...displaySettings,
              rememberClose: !displaySettings.rememberClose,
            })
          }
        />
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label="Reset experience on new window"
          value={displaySettings?.resetExperienceInNewWindow}
          checked={displaySettings?.resetExperienceInNewWindow}
          onChange={() =>
            setDisplaySettings({
              ...displaySettings,
              resetExperienceInNewWindow: !displaySettings.resetExperienceInNewWindow,
            })
          }
        />
      </FormGroup>
    </div>
  )
}

export default WidgetSettingsCheckbox
