import React, {FC, ReactNode, SetStateAction, useEffect, useState} from "react";
import {GridControlsWithTabs} from "./GridControlsWithTabs";
import {useAppContext} from "../../contexts/PermissionContext";
import {SortConfiguration} from "./GridSortControl";
import {FilterConfiguration} from "./GridFilterControl";
import {TabContext, TabPanel} from "@mui/lab";

export interface LayoutTabDefinition {
  name: string,
  title: string,
  hidden?: boolean,
  children: ReactNode,
}

export const ContentLayoutWithTabs: FC<{
  tab: string,
  setTab: React.Dispatch<SetStateAction<string>>,
  tabs: LayoutTabDefinition[],
  sort?: SortConfiguration,
  filter?: FilterConfiguration,
  controls?: ReactNode,
}> = ({
  tab,
  setTab,
  tabs,
  sort,
  filter,
  controls,

}) => {

  const [actualTabs, setActualTabs] = useState<LayoutTabDefinition[] | null>(null)

  useEffect(() => {
    setActualTabs(tabs.filter(t => !t.hidden))
  }, [tabs])

  const { setQueryParam, getQueryParam } = useAppContext()

  useEffect(() => {
    if (actualTabs) {
      let queryTab = getQueryParam("tab")
      if (!queryTab) {
        setQueryParam("tab", tab)
      } else if (queryTab !== tab) {
        let matchingTab = actualTabs.filter(t => t.name === queryTab )[0]?.name
        if (!matchingTab) {
          matchingTab = actualTabs[0].name
          setQueryParam("tab", matchingTab)
        }
        setTab(matchingTab)
      }
    }
  }, [tab, actualTabs])

  const handleTabChange = (newTab) => {

    setQueryParam("tab", newTab)
    setTab(newTab)
  }

  return (
    <>
      {actualTabs && actualTabs.length > 0 && (
        <TabContext value={tab}>
          <GridControlsWithTabs tabs={actualTabs} tab={tab} onTabChange={handleTabChange} actions={controls} sort={sort} filter={filter}/>
          {actualTabs.map(t => (
            <TabPanel key={t.name} value={t.name} sx={{padding: "16px"}}>
              {t.children}
            </TabPanel>
          ))}
        </TabContext>
      )}
    </>
  )
}