import {Card, Checkbox, Divider, FormControlLabel, FormGroup, Typography} from '@mui/material'
import './RecentlyCard.css'
import {Button} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {DialogSimple} from "../Reusable/DialogSimple";
import {GuidelinesData} from "../../api/types";
import {organizationGuidelinesGet, organizationGuidelinesUpdate} from "../../api/services";
import {toast} from "react-toastify";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';


const GuidelinesCard = () => {

  const [showForm, setShowForm] = useState(false)

  const onDismissInternal = () => {
    setShowForm(false)
    setGuidelines(null)
  }

  const [guidelines, setGuidelines] = useState(null);
  const [creatorsRequireManualReview, setCreatorsRequireManualReview] = useState(false)

  const [guidelinesObject, setGuidelinesObject] = useState<GuidelinesData>()

  useEffect(() => {
    if (showForm) {
      organizationGuidelinesGet().then(g => {
        setGuidelinesObject(g.data)
        setGuidelines(g.data.guidelines)
        setCreatorsRequireManualReview(g.data.approvalType !== 'PreApproved')
      })
    }
  }, [showForm])

  const save = async () => {

    organizationGuidelinesUpdate({
      id: guidelinesObject.id,
      approvalType: creatorsRequireManualReview ? 'SoftApproval' : 'PreApproved',
      guidelines: guidelines
    }).then(() => {
      toast.success("Guidelines updated successfully")
      onDismissInternal()
    })
  }

  return (
    <>
      <DialogSimple
        open={showForm}
        onDismiss={onDismissInternal}
        title={"Approval and guidelines"}
        maxWidth={"lg"}
        content={
          <>
            <Typography variant={"h5"}>
              Approval process
            </Typography>
            <FormGroup>
              <FormControlLabel control={<Checkbox color={"primary"} checked={creatorsRequireManualReview} onChange={(e) => setCreatorsRequireManualReview(e.target.checked)}/>} label={"Content creators need to pass approval process by default"}/>
            </FormGroup>
            <Typography variant={"body2"} sx={{ fontSize: "12px"}}>
              Enabling this checkbox means your content creators have to go through manual review process for each video experience.
            </Typography>
            <Typography variant={"body2"} sx={{ fontSize: "12px"}}>
              This setting can be changed for each individual content creator.
            </Typography>

            <Divider sx={{ margin: "12px"}}/>

            <Typography variant={"h5"} sx={{ marginTop: "10px" }}>
              Guidelines (optional)
            </Typography>

            <Typography variant={"body1"} sx={{ margin: "12px 0"}}>
              Specify a summary of guidelines that your creators have to follow when making content.
            </Typography>

            <Typography variant={"body1"} sx={{ fontSize: "12px"}}>
              Update the description frequently to match your brand message and new products values.
            </Typography>
            <Typography variant={"body1"} sx={{ fontSize: "12px"}}>
              To set guideline for a specific campaign you need to create a template and add the description on the sidebar.
            </Typography>
            <ReactQuill theme="snow" value={guidelines} placeholder={"Add a short summary of your brand guidelines"} onChange={setGuidelines} style={{ marginTop: "16px" }} modules={{
              clipboard: {
                matchVisual: false
              }
            }} />
          </>
        }
        actions={
          <>
            <Button onClick={save}>Save</Button>
            <Button onClick={onDismissInternal}>Cancel</Button>
          </>
        }
      />
      <div className="recentlyEditedCard">
        <Card>
          <div className="header">
            <h4><Button variant={"contained"} color={"primary"} onClick={() => setShowForm(true)} sx={{ width: "100%" }}>Approval and guidelines</Button></h4>
          </div>
        </Card>
      </div>
    </>
  )
}

export default GuidelinesCard
