import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import '../WidgetSettings.css'
import React, {FC, SetStateAction} from 'react'
import {useAppContext} from "../../../contexts/PermissionContext";
import {Template} from "../../../api/types";

const WidgetSettingsTemplate: FC<{
  templateSettings: Template,
  setTemplateSettings: React.Dispatch<SetStateAction<Template>>,
}> = ({
  templateSettings,
  setTemplateSettings,
}) => {

  const { permissions } = useAppContext()

  const anyEditable =
    permissions?.experience?.templateSettings?.global ||
    permissions?.experience?.templateSettings ||
    permissions?.experience?.templateSettings?.creator

  return (
    anyEditable && (
      <>
        <Divider variant="middle" />

        <FormControl className="template-settings-wrapper">
          <p>
            <b>Template settings</b>
          </p>
          { permissions?.experience?.templateSettings?.global && (
            <FormControlLabel
              control={<Checkbox key={uuidv4()} color="primary" />}
              label="Is global template"
              value={templateSettings.isGlobalTemplate}
              checked={templateSettings.isGlobalTemplate}
              onChange={(event, value) =>
                setTemplateSettings({
                  ...templateSettings,
                  isGlobalTemplate: value,
                })
              }
            />
          )}
          {permissions?.experience?.templateSettings && (
            <FormControlLabel
              control={<Checkbox key={uuidv4()} color="primary" />}
              label="Is organization template"
              value={templateSettings.isOrganizationTemplate}
              checked={templateSettings.isOrganizationTemplate}
              onChange={(event, value) =>
                setTemplateSettings({
                  ...templateSettings,
                  isOrganizationTemplate: value,
                })
              }
            />
          )}
          {permissions?.experience?.templateSettings?.creator && (
            <FormControlLabel
              control={<Checkbox key={uuidv4()} color="primary" />}
              label="Is creator template"
              value={templateSettings.isCreatorTemplate}
              checked={templateSettings.isCreatorTemplate}
              onChange={(event, value) =>
                setTemplateSettings({
                  ...templateSettings,
                  isCreatorTemplate: value
                })
              }
            />
          )}
        </FormControl>
      </>
    )
  )
}

export default WidgetSettingsTemplate
