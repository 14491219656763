import * as React from 'react'

import styles from '../SequenceDemo.module.scss'

import sampleImage from '../../../assets/image_holder_x.jpg'
import { ITreeItem } from 'src/redux/slices/sequenceSlice'
import { ImageWrapper } from '../SequenceImageSingle/SequenceImageSingleStyles'

export interface SequenceImageProps {
  item: ITreeItem
  image?: string
  // images: ImagesData[];
}

const SequenceImage: React.FC<SequenceImageProps> = ({ image, item }) => {
  let cardStyles = item?.cardType?.styles
  return (
    <div className={styles.image}>
      <img src={image || sampleImage} alt="" />
      <div
        className={styles?.directLinkLabel}
        style={{
          ...item?.cardType?.styles,
          backgroundColor: 'transparent',
          opacity: 1,
          left:
            cardStyles?.['aligment'] === 'left'
              ? '0%'
              : cardStyles?.['aligment'] === 'center' ||
                cardStyles?.['aligment'] === undefined
              ? '50%'
              : 'unset',
          right: cardStyles?.['aligment'] === 'right' ? '0%' : 'unset',
          transform:
            cardStyles?.['aligment'] === 'center' ||
            cardStyles?.['aligment'] === undefined
              ? 'translate(-50%, -50%)'
              : 'translate(0%, -50%)',
        }}
      >
        {item?.cardType?.directLinkLabel}
      </div>
      <ImageWrapper
        className={styles.imageWrapper}
        opacity={cardStyles?.['opacity'] / 100 || '0'}
        background={cardStyles?.['backgroundColor'] || '#000000'}
      ></ImageWrapper>
    </div>
  )
}

export default SequenceImage
