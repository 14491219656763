import { FC } from 'react'
import AnnouncementRoundedIcon from '@mui/icons-material/AnnouncementRounded'
import RateReviewRoundedIcon from '@mui/icons-material/RateReviewRounded'
import MarkUnreadChatAltRoundedIcon from '@mui/icons-material/MarkUnreadChatAltRounded'
import ReviewsRoundedIcon from '@mui/icons-material/ReviewsRounded'
import SpeakerNotesOffRoundedIcon from '@mui/icons-material/SpeakerNotesOffRounded'
import { Grid, Typography } from '@mui/material'

export const approvalStatusLabel = status => {
  if (status === 'PendingReview') {
    return 'Draft'
  } else if (status === 'InReviewWaitingCreator') {
    return 'Needs edit'
  } else if (status === 'InReviewWaitingMember') {
    return 'Needs review'
  } else if (status === 'Approved') {
    return 'Approved'
  } else if (status == 'Rejected') {
    return 'Rejected'
  }
}

export const ApprovalStatusIcon: FC<{
  status:
    | 'PendingReview'
    | 'InReviewWaitingCreator'
    | 'InReviewWaitingMember'
    | 'Approved'
    | 'Rejected'
  showTitle: boolean
  startText?: string
}> = ({ status, showTitle, startText }) => {
  const renderIcon = (icon, text) => {
    return (
      <Grid container alignItems={'center'} spacing={1}>
        {startText && <Grid item>{startText}</Grid>}
        <Grid item>{icon}</Grid>
        {showTitle && (
          <Grid item>
            <Typography variant={'h6'}>{text}</Typography>
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <>
      {status === 'PendingReview' &&
        renderIcon(
          <AnnouncementRoundedIcon sx={{ color: '#C4C4C4', width: '.8em' }} />,
          approvalStatusLabel(status),
        )}
      {status === 'InReviewWaitingCreator' &&
        renderIcon(
          <RateReviewRoundedIcon sx={{ color: '#CB00FF', width: '.8em' }} />,
          approvalStatusLabel(status),
        )}
      {status === 'InReviewWaitingMember' &&
        renderIcon(
          <MarkUnreadChatAltRoundedIcon
            sx={{ color: '#0075FF', width: '.8em' }}
          />,
          approvalStatusLabel(status),
        )}
      {status === 'Approved' &&
        renderIcon(
          <ReviewsRoundedIcon sx={{ color: '#11F6B8', width: '.8em' }} />,
          approvalStatusLabel(status),
        )}
      {status === 'Rejected' &&
        renderIcon(
          <SpeakerNotesOffRoundedIcon
            sx={{ color: '#FF0054', width: '.8em' }}
          />,
          approvalStatusLabel(status),
        )}
    </>
  )
}
