import { routes } from 'src/constants'
import { request } from '../apiServices'

export const saveImage = async (
  formData: FormData,
  id: string,
  uploadProgress: any = () => {},
): Promise<{ id: string; location: string }> => {
  let file = formData.get('file')
  let size = file.size
  let fileName = file.name

  let summary = await request({
    url: `${routes.POST_IMAGE}${id}/chunked/${size}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  let total = summary.chunks.length
  let loaded = 0

  let chain = new Promise<void>(resolve => {
    console.log('begin chunk upload')
    resolve()
  })

  let chunkSize = 1
  for (let c = 0; c < summary.chunks.length; c += chunkSize) {

    if (c > 0) {
      chunkSize = 5
    }

    let chunkSlice = summary.chunks.slice(c, c + chunkSize)
    await Promise.all(chunkSlice.map(chunk => {

      return new Promise<void>(resolve => {
        console.log(`uploading chunk ${chunk.number}`)
        let data = new FormData()
        data.append('file', new File([file.slice(chunk.from, chunk.to)], fileName))

        let options = {
          url: `${routes.POST_IMAGE}${id}/chunked/${size}/${chunk.number}`,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: data,
        }


        let proceed = () => {
          loaded = loaded + 1
          uploadProgress({ total: total, loaded: loaded })
          resolve()
        }

        // try up to three times for each part
        request(options, () =>
          request(options, () =>
            request(options)
              .then(proceed)
          ).then(proceed)
        ).then(proceed)
      })
    }))
  }

  return await chain
    .then(function () {
    return request({
      url: `${routes.POST_IMAGE}${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  })
}
