import React, {FC, useState} from "react";
import {Checkbox, FormControlLabel, IconButton} from "@mui/material";
import {Button, InputBase, Paper} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {InfiniteItemGrid, PaginatedContent} from "../Reusable/InfiniteItemGrid";
import {GalleryItem, upload} from "./helpers";
import {ProgressIndicator} from "../Reusable/ProgressIndicator";
import {mediaStorageReplicate, proxyUnsplashGetPhotos, proxyUnsplashTriggerDownload} from "../../api/services";
import {GalleryImageComponent} from "./GalleryItemComponent";
import {DialogSimple} from "../Reusable/DialogSimple";

interface UnsplashObject {
  id: string,
  description: string,
  alt_description: string,
  urls: {
    full: string,
    raw: string,
    regular: string,
    small: string,
    thumb: string,
  }
}

export const UnsplashDialog: FC<{
  open: boolean,
  onSelect: (GalleryItem) => void,
  onDismiss: () => void,
  type: 'DIRECT' | 'GALLERY'
}> = ({
  open,
  onSelect,
  onDismiss,
  type,
}) => {

  const [selectedItem, setSelectedItem] = useState(null)
  const [tempSearchTerm, setTempSearchTerm] = useState(null)
  const [searchTerm, setSearchTerm] = useState(null)
  const [makeAvailableInGallery, setMakeAvailableInGallery] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [items, setItems] = useState<GalleryItem[]>(null)
  const onDismissInternal = () => {
    onDismiss()
    reset()
  }

  const reset = () => {
    setSelectedItem(null)
    setSearchTerm(null)
    setTempSearchTerm(null)
    setItems(null)
    setIsUploading(false)
    setProgress(0)
  }

  let perPage = 24
  const search = async () => {
    setSelectedItem(null)
    setSearchTerm(tempSearchTerm)
  }

  const select = async () => {


    setProgress(0)
    setIsUploading(true)
    let blob = await fetch(selectedItem.url).then(r => r.blob())
    let file = new File([blob], `unsplash-${selectedItem.id}.jpg`)
    // @ts-ignore
    file.label = `Unsplash: ${selectedItem.label}`

    if (type === 'GALLERY') {
      let galleryItem = await upload([file], setProgress, type, (f) => f.label, )
      onSelect(galleryItem[0])

    } else if (type === 'DIRECT' && makeAvailableInGallery === true) {
      let galleryItem = await upload([file], setProgress, 'GALLERY', (f) => f.label, )
      let replica = await mediaStorageReplicate(galleryItem[0].id)
      onSelect(replica.data)

    } else if (type === 'DIRECT' && makeAvailableInGallery === false) {
      let directItem = await upload([file], setProgress, type, (f) => f.label, )
      onSelect(directItem[0])
    }

    await proxyUnsplashTriggerDownload(selectedItem.id)

    reset()
    onDismiss()
  }

  const searchUnsplash = async (page, perPage): Promise<PaginatedContent> => {

    if (searchTerm && searchTerm.length > 0) {
      let response = await proxyUnsplashGetPhotos({
        query: searchTerm,
        per_page: perPage,
        page: page + 1, // unsplash paging starts at 1
      }) as any

      return {
        totalElements: response.data.total,
        page: page,
        perPage: perPage,
        totalPages: response.data.total_pages,
        content: (response.data.results as UnsplashObject[]).map(obj => {
          return {
            id: obj.id,
            location: obj.urls.thumb,
            label: obj.description ?? obj.alt_description ?? "",
            metadata: {},
            url: obj.urls.regular, // sufficient quality
          }
        }),
      }
    } else {
      return {
        totalElements: 0,
        page: page,
        perPage: perPage,
        totalPages: 0,
        content: []
      }
    }
  }

  let itemProps = { xs: 6, sm: 4, md: 3, lg: 2, xl: 2 }

  return (
    <DialogSimple
        open={open}
        onDismiss={onDismissInternal}
        title={"Search on Unsplash"}
        maxWidth={"lg"}
        fullWith={false}
        content={
          <>
            <Paper
              component="form"
              sx={{ display: 'flex', alignItems: 'center', width: "100%", marginBottom: "10px" }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Unsplash"
                inputProps={{ 'aria-label': 'search unsplash' }}
                onKeyDown={async (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    await search()
                  }
                }}
                onChange={(e) => {
                  setTempSearchTerm(e.target.value)
                }}
                autoFocus={true}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={search}>
                <SearchIcon />
              </IconButton>
            </Paper>
            { searchTerm && searchTerm.length > 0 && (
              <InfiniteItemGrid
                perPage={perPage}
                loadItems={(page, perPage) => searchUnsplash(page, perPage) }
                scrollRef={document.getElementById("dialog-simple-dialog-content-id")}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                items={items}
                setItems={setItems}
                itemProps={itemProps}
                component={(item, selected) => <GalleryImageComponent selected={selected} item={item}/>}
              />
            )}
          </>
        }
        actions={
          <>
            { type === 'DIRECT' && (
              <FormControlLabel control={<Checkbox onChange={(e) => { setMakeAvailableInGallery(e.target.checked) }}/>} label={"Make available in gallery"}/>
            )}
            <Button disabled={ selectedItem === null || isUploading} onClick={select}>Select</Button>
            <Button disabled={isUploading} onClick={onDismissInternal}>Cancel</Button>
            <ProgressIndicator isUploading={isUploading} progress={progress}/>
          </>
        }
    />
  )
}