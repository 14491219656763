import {
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { history } from 'src/history'
import { getAssignableBrandsDataThunk } from 'src/redux/slices/brandSlice'
import {
  setBrandId,
  setContentName,
} from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import './SequenceBrand.scss'
import { AutoAwesome } from '@mui/icons-material'
import { sequencePollGetAvailableImageObjectFocusTypes } from '../../api/services'
import {ShareableUserData, PairMediaStorageItemObjectAggregatedTypeString, Settings} from '../../api/types'
import { useHistory } from 'react-router'
import {ExperienceLinkSelection} from "../Experiences/ExperienceLinkSelection";

interface Props {
  sequencePollId?: string,
  link: ShareableUserData,
  setLink: React.Dispatch<React.SetStateAction<ShareableUserData>>,
  linkRequired: Boolean,
  settings: Settings,
  setSettings: React.Dispatch<React.SetStateAction<Settings>>,
}

const SequenceBrand = ({ sequencePollId, settings, setSettings, link, setLink, linkRequired }: Props) => {
  const dispatch = useDispatch()
  const router = useHistory()

  const { contentName, brandId } = useSelector(
    (state: RootState) => state.sequenceReducer,
  )
  const brands = useSelector((state: RootState) => state.brandsReducer.brands)

  const canUpdateAISettings = useSelector(
    (state: RootState) =>
      state.organizationReducer.permissions.experience.updateAISettings,
  )

  const [availableObjectFocusTypes, setAvailableObjectFocusTypes] = useState<
    PairMediaStorageItemObjectAggregatedTypeString[]
  >([])

  useEffect(() => {
    sequencePollGetAvailableImageObjectFocusTypes().then(d => setAvailableObjectFocusTypes(d.data))
  }, [dispatch])

  useEffect(() => {
    if (!brandId && brands && brands.length > 0) {
      dispatch(setBrandId(brands[0].id))
    }
  }, [brandId, brands])

  const changeContentName = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    dispatch(setContentName(e.target.value))
  }

  useEffect(() => {
    dispatch(getAssignableBrandsDataThunk())
  }, [dispatch])

  return (
    <div className="step-one-wrapper">
      <FormControl className="step-one-brand-form">
        <FormHelperText>
          <span>
            Use a{' '}
            <b
              className="clickable-elem"
              onClick={() => router.push('/content/experiences?tab=templates') }
            >
              template &#62;&#62;
            </b>
          </span>
        </FormHelperText>
        <ExperienceLinkSelection link={link} setLink={setLink} linkRequired={linkRequired}/>
        <Divider/>
        <TextField
          value={contentName || ''}
          onChange={e => changeContentName(e)}
          label="Name your content*"
        />
        <TextField
          select
          onChange={e => {
            dispatch(setBrandId(e.target.value))
          }}
          disabled={brands.length === 0}
          value={brandId || 'default'}
          label="Brand *"
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem value="default">Select brand*</MenuItem>
          {Array.isArray(brands) && brands?.length !== 0 ? (
            brands?.map((brand, i) => {
              return (
                <MenuItem key={i} value={brand.id}>
                  {brand.name}
                </MenuItem>
              )
            })
          ) : (
            <option>No brands created</option>
          )}
        </TextField>

        <FormHelperText>
          <span>
            Add or change your brand{' '}
            <b
              className="clickable-elem"
              onClick={() => history.push('/content/brands')}
            >
              here &#62;&#62;
            </b>
          </span>
        </FormHelperText>

        {canUpdateAISettings && (
          <>
            <Divider />
            <h4 style={{ marginBottom: '0px' }}>
              <AutoAwesome sx={{ color: '#FF00EC' }} />
              <span className="ai-features-heading">AI features</span>
            </h4>
            <TextField
              select
              onChange={e => {
                setSettings({
                  ...settings,
                  ai: {
                    ...settings.ai,
                    // @ts-ignore
                    cardFocusedObjectType: e.target.value
                  }
                })
              }}
              disabled={availableObjectFocusTypes.length === 0}
              value={settings.ai.cardFocusedObjectType}
              label="Select focus type"
              InputLabelProps={{
                shrink: true,
              }}
            >
              {Array.isArray(availableObjectFocusTypes) &&
                availableObjectFocusTypes?.length !== 0 &&
                availableObjectFocusTypes?.map((focusType, i) => {
                  return (
                    <MenuItem key={i} value={focusType.first}>
                      {focusType.second}
                    </MenuItem>
                  )
                })}
            </TextField>
            <FormHelperText>
              <span>
                We use machine learning to auto-identify objects and faces
                within uploaded images, here you can select which things to
                focus within the images displayed to end users.
              </span>
            </FormHelperText>
          </>
        )}
      </FormControl>
    </div>
  )
}

export default SequenceBrand
