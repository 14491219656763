import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

import CopyIcon from '@mui/icons-material/FileCopy'
import { toast } from 'react-toastify'
import { UTMParams } from 'src/utils/sequenceSliceHelper'
import './WidgetSettingsUTMParams.css'
import {UTMSettings} from "../../../api/types";

type UTMValidation = {
  [key in keyof UTMParams]: string | boolean
}

const WidgetSettingsUTMParams: React.FC<{
  utmSettings: UTMSettings,
  url: string,
  setUtmSettings: (UTMSettings) => void,
  setUTMSettingsInvalid: React.Dispatch<React.SetStateAction<boolean>>
  experienceId: string
}> = ({
  utmSettings,
  url,
  setUtmSettings,
  setUTMSettingsInvalid,
  experienceId,
}) => {

  const [generatedUrl, setGeneratedUrl] = useState('')

  const [validationErrors, setValidationErrors] = useState<UTMValidation>({
    enabled: '',
    content: '',
    medium: '',
    campaign: '',
    source: '',
    term: '',
  })

  const handleButtonStatus = () => {

    let nullOrEmpty = (val) => val === null || val === ''
    if (
      (nullOrEmpty(utmSettings.source) ||
        nullOrEmpty(utmSettings.medium)||
        nullOrEmpty(utmSettings.campaign)) && utmSettings.enabled
    ) {
      // all utm settings valid even if empty
      // setUTMSettingsInvalid(true)
    } else {
      setUTMSettingsInvalid(false)
    }
  }

  useEffect(() => {
    handleButtonStatus()
  }, [utmSettings])

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text)
    toast.info('URL copied to clipboard')
  }

  const buildUrlFromUtmParams = () => {
    const paramQueries = []
    for (const [key, value] of Object.entries(utmSettings)) {
      if (value) {
        paramQueries.push(`utm_${key}=` + value)
      }
    }
    const generatedUrl = url + '?' + paramQueries.join('&')
    setGeneratedUrl(generatedUrl)
  }

  useEffect(() => {
    buildUrlFromUtmParams()
  }, [utmSettings, url, experienceId])

  useEffect(() => {
    handleButtonStatus()
  }, [utmSettings])

  return (
    <div className="utm-params-wrapper">
      <form>
        <TextField
          margin="normal"
          fullWidth
          required
          error={!!validationErrors['source']}
          placeholder="The referrer (e.g. google, newsletter)"
          label="Campaign Source"
          defaultValue={utmSettings.source}
          onBlur={e => {
            // validate('source', e.target.value)
            setUtmSettings({
              ...utmSettings,
              source: e.target.value,
            })
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          required
          error={!!validationErrors['medium']}
          placeholder="Medium (e.g. cpc, banner, email)"
          label="Campaign Medium"
          defaultValue={utmSettings.medium}
          onBlur={e => {
            // validate('medium', e.target.value)
            setUtmSettings({
              ...utmSettings,
              medium: e.target.value,
            })
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          required
          error={!!validationErrors['campaign']}
          placeholder="Product, promo code (e.g. sprint_sale)"
          label="Campaign Name"
          defaultValue={utmSettings.campaign}
          onBlur={e => {
            // validate('campaign', e.target.value)
            setUtmSettings({
              ...utmSettings,
              campaign: e.target.value,
            })
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          placeholder="Use to differentiate ads"
          label="Campaign Content"
          defaultValue={utmSettings.content}
          onBlur={e => {
            setUtmSettings({
              ...utmSettings,
              content: e.target.value,
            })
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          placeholder="Use to differentiate ads"
          label="Campaign Term"
          defaultValue={utmSettings.term}
          onBlur={e => {
            setUtmSettings({
              ...utmSettings,
              term: e.target.value,
            })
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          label="Generated URL"
          value={generatedUrl}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <CopyIcon
                className="copy-icon"
                onClick={() => copyToClipboard(generatedUrl)}
              />
            ),
          }}
        />
      </form>
    </div>
  )
}

export default WidgetSettingsUTMParams
