import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import 'react-sortable-tree/style.css' // This only needs to be imported once in your app

import {setSequenceId, setSequenceStatus, setTreeItems} from 'src/redux/slices/sequenceSlice'
import store, { RootState } from 'src/redux/store'
import { saveSequencePoll } from 'src/services/api/sequencePolls'
import '../../styles/global.scss'

import StepCreate from 'src/components/Home/Steps/StepCreate'

import { toast } from 'react-toastify'
import { selectStep, setStep } from 'src/redux/slices/creatorSlice'
import './Home.css'
import {ShareableUserData, SharedExperience, Settings} from '../../api/types'
import {
  experienceLinksDoUnshare,
  experienceSharesDoShare,
  experienceSharesGetEstablishedShares,
  sequencePollGetForUpdate,
  sequencePollSaveUpdate
} from "../../api/services";
import {useAppContext} from "../../contexts/PermissionContext";
import {StepCreateCreator} from "./Steps/StepCreateCreator";
import {StepEditor} from "./Steps/StepEditor";
import {useHistory} from "react-router";


export const defaultEmptyExperienceSettings = () => {
  return {
    template: {
      isGlobalTemplate: false,
      isOrganizationTemplate: false,
      isCreatorTemplate: false
    },
    ai: {
      cardFocusedObjectType: "NONE",
    },
    availableFonts: {},
    overlay: {
      url: "",
    },
    widget: {
      excludeUrls: [],
      includeUrls: [],
      renderCardDirectLinkProducts: true,
      showOnAllLastCardURLs: true,
      openLastCardURLsInNewTab: false,
      displaySettings: {
        resetExperienceInNewWindow: false,
        rememberClose: false,
        defaultPlacement: "BottomRight",
        defaultDisplayType: "Maximized",
        enabled: true,
      },
      displayDeviceTypeSettings: {
        enabled: false,
        desktop: true,
        mobile: true,
        tablet: true,
      },
      utm: {
        enabled: false,
        campaign: "",
        content: "",
        medium: "",
        source: "",
        term: "",
      }
    }
  } as Settings
}

const Home: React.FC = () => {
  const dispatch = useDispatch()
  const step = useSelector(selectStep)
  const router = useHistory()

  const { permissions, getQueryParam } = useAppContext()

  const [isLoading, setIsLoading] = useState(false)
  const [isAbleToCreate, setIsAbleToCreate] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [isExperienceEditorLoading, setIsExperienceEditorLoading] = useState(false)

  const [brandContentWarning, setBrandContentWarning] = useState(false)

  const [showWidgetSettings, setShowWidgetSettings] = useState(false)

  const [settings, setSettings] =
    useState<Settings>(defaultEmptyExperienceSettings())

  const sequencePoll = useSelector((state: RootState) => state.sequenceReducer)

  const sequencePollId = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceId,
  )

  const [link, setLink] = useState<ShareableUserData>(null)
  const [linkedExperiences, setLinkedExperiences] = useState<SharedExperience[]>()

  const extractLinkedUserFromLinkedExperience = (experience: SharedExperience): ShareableUserData => {
    if (experience) {
      return {
        organizationId: experience.targetOrganizationId,
        organizationName: experience.targetOrganizationName,
        origins: experience.origins,
        userId: undefined
      }
    } else {
      return null
    }
  }

  useEffect(() => {
    if (sequencePollId) {
      sequencePollGetForUpdate(sequencePollId).then(d => setSettings(d.data.settings))
    } else if (permissions) {
      let defaultSettings = defaultEmptyExperienceSettings()
      if (permissions.overall?.organizationCreator) {
        defaultSettings.widget.displaySettings.defaultDisplayType = 'Maximized'
      }
      setSettings(defaultSettings)
    }
    if (permissions?.experience?.shareExperience && !linkedExperiences) {
      experienceSharesGetEstablishedShares().then(d => {
        let links = d.data
        setLinkedExperiences(links)
        let establishedLink = links.find(link => link.id === sequencePollId)
        if (establishedLink) {
          setLink(extractLinkedUserFromLinkedExperience(establishedLink))
        }
      })
    }
  }, [sequencePollId, permissions, linkedExperiences])

  const contentName = useSelector(
    (state: RootState) => state.sequenceReducer.contentName,
  )

  const brandId = useSelector(
    (state: RootState) => state.sequenceReducer.brandId,
  )

  const { items } = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceBox,
  )

  const publishHandler = async (moveNextStep: boolean) => {
    setIsExperienceEditorLoading(true)
    try {
      let response = await saveSequencePoll(
        items,
        contentName,
        brandId,
        sequencePollId,
        'Active',
        settings,
        sequencePoll.originHint,
        true,
      )
      await attemptLink(response.id)

      if (moveNextStep) {

        if (sequencePollId) {
          toast.info(
            <Box>
              <div>
                Your new changes might take up to a few minutes to appear on the live experience.
              </div>
              <div>
                Also to see the new changes make sure the browser cache is cleaned
              </div>
            </Box>
          )
        } else {
          toast.info(
            <Box>
              <div>
                Your content is published successfully! 🎉
              </div>
              <div>
                Find the links to your content and manage it via tools on the sidebar
              </div>
            </Box>
          )
        }

        let tab = getQueryParam("tab")

        // upon publishing a draft item, it goes to active tab
        if (tab === "draft") {
          tab = "active"
        }

        if (response.ownerType === "Creator" && permissions.overall.organizationMember) {
          router.push('/creators/experiences?id=' + response.id + (tab ? `&tab=${tab}` : ""))
        } else {
          router.push('/content/experiences?id=' + response.id + (tab ? `&tab=${tab}` : ""))
        }
      } else {
        toast.success("Experience updated")
      }
      setIsExperienceEditorLoading(false)
    } catch (err) {
      setIsExperienceEditorLoading(false)
    }
  }

  const handleChangeStep = (type: 'next' | 'back') => {
    let tempItems = JSON.parse(JSON.stringify(items))
    // changeIds(tempItems);
    dispatch(setTreeItems(tempItems))
    if (type === 'next' && step === 1) {
      if (!brandId || !contentName) {
        setBrandContentWarning(true)
        return
      }
    }
    type === 'next'
      ? step < 4 && dispatch(setStep(step + 1))
      : dispatch(setStep(step - 1))
  }

  const attemptLink = async (sequencePollId: string) => {

    if (linkedExperiences && sequencePollId) {

      let existingLink = linkedExperiences.find(l => l.id === sequencePollId)
      let existingUser = extractLinkedUserFromLinkedExperience(existingLink)
      if (existingUser?.organizationId !== link?.organizationId) {
        if (existingUser) {
          await experienceLinksDoUnshare(existingLink.id)
        }
        if (link) {
          await experienceSharesDoShare({
            experienceId: sequencePollId,
            toOrganizationId: link.organizationId,
          })
        }
      }
    }
    console.log("attempt link now")
  }

  const handleSaveSettings = async (newSettings) => {
    if (sequencePollId) {
      let updateObject = await sequencePollGetForUpdate(sequencePollId)
      await sequencePollSaveUpdate({
        ...updateObject.data,
        settings: newSettings ?? settings
      }, {
        refresh: true,
      })
      toast.success("Widget settings updated successfully")
      await attemptLink(sequencePollId)
    }
  }

  const checkPlan = async () => {
    setIsLoading(true)
    // let status = await checkPaymentPlan();
    // setIsAbleToCreate(status?.canCreateSequencePoll);
    setIsAbleToCreate(true)
    setOpenDialog(true)
    setIsLoading(false)
  }



  useEffect(() => {
    checkPlan()
  }, [])

  /**
   * Activate autosave on step 2, stop on step 3
   */
  useEffect(() => {

    let autosave = async () => {

      const sequence = store.getState().sequenceReducer
      if (sequence.autosave && step < 3 && sequence.brandId && sequence.contentName) {

        try {
          let res = await saveSequencePoll(
            sequence.sequenceBox.items,
            sequence.contentName,
            sequence.brandId,
            sequence.sequenceId,
            sequence.status,
            settings,
            sequencePoll.originHint,
            false, // do not refresh to users through automatic update
          )
          if (sequence.sequenceId === '') {
            store.dispatch(setSequenceId(res.id))
          }
          // do not link as part of autosave. it should not trash advertiser organization
          // await attemptLink(res.id)
          store.dispatch(setSequenceStatus(res.status))
        } catch (err) {
          // TODO
        }
      }
    }

    if (step < 3) {
      let interval = setInterval(autosave, 60000)
      return () => clearInterval(interval)
    }
  }, [step])

  useEffect(() => {
    return () => {
      dispatch(setStep(1))
    }
  }, [])

  if (isLoading)
    return <CircularProgress className="circular-progress-center" />

  // if (user?.email_verified === false) return <EmailVerification />;

  return (
    <>
      {brandContentWarning && (
        <Dialog
          open={brandContentWarning}
          onClose={() => setBrandContentWarning(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="dialog-title">
            <IconButton onClick={() => setBrandContentWarning(false)}>
              <span className="material-icons-outlined">X</span>
            </IconButton>
          </DialogTitle>
          {/* <DialogTitle id="alert-dialog-title">{title}</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>
                <b>Fields are required</b>
              </Typography>
              <Typography>
                Name of your content and brand fields are required!
              </Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      {!isAbleToCreate && (
        <Dialog
          open={openDialog}
          // onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            You reached your plan limit. In order to create new experiences:
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>
                Update your plan{' '}
                <Link to="/account/organization">Here &gt;</Link>{' '}
              </Typography>{' '}
              <Typography>
                Archive active experiences{' '}
                <Link to="/experiences">Here &gt;</Link>{' '}
              </Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      {step === 1 && (
        <>
          { permissions?.experience?.editorType === 'Standard' && (
            <StepCreate
              isAbleToCreate={
                isAbleToCreate &&
                contentName.length > 0 &&
                brandId &&
                brandId !== 'default' &&
                (!(permissions?.experience?.experienceLinkRequired) || Boolean(link))
              }
              showWidgetSettings={showWidgetSettings}
              setShowWidgetSettings={setShowWidgetSettings}
              settings={settings}
              setSettings={setSettings}
              handleSaveSettings={handleSaveSettings}
              handleChangeStep={handleChangeStep}
              link={link}
              setLink={setLink}
              linkRequired={permissions?.experience?.experienceLinkRequired}
            />
          )}
          { permissions?.experience?.editorType === 'Simple' && (
            <StepCreateCreator
              settings={settings}
              setSettings={setSettings}
              link={link}
              setLink={setLink}
              linkRequired={permissions?.experience?.experienceLinkRequired}
            />
          )}
        </>
      )}
      {step === 2 && (
        <StepEditor
          publishHandler={publishHandler}
          isExperienceEditorLoading={isExperienceEditorLoading}
          sequencePollId={sequencePollId}
          link={link}
          handleChangeStep={handleChangeStep}
        />
      )}
    </>
  )
}

export default Home
