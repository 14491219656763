import React, { FC, ReactNode, useContext } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { SidebarContext } from '../../../../contexts/SidebarContext'

import PropTypes from 'prop-types'
import { Button, Badge, Collapse, ListItem } from '@mui/material'
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import { useDispatch } from 'react-redux'
import { resetSequenceData } from 'src/redux/slices/sequenceSlice'
import {setStep} from "../../../../redux/slices/creatorSlice";

interface SidebarMenuItemProps {
  children?: ReactNode
  link?: string
  icon?: any
  badge?: string
  open?: boolean
  name: string
  resetData?: boolean
  setActiveSidebarMenu?: any
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  open: isParentOpened,
  name,
  resetData,
  setActiveSidebarMenu,
  ...rest
}) => {
  const dispatch = useDispatch()
  const { toggleSidebar } = useContext(SidebarContext)

  const handleClick = () => {
    dispatch(resetSequenceData())
    dispatch(setStep(1))
    toggleSidebar()
  }

  const toggleMenu = (): void => {
    setActiveSidebarMenu(isParentOpened ? '' : name)
  }

  if (children) {
    return (
      <ListItem className="Mui-children" key={name} {...rest}>
        <Button
          className={clsx({ 'Mui-active': isParentOpened })}
          startIcon={Icon && <Icon />}
          endIcon={
            isParentOpened ? (
              <ExpandLessTwoToneIcon />
            ) : (
              <ExpandMoreTwoToneIcon />
            )
          }
          onClick={toggleMenu}
        >
          {name}
        </Button>
        <Collapse in={isParentOpened}>{children}</Collapse>
      </ListItem>
    )
  }

  return (
    <ListItem
      key={name}
      selected={window.location.href.indexOf(link) !== -1}
      {...rest}
    >
      <Button
        activeClassName="Mui-active"
        component={RouterLink}
        onClick={resetData ? handleClick : toggleSidebar}
        to={link}
        startIcon={Icon && <Icon />}
      >
        {name}
        {badge && <Badge badgeContent={badge} />}
      </Button>
    </ListItem>
  )
}

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
}

SidebarMenuItem.defaultProps = {
  open: false,
}

export default SidebarMenuItem
