import React, {FC} from "react";
import ContentWrapper from "../../ContentWrapper/ContentWrapper";
import {
  Box,
  CircularProgress,
  Drawer,
  Grid,
  useTheme
} from "@mui/material";
import {Scrollbars} from "react-custom-scrollbars-2";
import SequenceBox from "../../SequenceBox/SequenceBox";
import SequenceDemo from "../../SequenceDemo/SequenceDemo";
import RefreshIcon from "@mui/icons-material/Refresh";
import SequenceOptions from "../SequenceOptions/SequenceOptions";
import {experimentalStyled} from "@mui/material/styles";
import {SIDEBAR_WIDTH} from "../../../utils/layoutConstants";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {ShareableUserData} from "../../../api/types";
import {selectMode} from "../../../redux/slices/creatorSlice";


const DrawerWrapper = experimentalStyled(Drawer)(
  ({ theme }) => `
    width: 300px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 250px;
        height: calc(100% - ${theme.header.height});
        position: fixed;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        color: ${theme.colors.alpha.white[100]};
        border-left: unset;
    }
`,
)

const ListDrawerWrapper = experimentalStyled(Drawer)(
  ({ theme }) => `
    width: calc(100% - ${SIDEBAR_WIDTH});
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: calc(100% - 550px);
        min-width: 315px;
        height: calc(100% - 120px);
        top: 125px;
        z-index: 1;
        border: 0;
        color: ${theme.colors.alpha.white[100]};
    }

    @media only screen and (min-width: 1280px) {
      & > .MuiPaper-root {
        left: 320px;        
        width: calc(100% - 585px);
      }
    }

    @media only screen and (max-width: 1279px) {
      & > .MuiPaper-root {
        left: 45px;
        width: calc(100% - 315px);
      }
    }    

    @media only screen and (max-width: 959px) {
      & > .MuiPaper-root {
        left: 45px;
        width: calc(100% - 315px);
      }
    }
`,
)

const MainContentWrapper = experimentalStyled(Box)(
  () => `
  flex-grow: 1;
`,
)

const ButtonsWrapper = experimentalStyled(Box)(
  () => `
    display: flex;
    justify-content: center;
  `,
)

const PublishButton = experimentalStyled(Button)(
  () => `
    margin-left: 6px;
    width: 140px;
  `,
)

const SetupButton = experimentalStyled(Button)(
  () => `
    width: 140px;
  `,
)

const MainGridWrapper = experimentalStyled(Grid)(
  () => `
    height: 94%;
  `,
)

const GridWrapper = experimentalStyled(Grid)(
  () => `
    height: 100%;
  `,
)

export const StepEditor: FC<{
  publishHandler: (boolean) => void,
  isExperienceEditorLoading: boolean,
  sequencePollId?: string,
  link: ShareableUserData,
  handleChangeStep: (type: 'next' | 'back') => void,
}> = ({
  publishHandler,
  isExperienceEditorLoading,
  sequencePollId,
  link,
  handleChangeStep,
}) => {

  const theme = useTheme()

  const { items } = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceBox,
  )

  const mode = useSelector(selectMode)

  return (
    <>
      <ContentWrapper title="Experience List">
        <Box>
          <MainContentWrapper>
            <ListDrawerWrapper variant="permanent" anchor="left" open>
              <Scrollbars>
                <MainGridWrapper
                  container
                  direction="row"
                  justifyContent="center"
                  spacing={3}
                >
                  <GridWrapper item xs={12}>
                    <GridWrapper
                      pr={3}
                      container
                      spacing={3}
                      style={{ gridTemplateColumns: 'auto' }}
                    >
                      <GridWrapper mt={1} item xs={12} sm={12} md={7} lg={7}>
                        <SequenceBox
                          mode={mode}
                          experienceId={sequencePollId}
                        />
                      </GridWrapper>
                      <GridWrapper mt={1} item xs={12} sm={12} md={5} lg={5}>
                        <SequenceDemo />
                      </GridWrapper>
                      <Grid mt={0.5} item xs={12} sm={12} md={12} lg={12}>
                        <ButtonsWrapper>
                          <SetupButton
                            variant="outlined"
                            color="primary"
                            disabled={isExperienceEditorLoading}
                            onClick={() => handleChangeStep('back')}
                          >
                            {'< Setup'}
                          </SetupButton>
                          <PublishButton
                            variant="outlined"
                            color="primary"
                            disabled={isExperienceEditorLoading || items[0].children.length < 1 || mode !== "Editor"}
                            onClick={() => publishHandler(true) }
                            title="add items and click to next"
                          >
                            {'Publish >'}
                          </PublishButton>
                          <PublishButton
                            variant="text"
                            color="primary"
                            disabled={isExperienceEditorLoading || !sequencePollId || mode !== "Editor"}
                            onClick={() => publishHandler(false) }
                            title="add items and click to next"
                            endIcon={<RefreshIcon/>}
                          >
                            {'Update'}
                          </PublishButton>
                          <Box sx={{ width: "30px", height: "30px" }}>
                            { isExperienceEditorLoading && (
                              <CircularProgress/>
                            )}
                          </Box>
                        </ButtonsWrapper>
                      </Grid>
                    </GridWrapper>
                  </GridWrapper>
                </MainGridWrapper>
              </Scrollbars>
            </ListDrawerWrapper>
          </MainContentWrapper>
        </Box>
      </ContentWrapper>
      <DrawerWrapper variant="permanent" anchor={theme.direction === 'rtl' ? 'left' : 'right'} open>
        { mode === "Editor" && (
          <Scrollbars>
            <SequenceOptions link={link} />
          </Scrollbars>
        )}
      </DrawerWrapper>
    </>
  )
}