import React, {useState, useEffect} from 'react'
import {UIConstants, UIPermissions} from "../api/types";
import {uiGetConstants, uiGetPermissions} from "../api/services";
import {RootState} from "../redux/store";
import {useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";

interface PermissionContextParams {
  permissions: UIPermissions,
  getQueryParam: (key: string) => string | null,
  setQueryParam: (key: string, value: string) => void,
  removeQueryParam: (key: string) => void,
  constants: UIConstants,
}

const AppCustomContext = React.createContext<PermissionContextParams>(null)

export function useAppContext() {
  return React.useContext(AppCustomContext)
}

export function AppCustomContextProvider(props) {

  const [permissions, setPermissions] = useState(null)
  const [constants, setConstants] = useState(null)

  const accessToken = useSelector((state: RootState) => state.authReducer.token)

  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (permissions === null && accessToken?.length > 0) {
      uiGetPermissions().then(p => setPermissions(p.data))
    }
    if (constants === null && accessToken?.length > 0) {
      uiGetConstants().then(c => setConstants(c.data))
    }
  }, [constants, permissions, accessToken])

  return (
    <AppCustomContext.Provider
      value={{
        permissions: permissions,
        constants: constants,
        getQueryParam: (key) => searchParams.get(key) ?? null,
        setQueryParam: (key, value) => {
          searchParams.set(key, value)
          history.replace({ search: "?" + searchParams.toString() })
        },
        removeQueryParam: (key) => {
          searchParams.delete(key)
          history.replace({ search: "?" + searchParams.toString() })
        }
      }}>
      {props.children}
    </AppCustomContext.Provider>
  )
}