import React, { FC, HTMLProps, ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'
import { Container, Grid } from '@mui/material'
import './ContentWrapper.css'

interface ContentWrapperProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode
  title?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

const ContentWrapper: FC<
  ContentWrapperProps
> = ({
  children,
  title = '',
  maxWidth = "lg"
}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Container className={'contentWrapper'} sx={{ mt: 3 }} maxWidth={maxWidth}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default ContentWrapper
