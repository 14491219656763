import React, {FC, ReactNode} from "react";
import {Box, Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme} from "@mui/material";
import {DialogCloseButton} from "./DialogCloseButton";
import DialogActions from "@mui/material/DialogActions";
import {useIsMobileLayout} from "../../utils/layoutConstants";


export const DialogSimple: FC<{
  open: boolean,
  onDismiss: () => void,
  title?: ReactNode,
  content: ReactNode,
  actions?: ReactNode,
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
  fullWith?: boolean,
  canClose?: boolean,
  additionalStyles?: object,
}> = ({
  open,
  onDismiss,
  title,
  content,
  actions,
  maxWidth,
  fullWith = true,
  canClose = true,
  additionalStyles,
}) => {

  const isMobile = useIsMobileLayout()

  return (
    <Dialog open={open}
            onClose={onDismiss}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={maxWidth}
            fullWidth={fullWith}
            sx={{
              '& .MuiPaper-root': isMobile ? {
                margin: '0px',
                maxHeight: 'calc(100% - 32px)',
                width: 'calc(100% - 32px)',
              } : {
              }
            }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box sx={{ fontWeight: "bold"}}>
          {title}
        </Box>
        <DialogCloseButton canClose={canClose} onDismiss={onDismiss}/>
      </DialogTitle>
      <DialogContent id={"dialog-simple-dialog-content-id"} sx={{ maxHeight: "70vh", ...additionalStyles}}>
        {content}
      </DialogContent>
      {actions && (
        <DialogActions>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  )
}