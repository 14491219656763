import {FC, useEffect, useState} from 'react'
import {DialogSimple} from "../Reusable/DialogSimple";
import {CreatorProfileData} from "../../api/types";
import {adminOrganizationOwnerCreatorProfileGet, adminOrganizationOwnerCreatorProfileUpdate} from "../../api/services";
import {CreatorProfileForm} from "../Account/UserSettings/CreatorProfileForm";
import {toast} from "react-toastify";


export const DialogModifyCreatorProfile: FC<{
    organizationId: string,
    open: boolean,
    onDismiss: () => void,
    onSave: () => void,
}> = ({
    organizationId,
    open,
    onDismiss,
    onSave,
}) => {

    const [creatorProfile, setCreatorProfile] = useState<CreatorProfileData>()

    const getData = () => {
        adminOrganizationOwnerCreatorProfileGet(organizationId).then(d => setCreatorProfile(d.data))
    }

    const updateData = (profile) => {
        adminOrganizationOwnerCreatorProfileUpdate(organizationId, profile).then(() => {
            getData()
            toast.success("Creator profile updated")
            onDismiss()
        })
    }

    useEffect(() => {
        if (organizationId) {
            getData()
        }
    }, [organizationId])



    return (
        <DialogSimple
            open={open}
            onDismiss={onDismiss}
            title={"Modify owner creator profile"}
            maxWidth={"sm"}
            content={
            <>
                { creatorProfile && (
                    <CreatorProfileForm creatorProfileData={creatorProfile} updateData={updateData}/>
                )}
            </>
        }></DialogSimple>
    )
}