import React, {FC, ReactNode} from "react";
import {Button, FormHelperText} from "@mui/material";


export const InlineCSVSelector: FC<{

  title: string | ReactNode,
  onFileReady: (f: File) => void,
}> = ({
  title,
  onFileReady,
}) => {

  return (
    <>
      <input id="csv-selector-upload-file" name="csv-selector-upload-file" type="file" accept={"text/csv"}
             style={{display: "none"}} onChange={async (e) => {
        let files = Array.from(e.target.files || [])
        if (files.length > 0) {
          onFileReady(files[0])
        }
      }}/>
      <Button variant={"outlined"} component={"label"} htmlFor={"csv-selector-upload-file"}>Select</Button>
      { typeof title === 'string' ? (
        <FormHelperText>{title}</FormHelperText>
      ) : (
        <>
          {title}
        </>
      )}
    </>
  )
}