import {FC, useEffect, useState} from "react";
import {GalleryItem} from "../Gallery/helpers";
import {mediaStorageGetSingle} from "../../api/services";


export const InlineImage: FC<{
  id: string,
}> = ({
  id,
}) => {

  const [item, setItem] = useState<GalleryItem>()

  useEffect(() => {
    if (id) {
      mediaStorageGetSingle(id).then(d => {
        setItem(d.data)
      })
    }
  }, [id]);

  return (
    <>
      { item && (
        <img src={item.location} style={{ width: "100%", height: "100%", objectFit: "cover" }}/>
      )}
    </>
  )
}