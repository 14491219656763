import React, {FC, useState} from "react";
import {liveStreamsGetAll} from "../../api/services";
import {Button} from "@mui/material";
import {InfiniteItemGrid} from "../Reusable/InfiniteItemGrid";
import {LiveStreamGridComponent} from "./LiveStreamGridComponent";
import {DialogSimple} from "../Reusable/DialogSimple";

export const LiveStreamPickerDialog: FC<{
  open: boolean,
  itemReady: (LiveStreamListData) => void,
  onDismiss: () => void,
}> = ({
        open,
        itemReady,
        onDismiss,
      }) => {

  const [liveStreams, setLiveStreams] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)

  const onDismissInternal = () => {
    onDismiss()
    reset()
  }

  const reset = () => {
    setSelectedItem(null)
    setLiveStreams(null)
  }

  const onSelect = async () => {

    itemReady(selectedItem)
    onDismissInternal()
  }

  let perPage = 24

  let itemProps = { xs: 6, sm: 4, md: 3, lg: 2, xl: 2 }

  return (
    <DialogSimple
        open={open}
        onDismiss={onDismissInternal}
        title={"Select live stream"}
        maxWidth={"lg"}
        content={
          <>
            { open && (
              <InfiniteItemGrid
                perPage={perPage}
                loadItems={(page, perPage) => liveStreamsGetAll({ status: "Scheduled", owner: 'Member', page: page, perPage: perPage }).then(items => items.data)}
                scrollRef={document.getElementById("dialog-simple-dialog-content-id")}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                items={liveStreams}
                setItems={setLiveStreams}
                itemProps={itemProps}
                component={(item, selected) => <LiveStreamGridComponent selected={selected} item={item} />}
              />
            )}
          </>
        }
        actions={
          <>
            <Button disabled={ selectedItem === null } onClick={onSelect}>Select</Button>
            <Button onClick={onDismissInternal}>Cancel</Button>
          </>
        }
      />
  )
}
