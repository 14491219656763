export const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production'

export const BASE_API_URL = IS_PRODUCTION
  ? 'https://api.contester.net'
  : 'https://staging-api.contester.net'

export const API_URL = BASE_API_URL + '/api'

export const alphaFeaturesEnabled = () => new URLSearchParams(window.location.search).get("contester_alpha") === 'true' || !IS_PRODUCTION

export const INSTAGRAM_REDIRECT_URI = window.location.origin + "/integration/instagram/handleCode"
export const INSTAGRAM_APP_ID = "3114867768819216"
export const INSTAGRAM_SCOPE = "user_profile,user_media"

export const DEFAULT_MEDIA_ID = IS_PRODUCTION
  ? '49dfcc00-0c19-4735-b19d-985e5d713b02'
  : '83b0f6b6-bb0e-42bb-bffc-0de472777274'
export const DEFAULT_MEDIA_URL = IS_PRODUCTION
  ? 'https://media.contester.net/data/46293b2e-7790-474e-8f68-2af924e412a9/49dfcc00-0c19-4735-b19d-985e5d713b02-original.jpg'
  : 'https://staging-media.contester.net/data/ddcd2781-158e-4319-a6ab-76b37b52d519/83b0f6b6-bb0e-42bb-bffc-0de472777274-.jpg'

export const API_URL_BO_SEQUENCE_POLL = API_URL + '/bo/sequencepolls'

export const REACT_APP_DEV_CLIENT_ID = 'M3tzlapCImj8wN4XDgb3JJNVMp0typki'
export const REACT_APP_DEV_AUDIENCE = 'https://staging-api.contester.net'

export const REACT_APP_PROD_CLIENT_ID = 'dfjIQ5X6moEA3n7jHzHmKGBDaj97f1ac'
export const REACT_APP_PROD_AUDIENCE = 'https://api.contester.net'

export const ALLOWED_EXPERIENCE_CARDS = 100
export const routes = {
  // commenting out routes which were migrated to new API client

  // GET_PROFILE: API_URL + '/bo/users/profile',
  SAVE_UPDATE_SEQUENCE_POLL: API_URL_BO_SEQUENCE_POLL + '/update',
  PUT_IMAGE: API_URL + '/bo/mediastorage',
  POST_IMAGE: API_URL + '/bo/mediastorage/',
  // BRANDS_API: API_URL + '/bo/brands',
  // GET_SEQUENCE_POLLS: API_URL_BO_SEQUENCE_POLL + '/list',
  // GET_SEQUENCE_POLL_PUBLIC: API_URL + '/public/sequencepolls/',
  // GET_ACTIVE_ORGANIZATION: API_URL + '/bo/users/activeOrganization',
  // ORGANIZATION_PROFILE: API_URL + '/bo/organizations/profile',
  // ORGANIZATION_MEMBERS: API_URL + '/bo/organizations/members',
  // GET_CREATORS: API_URL + '/bo/organizations/creators',
  // AVAILABLE_ORGANIZATIONS: API_URL + '/bo/users/availableOrganizations',
  // ORGANIZATION_INVITATIONS: API_URL + '/bo/organizations/invitations',
  // INVITE_MEMBER_ORGANIZATION: API_URL + '/bo/organizations/members/invite',
  // INVITE_CREATOR: API_URL + '/bo/organizations/creators/invite',
  // SWITCH_ORGANIZATION: API_URL + '/bo/users/switch',
  // USER_INVITATIONS: API_URL + '/bo/users/invitations',
  // CHECK_PAYMENT_SUMMARY: API_URL + '/bo/organizations/paymentPlanSummary',
  // SET_FEATURED_IMAGE: API_URL + '/bo/sequencepolls/',
  UPDATE_PROFILE: API_URL + '/bo/users/profile',
  // UPDATE_PAYMENT_PLAN: API_URL + '/bo/organizations/subscribePaymentPlan',
  // GET_EXPERIENCE_COLLECTIONS: API_URL + '/bo/experiencecollections',
  // POST_VALIDATE_URL: API_URL + '/bo/sequencepolls/validateExternalUrl',
  PAYMENT_PLAN_SUMMARY: API_URL + '/bo/organizations/paymentPlanSummary',
  BILLING_CARDS: API_URL + '/bo/billing/cards',
  BILLING_PRICING: API_URL + '/bo/billing/pricing',
  BILLING_DETAILS: API_URL + '/bo/billing/details',
  GET_FONTS: API_URL + '/bo/organizations/availableFonts',
}
