import {CircularProgress, Grid, Paper} from "@mui/material";
import React, {FC, ReactNode, useEffect, useState} from "react";
import {debounce} from "lodash";
import './InfiniteItemGrid.css'
import {Box} from "@mui/material";

export interface GridContext {
  refreshGrid: () => void,
  create: () => void,
}

export const gridContextEmpty: GridContext = {
  refreshGrid: () => { },
  create: () => { }
}

export const gridItemPropsDefault = { xs: 6, sm: 6, md: 4, lg: 3, xl: 2 }

interface ItemGridProps {
  items: any[],
  component: (any, boolean) => ReactNode,
  selectedItem?: any,
  setSelectedItem?: React.Dispatch<React.SetStateAction<any>>,
  itemProps?: {
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number,
  }
}

export const ItemGrid: FC<ItemGridProps> = ({
  items,
  component,
  selectedItem,
  setSelectedItem,
  itemProps = { },
}) => {


  return (
    <Grid container spacing={2}>
      {items && items.map(item =>
        // @ts-ignore
        <Grid key={item.id} item { ...itemProps }>
          <Paper className={"fade-in"} elevation={2} onClick={(e) => {
            if (setSelectedItem) {
              setSelectedItem(item)
            }
          }}>
            {component(item, item === selectedItem)}
          </Paper>
        </Grid>
      )}
    </Grid>
  )
}


export interface PaginatedContent {
  content: any[],
  page: number,
  perPage: number,
  totalPages: number,
  totalElements: number,
}

interface InfiniteItemGridProps {
  scrollRef: HTMLElement,
  perPage: number,
  loadItems: (page: number, perPage: number) => Promise<PaginatedContent>,
  setItems: React.Dispatch<React.SetStateAction<any[]>>,
}

export const InfiniteItemGrid: FC<ItemGridProps & InfiniteItemGridProps> = ({
  items,
  component,
  selectedItem,
  setSelectedItem,
  itemProps = gridItemPropsDefault,
  scrollRef,
  perPage,
  loadItems,
  setItems,
}) => {


  const [totalItems, setTotalItems] = useState(items?.length)
  const [isLoading, setIsLoading] = useState(false)

  const loadItemsFunction = async () => {
    if (!(items?.length >= 0) || !(totalItems >= 0) || items.length < totalItems) {
      if (!isLoading) {
        let page = Math.floor((items?.length ?? 0) / perPage)
        setIsLoading(true)
        let data = await loadItems(page, perPage)
        setTotalItems(data.totalElements)
        let arr = (items ?? []).concat(data.content)
        setIsLoading(false)
        setItems(arr)
        // do not auto-select first item
        // if (!selectedItem) {
        //   setSelectedItem(arr[0])
        // }
      }
    }
  }

  useEffect(() => {
    let func = debounce((event) => {
      if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 400) {
        loadItemsFunction()
      }
    }, 100)
    if (scrollRef) {
      scrollRef.addEventListener('scroll', func)
      return () => {
        scrollRef.removeEventListener('scroll', func)
      }
    }
  }, [scrollRef, items, totalItems, perPage])

  useEffect(() => {
    if (!items) {
      loadItemsFunction()
    } else if (scrollRef && scrollRef.scrollHeight == scrollRef.clientHeight) {
      // scrollbar not visible yet
      loadItemsFunction()
    }
  }, [items])

  return (
    <Grid container spacing={2}>
      {items && items.map((item, index) =>
        // @ts-ignore
        <Grid key={`infinite-item-grid-index-${index}`} item { ...itemProps }>
          <Paper className={"fade-in"} elevation={2} onClick={() => setSelectedItem ? setSelectedItem(item) : {} }>
            {component(item, item === selectedItem)}
          </Paper>
        </Grid>
      )}
      { isLoading && (
        <Box sx={{ margin: "30px" }}>
          <CircularProgress/>
        </Box>
      )}
    </Grid>
  )
}