

export const formatSeconds = (totalSeconds: number) => {

  if (totalSeconds < 0) {
    return "" + totalSeconds
  }
  let title = ""
  if (totalSeconds > 36000) {
    let hours = Math.round(totalSeconds / 3600)
    let minutes = Math.round(totalSeconds % (3600 * hours) / 60)
    title = `${hours}h${minutes}m`
  } else if (totalSeconds > 3600) {
    let hours = Math.round(totalSeconds / 3600)
    let minutes = Math.round(totalSeconds % (3600 * hours) / 60)
    let seconds = Math.round(totalSeconds % 60)
    title = `${hours}h${minutes}m${seconds}s`
  } else {
    let minutes = Math.round(totalSeconds / 60)
    let seconds = Math.round(totalSeconds % 60)
    title = `${minutes}m${seconds}s`
  }

  return title
}