import React, {FC, useState} from "react";
import {Box, InputAdornment, TextField} from "@mui/material";
import {InviteButtonWrapper} from "./OrganizationMembers";
import {OrganizationInviteResponse} from "../../../api/types";
import {toast} from "react-toastify";
import {SwaggerResponse} from "../../../api/config";


interface MemberInvitationFormParams {
  canInvite: boolean,
  label: string,
  handleInvite: (emails) => Promise<SwaggerResponse<OrganizationInviteResponse>>
  handleRefresh: () => void
}

const MemberInvitationForm: FC<MemberInvitationFormParams> = ({ canInvite, label, handleInvite, handleRefresh }) => {

  let [emails, setEmails] = useState("")

  const invite = async () => {

    let response = await handleInvite(emails)
    let result = response.data

    if (result.errors.length === 0) {
      toast.success(`Imported ${result.imported}, existing users: ${result.existed}`)
      setEmails("")
    } else {
      toast.error(`Imported ${result.imported} with existing users ${result.existed} and errors ${result.errors.length}\nFailed to import: ${result.errors}`)
    }

    if (result.imported > 0) {
      handleRefresh()
    }
  }

  return (
    <>
      {canInvite && (
        <Box p={2}>
          <TextField
            sx={{ m: 0 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            onChange={e => setEmails(e.target.value)}
            placeholder={label}
            value={emails}
            size="small"
            margin="normal"
            variant="outlined"
            multiline={true}
            maxRows={3}
            style={{
              width: '300px',
            }}
            helperText={"Comma-separated list or one email per line"}
          />
          <InviteButtonWrapper
            variant="contained"
            onClick={invite}
          >
            Invite
          </InviteButtonWrapper>
        </Box>
      )}
    </>
  )
}

export default MemberInvitationForm