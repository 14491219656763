import React, {FC, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  setAccessToken,
  setAuthEmail,
  setAuthName,
  setAuthPicture,
  setEmailVerification,
  setIsAuthenticated,
  setLogoutFunction
} from "../../redux/slices/authSlice";
import {useHistory} from "react-router-dom";
import {RootState} from "../../redux/store";
import {externalAccessAuthorize} from "../../api/services";
import {toast} from "react-toastify";
import {useParams} from "react-router";

export const ExternalLogin: FC<{

}> = ({

}) => {

  let dispatch = useDispatch()
  let history = useHistory()

  let params = useParams()

  const logout = () => {
    setAccessToken({ token: undefined })
  }

  const isAuthenticated = useSelector((state: RootState) => state.authReducer.isAuthenticated)

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/summary")
    } else {
      externalAccessAuthorize({
        externalUserId: params['userId'],
        externalOrganizationKey: params['externalOrganizationKey'],
      }).then(d => {

        dispatch(setAccessToken({ token: d.data.accessToken }))
        dispatch(setLogoutFunction({ logout: logout }))
        dispatch(setEmailVerification({ verified: true }))
        dispatch(setAuthEmail({ email: d.data.email }))
        dispatch(setAuthName({ name: d.data.name }))
        dispatch(setAuthPicture({ picture: d.data.picture }))
        dispatch(setIsAuthenticated({ isAuthenticated: true }))

        toast.success("Authorized external user, redirecting")

      }).catch(r => {
        toast.error(r.error)
      })
    }
  }, [isAuthenticated, params]);

  return (
    <></>
  )
}