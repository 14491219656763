import { Divider, FormControl, TextField } from '@mui/material'
import '../WidgetSettings.css'
import React, {FC, SetStateAction} from 'react'
import {useAppContext} from "../../../contexts/PermissionContext";
import {OverlaySettings} from "../../../api/types";

const WidgetSettingsOverlay: FC<{
  overlaySettings: OverlaySettings,
  setOverlaySettings: React.Dispatch<SetStateAction<OverlaySettings>>,
}> = ({
  overlaySettings,
  setOverlaySettings,
}) => {
  const overlayUrl = overlaySettings.url

  const { permissions } = useAppContext()

  return (
    permissions?.experience?.updateOverlaySettings && (
      <>
        <Divider variant="middle" />
        <FormControl className="overlay-url-wrapper">
          <p>
            <b>Overlay settings</b>
          </p>
          <TextField
            defaultValue={overlayUrl || ''}
            onChange={e => {
              setOverlaySettings({
                ...overlaySettings,
                url: e.target.value
              })
            }}
            label="Overlay URL to start this video experience"
            fullWidth
          />
        </FormControl>
      </>
    )
  )
}

export default WidgetSettingsOverlay
