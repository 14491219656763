import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers'
import React, {FC, useEffect} from "react";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";


export const DialogSimpleFieldDateTimePicker: FC<{
  from?: Date,
  to?: Date,
  label: string,
  value: number | Date,
  setValue: React.Dispatch<React.SetStateAction<number | Date>>,
  helperText?: string,
  error: boolean,
  errorText?: string,
}> = ({
  from = new Date(new Date().getTime() + 3600000),
  to = new Date().setFullYear(from.getFullYear() + 1),
  label,
  value,
  setValue,
  helperText = "",
  error,
  errorText = "Field is required",

}) => {

  useEffect(() => {
    if (!value) {
      setValue(from)
    }
  }, [value])

  return (<LocalizationProvider dateAdapter={AdapterMoment}>
    <DateTimePicker
      minDate={moment(from)}
      maxDate={moment(to)}
      value={moment(value)}
      onChange={v => setValue(v.toDate()) }
      sx={{ marginTop: "10px"}}
      label={label}
      // slotProps={{
      //   name: label,
      //   margin: "dense",
      //   variant: "standard",
      //   fullWidth: true,
      //   error:error,
      //   helperText: error ? errorText : helperText
      // }}
      // renderInput={params => {
      //   return (
      //     <TextField
      //       name={label}
      //       margin="dense"
      //       variant="standard"
      //       fullWidth
      //       {...params}
      //       error={error}
      //       helperText={error ? errorText : helperText}
      //     />
      //   )
      // }}
    />
  </LocalizationProvider>)
}