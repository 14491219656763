import {mediaStorageCreateItem, mediaStorageUpdateLabel} from "../../api/services";
import {saveImage} from "../../services/api/mediastorage";

export interface GalleryItem {
  id?: string,
  location?: string,
}

export const upload = async (
  files: File[],
  setProgress: (number) => void,
  uploadType: 'DIRECT' | 'GALLERY',
  labelFunction: (File) => string = (file) => file.name,

): Promise<GalleryItem[]> => {

  let totalFiles = files.length
  let currentFile = 0
  let uploaded = []
  for (const index in files) {
    let file = files[index]
    let createResponse = await mediaStorageCreateItem({ type: uploadType })
    let created = createResponse.data
    await mediaStorageUpdateLabel(created.id, { label: labelFunction(file) })
    let formData = new FormData()
    formData.append("file", file)
    let uploadedImage = await saveImage(formData, created.id, (progress) => {
      let { total, loaded } = progress
      let percentage = (currentFile * 100 / totalFiles) + ((loaded * 100 / total) / totalFiles)
      setProgress((Math.round(percentage * 100) / 100).toFixed(2))
    })
    uploaded.push(uploadedImage)
    currentFile = currentFile + 1
  }
  return uploaded
}