import React, {FC} from "react";
import {MediaStorageItemDataFull} from "../../api/types";
import {formatLabelLength, GridComponent} from "../Reusable/GridComponent";
import {Grid, useTheme} from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';

export const GalleryImageComponent: FC<{ item: MediaStorageItemDataFull, selected: boolean }> = ({ item, selected }) => {

  return (
    <GridComponent selected={selected} thumbnail={item.metadata["variant_mid"] ?? item.location} label={<GalleryItemLabel item={item}/>}/>
  )
}
export const GalleryVideoComponent: FC<{ item: MediaStorageItemDataFull, selected: boolean, controls: boolean }> = ({ item, selected, controls }) => {

  const styles = {
    video: {
      width: "100%",
      height: 200,
      objectFit: "cover",
    },
  }
  return (
    <GridComponent selected={selected} thumbnail={
      <video controls={controls} preload={"metadata"} style={styles.video} src={item.optimizedLocation ?? item.location}/>
    } label={<GalleryItemLabel item={item}/>}/>
  )
}

const GalleryItemLabel: FC<{ item: MediaStorageItemDataFull }> = ({ item }) => {

  let size = item.metadata["size"]
  let duration = item.metadata["duration"]
  let format = item.metadata["format"]

  const theme = useTheme()

  const styles = {
    metadata: {
      fontSize: "10px",
      color: theme.colors.secondary.main,
    },

    icon: {
      height: "24px"
    },
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={{ minHeight: "40px" }}>
        {formatLabelLength(item.label)}
      </Grid>

      { size && (
        <Grid item xs sx={styles.metadata}>
          Size: {size}
        </Grid>
      ) }
      { duration && (
        <Grid item xs sx={styles.metadata}>
          Duration: {duration}
        </Grid>
      ) }
      { format && (
        <Grid item xs sx={styles.metadata}>
          Format: {format}
        </Grid>
      ) }

      <Grid item sx={styles.icon}>
        { item.availability === 'MembersAndCreators' ? (
          <ShareIcon fontSize={"small"}/>
        ) : (
          <>
          </>
        ) }
      </Grid>
    </Grid>
  )
}