import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { experimentalStyled } from '@mui/material/styles'
import { TransitionProps } from '@mui/material/transitions'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import React, {
  ChangeEvent,
  FC,
  forwardRef,
  ReactElement,
  Ref, useEffect,
  useState,
} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
  acceptUserInvitationThunk,
  declineUserInvitationThunk,
  getUserInvitationsThunk,
  getAvailableOrganizationsThunk,
} from '../../../redux/slices/organizationSlice'
import { OrganizationPendingInvitation } from '../../../api/types'
import {RootState} from "../../../redux/store";
import {useLocation} from "react-router";
import {toast} from "react-toastify";

const DialogWrapper = experimentalStyled(Dialog)(
  () => `
          .MuiDialog-paper {
            overflow: visible;
          }
    `,
)

const InvitationsOrganizationWrapper = experimentalStyled(Box)(
  () => `
  background-color: #add4e6;
`,
)

const AvatarError = experimentalStyled(Avatar)(
  ({ theme }) => `
          background-color: ${theme.colors.error.lighter};
          color: ${theme.colors.error.main};
          width: ${theme.spacing(12)};
          height: ${theme.spacing(12)};
    
          .MuiSvgIcon-root {
            font-size: ${theme.typography.pxToRem(45)};
          }
    `,
)

const ButtonError = experimentalStyled(Button)(
  ({ theme }) => `
         background: ${theme.colors.error.main};
         color: ${theme.palette.error.contrastText};
    
         &:hover {
            background: ${theme.colors.error.dark};
         }
        `,
)


const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />
})

const applyInvitationsPagination = (
  invitations?: OrganizationPendingInvitation[],
  page?: number,
  limit?: number,
): OrganizationPendingInvitation[] => {
  return invitations?.slice(page * limit, page * limit + limit)
}

const UserInvitations: FC<{}> = ({ }) => {
  //   const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch()
  const location = useLocation()


  const invitations = useSelector(
    (state: RootState) => state.organizationReducer.userInvitations,
  )

  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(10)
  const [cancelInvitationId, setCancelInvitationId] = useState<string>('')
  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage)
  }

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value))
  }

  useEffect(() => {
    if (!invitations) {
      dispatch(getUserInvitationsThunk())
    }
  }, [dispatch, invitations])

  const paginatedInvitations = applyInvitationsPagination(
    invitations,
    page,
    limit,
  )

  const [openConfirmCancel, setOpenConfirmCancel] = useState(false)

  const closeConfirmCancel = () => {
    setOpenConfirmCancel(false)
    setCancelInvitationId('')
  }

  const handleSetCancelInvitationId = id => (event: React.MouseEvent) => {
    setOpenConfirmCancel(true)
    setCancelInvitationId(id)
  }

  const handleCancelInvitation = id => async (event: React.MouseEvent) => {
    await dispatch(declineUserInvitationThunk(id))
    setOpenConfirmCancel(false)
    setCancelInvitationId('')
    await dispatch(getUserInvitationsThunk())
  }

  const handleAcceptInvitation = id => async (event: React.MouseEvent) => {
    await dispatch(acceptUserInvitationThunk(id))
    await dispatch(getAvailableOrganizationsThunk())
    toast.success("Accepted invitation. You can switch to new organization with a dropdown in the top right")
  }

  let accept = new URLSearchParams(location.search).get("accept")

  useEffect(() => {
    if (accept && invitations) {
      let match = invitations.find(i => i.id === accept)
      if (match) {
        console.log("matching invitation")
        handleAcceptInvitation(match.id)(undefined)
      } else {
        console.log("non matching invitation")
      }
      // window.location.href = window.location.href.split("?")[0]
    }
  }, [accept, invitations])

  return (
    <>
      {paginatedInvitations && paginatedInvitations.length > 0 && (
        <>
          <Card>
              <InvitationsOrganizationWrapper
                display="flex"
                alignItems="center"
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent={{ xs: 'center', sm: 'space-between' }}
                p={2}
              >
                <Typography
                  variant="h4"
                  fontWeight="normal"
                  color="text.secondary"
                  align="center"
                >
                  You have been invited to join one or more organizations
                </Typography>
              </InvitationsOrganizationWrapper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow hover>
                      <TableCell>Title</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedInvitations?.map(invitation => {
                      return (
                        <TableRow hover key={invitation.id}>
                          <TableCell>
                            <Typography variant="h5">
                              Invitation to '{invitation.organization.name}'
                              organization
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography noWrap>
                              <Tooltip title="Accept" arrow>
                                <IconButton
                                  onClick={handleAcceptInvitation(invitation.id)}
                                  color="primary"
                                >
                                  <CheckIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Decline" arrow>
                                <IconButton
                                  onClick={handleSetCancelInvitationId(
                                    invitation.id,
                                  )}
                                  color="primary"
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={invitations.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
          </Card>
          <DialogWrapper
            open={openConfirmCancel}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Transition}
            keepMounted
            onClose={closeConfirmCancel}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              p={5}
            >
              <AvatarError>
                <CloseIcon />
              </AvatarError>

              <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
                Are you sure you want to decline this invitation?
              </Typography>

              <Box>
                <Button
                  variant="text"
                  size="large"
                  sx={{ mx: 1 }}
                  onClick={closeConfirmCancel}
                >
                  Cancel
                </Button>
                <ButtonError
                  onClick={handleCancelInvitation(cancelInvitationId)}
                  size="large"
                  sx={{ mx: 1, px: 3 }}
                  variant="contained"
                >
                  Decline
                </ButtonError>
              </Box>
            </Box>
          </DialogWrapper>
        </>
      )}
    </>
  )
}

UserInvitations.propTypes = {
  invitations: PropTypes.array.isRequired,
}

UserInvitations.defaultProps = {
  invitations: [],
}

export default UserInvitations
