import React, {FC, SetStateAction, useEffect, useState} from "react";
import {MediaStorageItemDataFull} from "../../api/types";
import {GridContext} from "../Reusable/InfiniteItemGrid";
import {useAppContext} from "../../contexts/PermissionContext";
import {
  mediaStorageDelete,
  mediaStorageSetAvailableForCreators,
  mediaStorageSetUnavailableForCreators,
  mediaStorageUpdateLabel
} from "../../api/services";
import {toast} from "react-toastify";
import {LayoutWithSidebarWrapper, sidebarStyles} from "../Reusable/LayoutWithSidebars";
import {DialogConfirmation} from "../Reusable/DialogConfirmation";
import {DialogTextField} from "../Reusable/DialogTextField";
import {SidebarItemSummary} from "../Reusable/SidebarItemSummary";
import {Checkbox, Divider, FormControlLabel} from "@mui/material";
import {Button} from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';

export const GallerySidebar: FC<{
  selectedItem: MediaStorageItemDataFull,
  setSelectedItem: React.Dispatch<SetStateAction<MediaStorageItemDataFull>>,
  context: GridContext,
}> = ({
        selectedItem,
        setSelectedItem,
        context,
      }) => {


  const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false)
  const [isEditLabelDialogOpen, setEditLabelDialogOpen] = useState(false)

  const [label, setLabel] = useState("")
  const [isAvailableToCreators, setIsAvailableToCreators] = useState(false)
  const [isEditable, setIsEditable] = useState(false)

  const { permissions } = useAppContext()

  useEffect(() => {
    setLabel(selectedItem?.label ?? "")
    setIsAvailableToCreators(selectedItem?.availability === 'MembersAndCreators')
    setIsEditable(permissions?.overall?.organizationMember || selectedItem?.availability === 'Members')
  }, [selectedItem])

  const deleteItem = async (item) => {
    await mediaStorageDelete(item.id)
    toast.success("Asset deleted successfully")
    context.refreshGrid()
  }

  const editLabel = async () => {
    await mediaStorageUpdateLabel(selectedItem.id, { label: label })
    selectedItem.label = label
    setLabel(selectedItem?.label ?? "")
    toast.success("Asset updated successfully")
  }

  const dismissLabelEdit = () => {
    setEditLabelDialogOpen(false)
    setLabel(selectedItem?.label ?? "")
  }

  const triggerAvailableToCreators = async (available) => {
    if (available) {
      mediaStorageSetAvailableForCreators(selectedItem.id).then((d) => {
        toast.success("Asset made available to creators")
        selectedItem.availability = d.data.availability
        setIsAvailableToCreators(available)
      })
    } else {
      mediaStorageSetUnavailableForCreators(selectedItem.id).then((d) => {
        toast.success("Asset made unavailable to creators")
        selectedItem.availability = d.data.availability
        setIsAvailableToCreators(available)
      })
    }
  }

  return (
    <LayoutWithSidebarWrapper>
      <DialogConfirmation
        title={"Are you sure?"}
        description={"Are you sure you want to delete this asset?"}
        yesTitle={"Delete"}
        noTitle={"Cancel"}
        open={isDeleteConfirmationDialogOpen}
        onPressYes={() => deleteItem(selectedItem)}
        onPressNo={() => setDeleteConfirmationDialogOpen(false) }
        onDismiss={() => setDeleteConfirmationDialogOpen(false) }
      />
      <DialogTextField
        title={"Change asset label"}
        description={""}
        yesTitle={"Save"}
        noTitle={"Cancel"}
        open={isEditLabelDialogOpen}
        textFieldLabel={"Asset label"}
        value={label}
        setValue={setLabel}
        onPressYes={editLabel}
        onPressNo={dismissLabelEdit}
        onDismiss={dismissLabelEdit}
      />

      { selectedItem && (
        <>
          <SidebarItemSummary name={label} created={selectedItem.createdAt} extra1={selectedItem.metadata["size"]}/>
          { isEditable && (
            <>
              <Divider sx={sidebarStyles.divider}/>
              { permissions?.media?.updateMedia && (
                <Button sx={sidebarStyles.mainButton} variant={"outlined"} onClick={() => setEditLabelDialogOpen(true) }>Rename</Button>
              )}
              { permissions?.media?.deleteMedia && (
                <Button sx={sidebarStyles.mainButton} variant={"outlined"} onClick={() => setDeleteConfirmationDialogOpen(true) }>Delete</Button>
              )}
              { permissions?.media?.makeAvailableToCreators && (
                <>
                  <Divider sx={sidebarStyles.divider}/>
                  <FormControlLabel control={<Checkbox color={"primary"} checked={isAvailableToCreators} onChange={(e) => triggerAvailableToCreators(e.target.checked)}/>} label={"Share with creator accounts"}/>
                  <ShareIcon sx={{margin: "-9px" }}/>
                </>
              )}
            </>
          )}
          <Divider sx={sidebarStyles.divider}/>
          <a href={selectedItem.location} download target="_blank"><Button sx={sidebarStyles.mainButton} variant={"outlined"}>Download</Button></a>

        </>
      )}

    </LayoutWithSidebarWrapper>
  )
}
