import React, {FC, useState} from "react";
import {mediaStorageGetList, mediaStorageReplicate} from "../../api/services";
import {Button} from "@mui/material";
import {InfiniteItemGrid} from "../Reusable/InfiniteItemGrid";
import {GalleryImageComponent, GalleryVideoComponent} from "./GalleryItemComponent";
import {DialogSimple} from "../Reusable/DialogSimple";

const GalleryPickerDialog: FC<{
  open: boolean,
  type: "image/*" | "video/*"
  itemReady: (GalleryItem) => void,
  onDismiss: () => void,
}> = ({
        open,
        type,
        itemReady,
        onDismiss,
      }) => {

  const [videos, setVideos] = useState(null)
  const [images, setImages] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)

  const onDismissInternal = () => {
    onDismiss()
    reset()
  }

  const reset = () => {
    setSelectedItem(null)
    setVideos(null)
    setImages(null)
  }

  const onSelect = async () => {

    let replica = await mediaStorageReplicate(selectedItem.id)
    itemReady(replica.data)
    onDismissInternal()
  }

  let perPage = 24
  let itemProps = { xs: 6, sm: 4, md: 3, lg: 2, xl: 2 }

  return (
    <DialogSimple
        open={open}
        onDismiss={onDismissInternal}
        title={"Select item from gallery"}
        maxWidth={"lg"}
        content={
          <>
            { open && (
              <>
                { type === 'image/*' ? (
                  <InfiniteItemGrid
                    perPage={perPage}
                    loadItems={(page, perPage) => mediaStorageGetList({ type: 'IMAGE', page: page, perPage: perPage }).then(items => items.data)}
                    scrollRef={document.getElementById("dialog-simple-dialog-content-id")}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    items={images}
                    setItems={setImages}
                    itemProps={itemProps}
                    component={(item, selected) => <GalleryImageComponent selected={selected} item={item} />}
                  />
                ) : (
                  <InfiniteItemGrid
                    perPage={perPage}
                    loadItems={(page, perPage) => mediaStorageGetList({ type: 'VIDEO', page: page, perPage: perPage }).then(items => items.data)}
                    scrollRef={document.getElementById("dialog-simple-dialog-content-id")}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    items={videos}
                    setItems={setVideos}
                    itemProps={itemProps}
                    component={(item, selected) => <GalleryVideoComponent selected={selected} controls={false} item={item} />}
                  />
                )}
              </>
            )}

          </>
        }
        actions={
          <>
            <Button disabled={ selectedItem === null } onClick={onSelect}>Select</Button>
            <Button onClick={onDismissInternal}>Cancel</Button>
          </>
        }
    />
  )
}

export default GalleryPickerDialog