import React, {FC, SetStateAction, useState} from "react";
import {BrandData} from "../../api/types";
import {GridContext, InfiniteItemGrid} from "../Reusable/InfiniteItemGrid";
import {useAppContext} from "../../contexts/PermissionContext";
import {ContentLayout} from "../Reusable/ContentLayout";
import {Button} from "@mui/material";
import {brandsGetBrands} from "../../api/services";
import {BrandGridComponent} from "./BrandGridComponent";
import AddIcon from '@mui/icons-material/Add';


export const BrandsMain: FC<{
  selectedBrand: BrandData,
  setSelectedBrand: React.Dispatch<SetStateAction<BrandData>>,
  context: GridContext,
}> = ({
        selectedBrand,
        setSelectedBrand,
        context,
      }) => {

  const { permissions } = useAppContext()

  const [brands, setBrands] = useState(null)

  context.refreshGrid = () => {
    setBrands(null)
  }

  let itemProps = { xs: 6, sm: 6, md: 4, lg: 3, xl: 2 }
  let perPage = 24
  return (
    <ContentLayout controls={
      <>
        {permissions?.brand?.createBrand && (
          <Button onClick={context.create} startIcon={<AddIcon/>}>
            Create
          </Button>
        )}
      </>
    }>
      <InfiniteItemGrid
        itemProps={itemProps}
        scrollRef={document.getElementById("main-content-area")}
        setItems={setBrands}
        perPage={perPage}
        loadItems={(page, perPage) => brandsGetBrands({ page: page, perPage: perPage }).then(d => d.data) }
        items={brands}
        setSelectedItem={setSelectedBrand}
        selectedItem={selectedBrand}
        component={(item, selected) => <BrandGridComponent selected={selected} item={item} />}
      />
    </ContentLayout>
  )
}