import React, {FC, useState} from "react";
import {Button, MenuItem} from "@mui/material";
import {GalleryItem, upload} from "./helpers";
import {toast} from "react-toastify";
import {Divider, Popover} from "@mui/material";
import GalleryPickerDialog from "./GalleryPickerDialog";
import {ProgressIndicator} from "../Reusable/ProgressIndicator";
import {UnsplashDialog} from "./UnsplashDialog";

const GalleryPicker: FC<{
  itemReady: (item: GalleryItem) => void,
  onDismiss: () => void,
  anchor: HTMLElement | null,
  type: "image/*" | "video/*"
}> = ({ itemReady, onDismiss, anchor, type }) => {

  const open = Boolean(anchor)
  const [isUploading, setIsUploading] = useState(false)
  const [galleryPickerDialogOpen, setGalleryPickerDialogOpen] = useState(false)
  const [progress, setProgress] = useState(0)

  const [unsplashDialogOpen, setUnsplashDialogOpen] = useState(false)

  const selectFromGallery = (e) => {
    setGalleryPickerDialogOpen(true)
  }
  const selectFromUnsplash = (e) => {
    setUnsplashDialogOpen(true)
  }

  const reset = () => {
    setIsUploading(false)
    setProgress(0)
  }

  const onClose = () => {
    if (!isUploading) {
      onDismiss()
    }
  }

  const styles = {
    directUploadButton: {
      fontWeight: "normal",
      color: "#223354",
      margin: 0,
      padding: "10px",
      width: "100%",
      justifyContent: "left",
    },
  }

  return (
    <>
      <Popover
        sx={{ margin: 0, padding: 0 }}
        open={open}
        onClose={onClose}
        anchorEl={anchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <input id="gallery-picker-upload-file" name="gallery-picker-upload-file" type="file" accept={type} style={{ display: "none" }} onChange={async (e) => {
          let files = Array.from(e.target.files || [])
          if (files.length > 0) {
            setProgress(0)
            setIsUploading(true)
            let uploaded = await upload(files, setProgress, 'DIRECT')
            toast.success("Asset uploaded successfully")
            itemReady(uploaded[0])
            onDismiss()
            reset()
          }
          // setFiles(files)
          // setFileNames(files.map(f => f.name))
        }}/>
        <Button style={styles.directUploadButton} variant={"text"} component={"label"} htmlFor={"gallery-picker-upload-file"}>Direct upload</Button>
        <Divider/>
        <MenuItem sx={{ margin: 0, padding: "10px" }} dense={true} onClick={selectFromGallery}>Select from gallery</MenuItem>
        { type === 'image/*' && (
          <MenuItem sx={{ margin: 0, padding: "10px" }} dense={true} onClick={selectFromUnsplash}>Select from Unsplash</MenuItem>
        )}
        <ProgressIndicator isUploading={isUploading} progress={progress}/>
      </Popover>
      <GalleryPickerDialog
        open={galleryPickerDialogOpen}
        itemReady={itemReady}
        type={type}
        onDismiss={() => {
          setGalleryPickerDialogOpen(false)
          onDismiss()
        }}
      />
      <UnsplashDialog open={unsplashDialogOpen} onSelect={itemReady} type={"DIRECT"} onDismiss={() => {
        setUnsplashDialogOpen(false)
        onDismiss()
      }}/>
    </>
  )
}

export default GalleryPicker