import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  organizationAssignMemberAdmin,
  organizationCancelPendingInvitation,
  organizationGetCreators,
  organizationGetMembers,
  organizationGetPendingInvitations,
  organizationRemoveMember,
  organizationUnassignMemberAdmin,
  organizationUpdateProfile,
  uiGetPermissions,
  userAcceptInvitation,
  userDeclineInvitation,
  userGetActiveOrganization,
  userGetAvailableOrganizations,
  userGetInvitations,
} from '../../api/services'
import {
  OrganizationData,
  OrganizationMemberData,
  OrganizationPendingInvitation,
  OrganizationSwitchable,
  UIPermissions,
} from '../../api/types'
import { IS_PRODUCTION } from '../../constants'

export interface IActiveOrganizationState {
  activeOrganization: OrganizationData
  organizationProfile: OrganizationData
  members: OrganizationMemberData[]
  creators: OrganizationMemberData[]
  pendingInvitations: OrganizationPendingInvitation[]
  availableOrganizations: OrganizationSwitchable[]
  paymentPlanSummary: any
  userInvitations: OrganizationPendingInvitation[]
  permissions: null | UIPermissions
}

export const getUserInvitationsThunk = createAsyncThunk(
  'organization/getUserInvitations',
  async () => {
    let userInvitations = await userGetInvitations()
    return { userInvitations }
  },
)

export const getPendingInvitationsThunk = createAsyncThunk(
  'organization/getPendingInvitations',
  async () => {
    let pendingInvitations = await organizationGetPendingInvitations()
    return { pendingInvitations }
  },
)

export const cancelPendingInvitationThunk = createAsyncThunk(
  'organization/cancelPendingInvitation',
  async (id: string) => {
    await organizationCancelPendingInvitation(id)
    let pendingInvitations = await organizationGetPendingInvitations()
    return { pendingInvitations, success: true }
  },
)

export const acceptUserInvitationThunk = createAsyncThunk(
  'organization/acceptUserInvitation',
  async (id: string) => {
    await userAcceptInvitation(id)
    let userInvitations = await userGetInvitations()
    return { userInvitations, success: true }
  },
)

export const declineUserInvitationThunk = createAsyncThunk(
  'organization/declineUserInvitation',
  async (id: string) => {
    await userDeclineInvitation(id)
    let userInvitations = await userGetInvitations()
    return { userInvitations, success: true }
  },
)

export const getOrganizationMembersThunk = createAsyncThunk(
  'organization/getOrganizationMembers',
  async () => {
    let members = await organizationGetMembers()
    return { members }
  },
)

export const getCreatorsThunk = createAsyncThunk(
  'organization/getCreators',
  async () => {
    let creators = await organizationGetCreators()
    return { creators }
  },
)

export const getAvailableOrganizationsThunk = createAsyncThunk(
  'organization/getAvailableOrganizations',
  async () => {
    let availableOrganizations = await userGetAvailableOrganizations()
    return { availableOrganizations }
  },
)

export const getActiveOrganizationThunk = createAsyncThunk(
  'organization/getActiveOrganization',
  async () => {
    let activeOrganization = await userGetActiveOrganization()
    return { activeOrganization }
  },
)

export const setAdminOrganizationThunk = createAsyncThunk(
  'organization/setAdminOrganization',
  async (id: string) => {
    await organizationAssignMemberAdmin(id)
    let members = await organizationGetMembers()
    return { members, success: true }
  },
)

export const unsetAdminOrganizationThunk = createAsyncThunk(
  'organization/unsetAdminOrganization',
  async (id: string) => {
    await organizationUnassignMemberAdmin(id)
    let members = await organizationGetMembers()
    return { members, success: true }
  },
)

export const removeMemberOrganizationThunk = createAsyncThunk(
  'organization/removeOrganizationMember',
  async (id: string) => {
    await organizationRemoveMember(id)
    let members = await organizationGetMembers()
    return { members, success: true }
  },
)

export const removeCreatorThunk = createAsyncThunk(
  'organization/removeCreator',
  async (id: string) => {
    await organizationRemoveMember(id)
    // reused for creator
    let creators = await organizationGetCreators()
    return { creators, success: true }
  },
)

export const getPaymentPlanSummaryThunk = createAsyncThunk(
  'organization/getPaymentPlanSummaryThunk',
  async () => {
    // let paymentPlanSummary = await checkPaymentPlan();
    return {}
  },
)

export const editOrganizationProfileDataThunk = createAsyncThunk(
  'organization/editOrganizationProfileData',
  async (profile: any) => {
    let editData: OrganizationData = {
      id: null,
      name: profile.name,
      owner: null,
    }
    await organizationUpdateProfile(editData)
    let activeOrganization = await userGetActiveOrganization()
    return { activeOrganization, success: true }
  },
)

export const getPermissionsThunk = createAsyncThunk(
  'organization/getPermissionsThunk',
  async () => {
    let permissionsResponse = await uiGetPermissions()
    let permissions = permissionsResponse.data
    if (!IS_PRODUCTION) {
      // allows overriding returned permissions.
      // example:
      // someUrl?permissions.menu.brands=false&permissions.menu.experiences=true
      if (window.location.search.length > 0) {
        window.location.search
          .substring(1)
          .split('&')
          .forEach(function (keyValue) {
            let split = keyValue.split('=')
            if (split.length == 2) {
              let key = split[0]
              let value = split[1] === 'true'
              if (key.startsWith('permissions')) {
                let prop = null
                let tree = key.substring(12).split('.')

                tree.forEach(function (objKey, index) {
                  if (index != tree.length - 1) {
                    prop = permissions[objKey]
                    console.log(prop)
                  }
                })
                if (prop != null) {
                  prop[tree[tree.length - 1]] = value
                } else {
                  console.log('no property to override for path found: ' + key)
                }
              }
            } else {
              console.log('wrong input: ' + keyValue)
            }
          })
      }
    }
    return { permissions }
  },
)

let initialState: IActiveOrganizationState = {
  activeOrganization: null,
  paymentPlanSummary: {},
  organizationProfile: null,
  members: null,
  creators: null,
  pendingInvitations: null,
  availableOrganizations: null,
  userInvitations: null,
  permissions: null,
}

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUserInvitationsThunk.fulfilled, (state, action) => {
      state.userInvitations = action.payload.userInvitations.data
    })
    builder.addCase(getPendingInvitationsThunk.fulfilled, (state, action) => {
      state.pendingInvitations = action.payload.pendingInvitations.data
    })
    builder.addCase(cancelPendingInvitationThunk.fulfilled, (state, action) => {
      state.pendingInvitations = action.payload.pendingInvitations.data
    })
    builder.addCase(acceptUserInvitationThunk.fulfilled, (state, action) => {
      state.userInvitations = action.payload.userInvitations.data
    })
    builder.addCase(declineUserInvitationThunk.fulfilled, (state, action) => {
      state.userInvitations = action.payload.userInvitations.data
    })
    builder.addCase(getOrganizationMembersThunk.fulfilled, (state, action) => {
      state.members = action.payload.members.data
    })
    builder.addCase(getCreatorsThunk.fulfilled, (state, action) => {
      state.creators = action.payload.creators.data
    })
    builder.addCase(
      getAvailableOrganizationsThunk.fulfilled,
      (state, action) => {
        state.availableOrganizations =
          action.payload.availableOrganizations.data
      },
    )
    builder.addCase(getActiveOrganizationThunk.fulfilled, (state, action) => {
      state.activeOrganization = action.payload.activeOrganization.data
    })
    builder.addCase(setAdminOrganizationThunk.fulfilled, (state, action) => {
      state.members = action.payload.members.data
    })
    builder.addCase(unsetAdminOrganizationThunk.fulfilled, (state, action) => {
      state.members = action.payload.members.data
    })
    builder.addCase(
      removeMemberOrganizationThunk.fulfilled,
      (state, action) => {
        state.members = action.payload.members.data
      },
    )
    builder.addCase(removeCreatorThunk.fulfilled, (state, action) => {
      state.creators = action.payload.creators.data
    })
    builder.addCase(getPaymentPlanSummaryThunk.fulfilled, (state, action) => {
      state.paymentPlanSummary = action.payload
    })
    builder.addCase(
      editOrganizationProfileDataThunk.fulfilled,
      (state, action) => {
        state.activeOrganization = action.payload.activeOrganization.data
      },
    )
    builder.addCase(getPermissionsThunk.fulfilled, (state, action) => {
      state.permissions = action.payload.permissions
    })
  },
})

export default organizationSlice.reducer
