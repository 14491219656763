import React from 'react'

import ContentWrapper from '../../ContentWrapper/ContentWrapper'
import MemberProfile from './MemberProfile'
import UserInvitations from "../OrganizationData/UserInvitations";
import {useAppContext} from "../../../contexts/PermissionContext";
import {CreatorProfile} from "./CreatorProfile";
import {Card, CardContent} from "@mui/material";

const UserSettings: React.FC = () => {

  const { permissions } = useAppContext()

  return (
    <ContentWrapper title="User Details - Management">
      <UserInvitations />
      { permissions && (
        <Card>
          <CardContent>
            { permissions.creator.viewProfile && permissions.creator.updateProfile ? (
              <CreatorProfile />
            ) : (
              <MemberProfile />
            )}
          </CardContent>
        </Card>
      )}
    </ContentWrapper>
  )
}

export default UserSettings
