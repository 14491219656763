import {Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import { TopUserChoice } from '../../../api/types'
import './SummaryTable.css'
import React, {FC} from "react";

const UserChoiceTable: FC<{
  userChoices: TopUserChoice[],
  rowsToShow: number,
  defaultRowsToShow: number,
  expand?: () => void,
  collapse?: () => void,
  type?: 'Regular' | 'Compact',
}> = ({
  userChoices,
  rowsToShow,
  defaultRowsToShow,
  expand,
  collapse,
  type = 'Regular',
}) => {
  return (
    <>
      <TableContainer component={Card}>
        <Table>
          <TableHead>
            <TableRow hover>
              <TableCell>Top user choices</TableCell>
              <TableCell>Clicks</TableCell>
              {type == 'Regular' && (<TableCell>Page</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {userChoices?.slice(0, rowsToShow).map(choice => (
              <TableRow hover key={choice.cardId} className={type}>
                { choice.product ? (
                  <TableCell style={type == 'Compact' ? {justifyContent: 'left'} : {}}>
                    <div style={{float:"left"}}>
                      <img
                        className="table-avatar-square"
                        src={choice.product.images[0]}
                      />
                    </div>
                    <div>
                      <div>{choice.product.title}</div>
                      <div style={{fontSize: "14px"}}>
                          <span>
                            {choice.product.price}
                          </span>
                        {choice.product.oldPrice && (
                          <span style={{textDecoration: "line-through"}}>{choice.product.oldPrice}</span>
                        )}
                      </div>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell style={type == 'Compact' ? { justifyContent: 'left' } : {}}>
                    <div style={{float:"left"}}>
                      <img
                        className="table-avatar-square"
                        src={choice.cardImage}
                      />
                    </div>
                    <div style={{marginTop:"10px"}}>
                      {choice.tags?.length > 0 ? (
                        <div>{choice.tags.join(", ")}</div>
                      ) : choice?.directLinkLabel ? (
                        <div>{choice.directLinkLabel}</div>
                      ) : (
                        <div>{choice.campaignName}</div>
                      )}
                    </div>
                  </TableCell>
                )}
                <TableCell>{choice?.leads?.displayValue} clicks</TableCell>
                {type == 'Regular' && (
                  <TableCell>
                    <div style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "180px",
                      whiteSpace: "nowrap",
                      display: "inline-block"
                    }}>
                      <a target="_blank" href={choice?.leadUrl}>
                        {choice?.leadUrl}
                      </a>
                    </div>
                  </TableCell>
                )}
              </TableRow>

            ))}
            {(expand || collapse) && type == 'Regular' && (
              <>

                {defaultRowsToShow === rowsToShow && userChoices?.length > rowsToShow && expand && (
                  <TableRow hover className={"table-show-more"}>
                    <TableCell colSpan={5} align={"center"} onClick={expand} sx={{color:"rgba(34, 51, 84, 0.7) !important"}}>
                      Show more
                    </TableCell>
                  </TableRow>
                )}
                {defaultRowsToShow !== rowsToShow && collapse && (
                  <TableRow hover className={"table-show-more"}>
                    <TableCell colSpan={5} align={"center"} onClick={collapse} sx={{color:"rgba(34, 51, 84, 0.7) !important"}}>
                      Show less
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default UserChoiceTable
