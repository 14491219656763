import { HelmetProvider } from 'react-helmet-async'
import store from './redux/store'
import { Provider } from 'react-redux'
import App from './App'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'nprogress/nprogress.css'
import { ToastContainer } from 'react-toastify'
import ThemeProvider from './theme/ThemeProvider'
import { SidebarProvider } from './contexts/SidebarContext'
import { history } from './history'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import React from 'react'
import { createRoot } from 'react-dom/client';
import {Route, Router} from 'react-router-dom'
import {Auth0LoginProvider} from "./components/Auth/Auth0LoginProvider";
import { AppCustomContextProvider } from './contexts/PermissionContext'
import {Authorized} from "./components/Auth/Authorized";
import { ExternalLogin } from './components/Auth/ExternalLogin'

Sentry.init({
  dsn: 'https://59e36c88d4904c7fbddf8fdfaf560a58@o590637.ingest.sentry.io/5994977',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

const container = document.getElementById('contester-creator-root');
const root = createRoot(container)


// @ts-ignore
root.render(

  <Router history={history}>
    <ThemeProvider theme={"PureLightTheme"}>
        <HelmetProvider>
          <ToastContainer position="top-center" />
          <Provider store={store}>
            <SidebarProvider>
              <AppCustomContextProvider>
                <Route path={"/external/login/:externalOrganizationKey/:userId"} render={() => (<ExternalLogin/>)}/>
                <Authorized>
                  <Route path={"/"} render={() => (
                    <Auth0LoginProvider/>
                  )}>
                  </Route>
                  <App/>
                </Authorized>
              </AppCustomContextProvider>
            </SidebarProvider>
          </Provider>
        </HelmetProvider>
    </ThemeProvider>
  </Router>
)
// serviceWorkerRegistration.register();
