import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TreeItem } from 'react-sortable-tree'
import {
  HEADING_TYPE,
} from 'src/utils/sequenceSliceHelper'
import {
  changeHeadersOfChildren,
  changeIds,
  getObject,
  getParent,
  updateCurrentSelectedCard,
} from 'src/utils/sequenceSliceUtils'
import { v4 as uuidv4 } from 'uuid'
import {GalleryItem} from "../../components/Gallery/helpers";
import {ALLOWED_EXPERIENCE_CARDS} from "../../constants";
import {ExperienceUpdateRequestCardProductOverride, Product} from "../../api/types";

interface UndoRedo {
  undoRedo: {
    history: (SequenceSlice & UndoRedo)[]
    present: SequenceSlice & UndoRedo
    future: (SequenceSlice & UndoRedo)[]
  }
}

export enum HeadingType {
  noHeading = 'No heading',
  title = 'Title heading',
  video = 'Video heading',
  image = 'Image heading',
  asPrevious = 'As previous',
}

export enum CardTreeType {
  child = 'child',
  parent = 'parent',
}

export enum CardType {
  directLink = 'DIRECT_LINK',
  intermediate = 'INTERMEDIATE',
  videoOnly = 'VIDEO_ONLY',
  root = 'ROOT',
}

export interface NoHeading {
  type: HeadingType
  titleText?: string
  isAsPrevious?: boolean
}

export interface Image extends NoHeading {
  imageId: string
  imageLocation: string
}

export interface Video extends NoHeading {
  videoId: string
  videoLocation: string
}

export interface Title extends NoHeading {
  titleText: string
  styles?: object
}

export interface DirectLink {
  cardType: CardType.directLink
  directLinkUrl: string
  directLinkLabel: string
  styles?: object
}

interface Intermediate {
  cardType: CardType.intermediate
  directLinkUrl?: string
  directLinkLabel?: string
  styles?: object
}

interface VideoOnly {
  cardType: CardType.videoOnly
  directLinkLabel: string
  styles?: object
}

interface Root {
  cardType: CardType.root
  directLinkUrl?: string
  directLinkLabel?: string
  styles?: object
}

export type HeadingProps = NoHeading | Image | Title | Video


export type CardProps = { styles?: object } & (
  | DirectLink
  | Intermediate
  | VideoOnly
  | Root
)

export interface ITreeItem extends TreeItem {
  cardId?: string
  name?: string
  heading?: HeadingProps
  headingType?: HEADING_TYPE
  cardType?: CardProps
  file?: File
  fileName?: string
  cardTreeType?: CardTreeType
  media?: GalleryItem,
  tags?: string[]
  liveStreamId: string | null
  renderDirectLinkProduct: boolean
  productImageHint: string | null
  visibility: 'Public' | 'Private'
  productOverride: ExperienceUpdateRequestCardProductOverride | null
  transientProduct: Product | null
}

export interface SequenceSlice {
  sequenceId?: string
  autosave: boolean
  contentName: string
  brandId: string
  status: string
  liveStreamId: string | null
  liveStreamEditable: boolean
  sequenceBox: {
    items: ITreeItem[]
  }
  currentProps: {
    currentCard: ITreeItem
  }
  headings: {
    [cardId: string]: HeadingProps
  }
  cardType: {
    [cardId: string]: CardProps
  }
  originHint: string | null,
}

const initialCard: ITreeItem = {
  cardId: uuidv4(),
  cardTreeType: CardTreeType.parent,
  cardType: {
    cardType: CardType.root,
  },
  children: [
    {
      cardId: uuidv4(),
      cardTreeType: CardTreeType.child,
      cardType: {
        cardType: CardType.directLink,
      },
      children: [],
      expanded: true,
      heading: {
        type: HeadingType.noHeading,
        isAsPrevious: false,
      },
      headingType: 'NONE',
      tags: [],
      liveStreamId: null,
      renderDirectLinkProduct: true,
      visibility: 'Public',
    }
  ],
  expanded: true,
  heading: {
    type: HeadingType.video,
    isAsPrevious: false,
  },
  headingType: 'NONE',
  name: 'ROOT',
  tags: [],
  liveStreamId: null,
  renderDirectLinkProduct: true,
  visibility: 'Public',
}

export const initialState: SequenceSlice & UndoRedo = {
  sequenceId: '',
  autosave: true,
  contentName: '',
  brandId: '',
  status: 'Draft',
  sequenceBox: {
    items: [initialCard],
  },
  currentProps: {
    currentCard: initialCard,
  },
  headings: {},
  cardType: {},
  undoRedo: {
    history: [],
    present: null,
    future: [],
  },
  liveStreamId: null,
  liveStreamEditable: true,
  originHint: null,
}

const handleUndoRedoChange = (state: SequenceSlice & UndoRedo) => {
  let temp = state.undoRedo.present
  state.undoRedo.present = state
  if (state.undoRedo.present != null) {
    state.undoRedo.history.push(temp)
  }

  if (state.undoRedo.history.length > 100) {
    state.undoRedo.history.shift()
  }
}

const sequenceSlice = createSlice({
  name: 'sequence',
  initialState,
  reducers: {
    setSequenceId(state, action: PayloadAction<string>) {
      state.sequenceId = action.payload
    },
    setSequenceStatus(state, action: PayloadAction<string>) {
      state.status = action.payload
    },
    resetSequenceData(state, action: PayloadAction) {
      return (state = initialState)
    },
    setSequenceSliceData(state, action: PayloadAction<SequenceSlice>) {
      return (state = {
        ...action.payload,
        undoRedo: state.undoRedo,
      })
    },
    setContentName(state, action: PayloadAction<string>) {
      state.contentName = action.payload
    },
    setBrandId(state, action: PayloadAction<string>) {
      state.brandId = action.payload
    },
    setTreeItems(state, action: PayloadAction<ITreeItem[]>) {
      state.sequenceBox.items = action.payload
      let found = getObject(
        action.payload,
        state.currentProps.currentCard.cardId,
      )

      let traverseTree = (item: ITreeItem, func: (ITreeItem) => void) => {
        func(item)
        if (Array.isArray(item.children)) {
          item.children.forEach(c => traverseTree(c, func))
        }
      }

      let validateTreeItem = (item: ITreeItem) => {
        let hasChildren = Array.isArray(item.children) && item.children.length > 0
        if (hasChildren && item?.cardType?.cardType !== CardType.intermediate && item?.cardType?.cardType !== CardType.root) {
          item.cardTreeType = CardTreeType.parent
          item.cardType = {
            ...item.cardType,
            cardType: CardType.intermediate,
          }
        } else if (!hasChildren && item?.cardType?.cardType === CardType.intermediate) {
          item.cardTreeType = CardTreeType.child
          item.cardType = {
            ...item.cardType,
            cardType: CardType.directLink,
            directLinkLabel: item?.cardType?.directLinkLabel,
            directLinkUrl: item?.cardType?.directLinkUrl,
          }
        }
        if (item.cardId === found?.cardId) {
          state.currentProps.currentCard = item
        }
      }

      action.payload.forEach(root => traverseTree(root, validateTreeItem))


    },
    addNewTreeItem(state, action: PayloadAction<ITreeItem>) {
      state.sequenceBox.items.push(action.payload)
    },
    changeCardLabelStyles(state, action: PayloadAction<object>) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      if (found) {
        if ([undefined, null].includes(found?.cardType?.styles))
          found.cardType.styles = {}
        found.cardType.styles = Object.assign(
          found.cardType.styles,
          action.payload,
        )
        // updateCurrentSelectedCard(state);
        state.currentProps.currentCard = found
      }
    },
    changeCardHeader(
      state,
      action: PayloadAction<{ cardId: string; options: HeadingProps }>,
    ) {
      let found = getObject(state.sequenceBox.items, action.payload.cardId)
      if (found) {
        if (action.payload.options.type === HeadingType.asPrevious) {
          let parentCard = getParent(
            state.sequenceBox.items,
            action.payload.cardId,
            null,
          )
          if (parentCard) {
            if (found.heading) {
              found.heading = Object.assign(found.heading, parentCard.heading)
            } else {
              found.heading = parentCard.heading
            }
            found.heading.isAsPrevious = true
            found.headingType = 'INHERIT_PARENT'
            // updateCurrentSelectedCard(state);
            state.currentProps.currentCard = found
          }
        } else {
          if (found.heading) {
            found.heading = Object.assign(found.heading, action.payload.options)
          } else {
            found.heading = action.payload.options
          }
          if ('titleText' in found.heading) {
            found.headingType = 'TEXT'
          }
          if ('imageLocation' in found.heading) {
            found.headingType = 'IMAGE'
          } else if ('videoLocation' in found.heading) {
            found.headingType = 'VIDEO'
          }
          found.heading.isAsPrevious = false
          // updateCurrentSelectedCard(state);
          state.currentProps.currentCard = found
        }
        if (found.cardTreeType === CardTreeType.parent) {
          changeHeadersOfChildren(found, found.heading)
        }
      }
    },
    changeHeaderStyles(state, action: PayloadAction<object>) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      if (found) {
        if (found.heading.type === HeadingType.title) {
          let temp = found.heading as Title
          if ([undefined, null].includes(temp.styles)) temp.styles = {}
          temp.styles = Object.assign(temp.styles, action.payload)
          // updateCurrentSelectedCard(state);
          state.currentProps.currentCard = found
        }
      }
    },
    changeImageProps(
      state,
      action: PayloadAction<{ cardId: string; props: GalleryItem }>,
    ) {
      let found = getObject(state.sequenceBox.items, action.payload.cardId)
      if (found) {
        found.media = action.payload.props
        // updateCurrentSelectedCard(state);
        state.currentProps.currentCard = found
      }
      state.currentProps.currentCard = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
    },
    changeCardType(
      state,
      action: PayloadAction<{ cardId: string; options: CardProps }>,
    ) {
      let found = getObject(state.sequenceBox.items, action.payload.cardId)
      if (found && found.cardType?.cardType != CardType.root) {
        // cannot change type of root card
        found.cardType = action.payload.options
        // updateCurrentSelectedCard(state);
        state.currentProps.currentCard = found
      }
    },
    setCurrentCard(state, action: PayloadAction<{ card: ITreeItem }>) {
      if (action.payload) {
        state.currentProps.currentCard = action.payload.card
      } else {
        state.currentProps.currentCard = state.sequenceBox.items[0]
      }
    },
    addFileToCard(state, action: PayloadAction<{ file: File }>) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      found.file = action.payload.file
      found.fileName = action.payload.file.name
      state.currentProps.currentCard = found
    },
    addLiveStreamToCard(state, action: PayloadAction<any>) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      found.liveStreamId = action.payload.id
      state.currentProps.currentCard = found
    },
    setCardRenderDirectLinkProduct(state, action: PayloadAction<boolean>) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      found.renderDirectLinkProduct = action.payload
      state.currentProps.currentCard = found
    },
    setCardProductImageHint(state, action: PayloadAction<string>) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      found.productImageHint = action.payload
      state.currentProps.currentCard = found
    },
    setCardVisibility(state, action: PayloadAction<'Public' | 'Private'>) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      found.visibility = action.payload
      state.currentProps.currentCard = found
    },
    setCardProductOverride(state, action: PayloadAction<ExperienceUpdateRequestCardProductOverride>) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      found.productOverride = action.payload
      state.currentProps.currentCard = found
    },
    removeLiveStreamFromCard(state) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      found.liveStreamId = null
      state.currentProps.currentCard = found
    },
    resetCardProductOverride(state) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      found.productOverride = null
      state.currentProps.currentCard = found
    },
    changeCardTreeType(
      state,
      action: PayloadAction<{
        cardId: string
        treeType: CardTreeType
        type: CardType
      }>,
    ) {
      let found = getObject(state.sequenceBox.items, action.payload.cardId)
      if (found) {
        found.cardTreeType = action.payload.treeType
        if (found.cardType.cardType !== CardType.root) {
          // cannot change type of root card
          found.cardType.cardType = action.payload.type
        }
        // updateCurrentSelectedCard(state);
        state.currentProps.currentCard = found
      }
    },
    duplicateTreeItem(
      state,
      action: PayloadAction<{ parentId: string; itemId: string }>,
    ) {
      let found = getObject(state.sequenceBox.items, action.payload.parentId)
      if (Array.isArray(found.children)) {
        let copyItem: ITreeItem = JSON.parse(
          JSON.stringify(
            found.children.find(
              (el: ITreeItem) => el.cardId === action.payload.itemId,
            ),
          ),
        )
        copyItem.cardId = uuidv4()
        if (Array.isArray(copyItem.children)) changeIds(copyItem.children)
        let tempArr = [...found.children, copyItem]
        if (tempArr.length > ALLOWED_EXPERIENCE_CARDS) tempArr = tempArr.slice(0, ALLOWED_EXPERIENCE_CARDS)
        if (copyItem) found.children = tempArr
      }
    },
    addTagsToCard(state, action: PayloadAction<string[]>) {
      let found = getObject(
        state.sequenceBox.items,
        state.currentProps.currentCard.cardId,
      )
      found.tags = action.payload
      // updateCurrentSelectedCard(state);
      state.currentProps.currentCard = found
    },
    saveActionToHistory(state) {
      handleUndoRedoChange(state)
    },
    undoChange(state) {
      const previousChange = state.undoRedo.history.pop()
      if (previousChange) {
        state.undoRedo.future.push(state.undoRedo.present)
        state.undoRedo.present = previousChange
        state.sequenceBox = previousChange.sequenceBox
        state.currentProps.currentCard = previousChange.currentProps.currentCard
        updateCurrentSelectedCard(state)
      }
    },
    setOriginHint(state, action: PayloadAction<string>) {
      state.originHint = action.payload
    },
    setAutosave(state, action: PayloadAction<boolean>) {
      state.autosave = action.payload
    },
    redoChange(state) {
      const futureChange = state.undoRedo.future.pop()
      if (futureChange) {
        state.undoRedo.history.push(state.undoRedo.present)
        state.undoRedo.present = futureChange
        state.sequenceBox = futureChange.sequenceBox
        state.currentProps.currentCard = futureChange.currentProps.currentCard
        updateCurrentSelectedCard(state)
      }
    },
  },
})

export const {
  setSequenceId,
  setAutosave,
  setOriginHint,
  resetSequenceData,
  setSequenceSliceData,
  setSequenceStatus,
  setContentName,
  setBrandId,
  setTreeItems,
  addNewTreeItem,
  setCurrentCard,
  changeCardHeader,
  changeCardType,
  addFileToCard,
  changeCardTreeType,
  changeImageProps,
  changeCardLabelStyles,
  duplicateTreeItem,
  changeHeaderStyles,
  addTagsToCard,
  undoChange,
  redoChange,
  addLiveStreamToCard,
  setCardRenderDirectLinkProduct,
  setCardProductImageHint,
  setCardVisibility,
  removeLiveStreamFromCard,
  resetCardProductOverride,
  setCardProductOverride,
} = sequenceSlice.actions
export default sequenceSlice.reducer
