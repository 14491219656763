import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from '../../SequenceOptions.module.scss'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'
import {
  changeCardLabelStyles,
  ITreeItem,
} from 'src/redux/slices/sequenceSlice'
import ColorPicker from './ColorPicker'
import { FormControl, MenuItem, Select } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  rootSelect: {
    padding: '5px 0px 9px !important',
  },
}))

interface Props {
  currentCard: ITreeItem
  disabledStatus: boolean
}

const CardEditorTooltips: React.FC<Props> = ({
  currentCard,
  disabledStatus,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [cardAligment, setCardAligment] = useState('center')
  const [boldStatus, setBoldStatus] = useState(false)
  const [italicStatus, setItalicStatus] = useState(false)

  useEffect(() => {
    let styles = currentCard?.cardType?.['styles']
    setItalicStatus(styles?.['fontStyle'] === 'italic')
    setBoldStatus(styles?.['fontWeight'] === 'bold')
    setCardAligment(styles?.['aligment'] || 'center')
  }, [currentCard])

  return (
    <div id="form-tooltips" className={styles.cardEditorTooltips}>
      <ColorPicker
        styleProp="color"
        disabledStatus={disabledStatus}
        currentCard={currentCard}
      />
      <FormControl>
        <Select
          disableUnderline
          classes={{
            root: classes.rootSelect,
            select: classes.rootSelect,
          }}
          onChange={e => {
            setCardAligment(e.target.value)
            dispatch(
              changeCardLabelStyles({
                aligment: e.target.value,
              }),
            )
          }}
          disabled={disabledStatus}
          value={cardAligment || 'center'}
          IconComponent={() => <></>}
        >
          <MenuItem value="center">
            <FormatAlignCenterIcon />
          </MenuItem>
          <MenuItem value="right">
            <FormatAlignRightIcon />
          </MenuItem>
          <MenuItem value="left">
            <FormatAlignLeftIcon />
          </MenuItem>
        </Select>
      </FormControl>
      <button
        className={boldStatus ? styles.buttonStyleActive : ''}
        onClick={() => {
          dispatch(
            changeCardLabelStyles({
              fontWeight: !boldStatus ? 'bold' : 'unset',
            }),
          )
          setBoldStatus(!boldStatus)
        }}
      >
        B
      </button>
      <button
        onClick={() => {
          dispatch(
            changeCardLabelStyles({
              fontStyle: !italicStatus ? 'italic' : 'normal',
            }),
          )
          setItalicStatus(!italicStatus)
        }}
        className={`${styles.italicButton} ${
          italicStatus && styles.buttonStyleActive
        }`}
      >
        I
      </button>
    </div>
  )
}

export default CardEditorTooltips
