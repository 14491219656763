import React, {FC} from "react";
import {BrandData} from "../../api/types";
import {GridComponent} from "../Reusable/GridComponent";

export const BrandGridComponent: FC<
  {
    item: BrandData,
    selected: boolean,
  }> = ({
  item, selected
}) => {

  return (
    <GridComponent selected={selected} key={item.id} thumbnail={item.imageUrl ?? item.posterUrl} label={item.name} />
  )
}