import { Button, Checkbox, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import SequenceBrand from 'src/components/SequenceBrand/SequenceBrand'
import { RootState } from 'src/redux/store'
import StepOnePlaceholderImage from '../../../assets/brand-step-one.png'
import MoreIconSvg from '../../../assets/more-icon.svg'
import {ShareableUserData, Settings} from "../../../api/types";
import ExperienceSettingsComponent from "../ExperienceSettingsComponent";
import {useAppContext} from "../../../contexts/PermissionContext";

interface Props {
  isAbleToCreate: boolean
  showWidgetSettings: boolean
  setShowWidgetSettings: React.Dispatch<React.SetStateAction<boolean>>
  link: ShareableUserData,
  setLink: React.Dispatch<React.SetStateAction<ShareableUserData>>,
  settings: Settings,
  setSettings: React.Dispatch<React.SetStateAction<Settings>>,
  handleSaveSettings: (Settings?) => void,
  handleChangeStep: (step: 'next' | 'back') => void
  linkRequired: Boolean,
}

const StepCreate = ({
  isAbleToCreate,
  showWidgetSettings,
  setShowWidgetSettings,
  link,
  setLink,
  settings,
  setSettings,
  handleSaveSettings,
  handleChangeStep,
  linkRequired,
}: Props) => {
  const sequencePollId = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceId,
  )
  const { permissions } = useAppContext()
  return (
    <div>
      <div className="home-step-one-form">
        <div className="home-step-one-form-inner">
          <SequenceBrand
            sequencePollId={sequencePollId}
            settings={settings}
            setSettings={setSettings}
            link={link}
            setLink={setLink}
            linkRequired={linkRequired}
          />
          <div className="editor-buttons">
            <div className="home-buttons-wrapper">
              <Button
                disabled={!isAbleToCreate}
                variant="outlined"
                color="primary"
                style={{ minWidth: '250px', marginLeft: '25px' }}
                onClick={() => {
                  handleChangeStep('next')
                  toast.info('Start creating something amazing!')
                }}
              >
                {sequencePollId ? 'Edit' : 'Create'}
              </Button>
              <div className="home-widget-checkbox-status">
                <Button
                  onClick={() => {
                    setShowWidgetSettings(!showWidgetSettings)
                  }}
                >
                  Widget settings{' '}
                  <img
                    width={16}
                    height={16}
                    style={{ marginLeft: '8px' }}
                    src={MoreIconSvg}
                  />
                </Button>
                { permissions?.experience?.updateWidgetSettings && (
                  <>
                    <Checkbox
                      color="primary"
                      value={settings.widget?.displaySettings?.enabled}
                      checked={settings.widget?.displaySettings?.enabled}
                      onChange={() => {
                        let newSettings = {
                          ...settings,
                          widget: {
                            ...settings.widget,
                            displaySettings: {
                              ...settings.widget?.displaySettings,
                              enabled: !settings.widget?.displaySettings?.enabled
                            }
                          }
                        }
                        setSettings(newSettings)
                        handleSaveSettings(newSettings)
                      }}
                    />
                    <Typography className="widget-settings__enabled-checkbox">
                      Enabled
                    </Typography>
                  </>
                ) }
              </div>
            </div>
          </div>
        </div>
        <div className="step-one-image">
          <img src={StepOnePlaceholderImage} alt="step-one" />
        </div>
      </div>
      {showWidgetSettings && (
        <ExperienceSettingsComponent
          onDismiss={() => setShowWidgetSettings(false) }
          handleSaveChanges={handleSaveSettings}
          settings={settings}
          setSettings={setSettings}
          experienceId={sequencePollId}
        />
      )}
    </div>
  )
}

export default StepCreate
