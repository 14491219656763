import {FC} from "react";
import {Auth0Provider} from "@auth0/auth0-react";
import {
  IS_PRODUCTION, REACT_APP_DEV_AUDIENCE,
  REACT_APP_DEV_CLIENT_ID,
  REACT_APP_PROD_AUDIENCE,
  REACT_APP_PROD_CLIENT_ID
} from "../../constants";
import {history} from "../../history";
import {Auth0Login} from "./Auth0Login";


export const Auth0LoginProvider: FC<{

}> = ({

}) => {



// the following is for automatic subscription selection
  let auth0RedirectUri = window.location.origin

  let clientProps = {}
  if (window.location.href.indexOf("?tier=Creator") > -1 || window.location.href.indexOf("?tier=Business") > -1) {
    auth0RedirectUri = window.location.href
    clientProps = {
      // this will make sure sign up form variant is shown to users if tier query parameter is in URL
      screen_hint: "signup"
    }
  }

  const redirectCallback = appState => {
    let tier = new URLSearchParams(window.location.search).get('tier')
    if (tier) {
      // sign up URL may contain a ?tier=Business or ?tier=Creator which will get picked up by SidebarLayout and automatic subscription selection happens
      history.replace(
        appState && appState.returnTo ? appState.returnTo + "?tier=" + tier : window.location.href,
      )
    } else {

      history.replace(
        appState && appState.returnTo ? appState.returnTo : window.location.href,
      )
    }
  }

  return (

    <Auth0Provider
      {...clientProps}
      domain="contester.eu.auth0.com"
      clientId={
        (IS_PRODUCTION ? REACT_APP_PROD_CLIENT_ID : REACT_APP_DEV_CLIENT_ID) ||
        ''
      }
      redirectUri={auth0RedirectUri}
      audience={
        IS_PRODUCTION ? REACT_APP_PROD_AUDIENCE : REACT_APP_DEV_AUDIENCE
      }
      onRedirectCallback={redirectCallback}
      scopes="read:default"
    >
      <Auth0Login/>
    </Auth0Provider>
  )
}