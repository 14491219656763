import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BrandData } from '../../api/types'
import {
  brandsGetAssignableBrands,
} from '../../api/services'

interface IBrandSliceState {
  brands: BrandData[]
}

export const getAssignableBrandsDataThunk = createAsyncThunk(
  'brands/getBrands',
  async () => {
    let brands = await brandsGetAssignableBrands({ page: 0, perPage: 1000 })
    return { brands }
  },
)

let initialState: IBrandSliceState = {
  brands: [],
}

const brandSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAssignableBrandsDataThunk.fulfilled, (state, action) => {
      state.brands = action.payload.brands.data.content
    })
  },
})

export default brandSlice.reducer
