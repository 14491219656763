import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material'
import React from 'react'
import { PlayLesson, TextSnippet } from '@mui/icons-material'
import { IS_PRODUCTION } from '../../../constants'

function SidebarBottomLinks() {
  return (
    <>
      <MenuList>
        {!IS_PRODUCTION && (
          <a
            href={
              window.location.pathname +
              '?utm_enabled=true&utm_source=contester&utm_medium=creator&utm_campaign=tutorial'
            }
          >
            <MenuItem>
              <ListItemIcon>
                <PlayLesson fontSize="small" />
              </ListItemIcon>
              <ListItemText>Tutorial</ListItemText>
            </MenuItem>
          </a>
        )}
        {/*<a href={'https://docs.contester.net'} target={'_blank'}>*/}
        {/*  <MenuItem>*/}
        {/*    <ListItemIcon>*/}
        {/*      <TextSnippet fontSize="small" />*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText>Docs</ListItemText>*/}
        {/*  </MenuItem>*/}
        {/*</a>*/}
        {/*<MenuItem>*/}
        {/*  <ListItemIcon>*/}
        {/*    <LiveHelp fontSize="small" />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText>Live chat</ListItemText>*/}
        {/*</MenuItem>*/}
      </MenuList>
    </>
  )
}

export default SidebarBottomLinks
