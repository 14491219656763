import {FC} from "react";
import {Divider, Grid, IconButton, Typography} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {toast} from "react-toastify";

export const CodeDisplay: FC<{
  code: string,
  title?: string,
}> = ({
  code,
  title,
}) => {

  const styles = {
    cred: {
      border: "1px dashed #dadada",
      backgroundColor: "#f5f5f5",
      borderRadius: "5px",
      padding: "5px",
      marginBottom: "10px",

    },
  }

  let copy = (value: string) => {
    toast.info('Code is copied to the clipboard', {
      autoClose: 1000,
    })
    navigator.clipboard.writeText(value)
  }

  return (
    <>
      { code && (
        <Grid container style={styles.cred}>
          { title && (
            <Grid item xs={12}>
              <Typography variant={"h6"}>
                {title}
              </Typography>
              <Divider sx={{margin: "10px"}}/>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography sx={{ wordWrap: "break-word", fontSize: "12px" }}>
              {code}
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <Grid container justifyContent={"flex-end"}>
              <Grid item>
                <IconButton sx={{ padding: "4px" }} onClick={() => copy(code)}><ContentCopyIcon fontSize={"small"}/></IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}