import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/redux/store'

export type EditorMode = "Editor" | "Interactive"
interface State {
  step: number,
  mode: EditorMode
}

const initialState: State = {
  step: 1,
  mode: "Editor",
}

const creatorSlice = createSlice({
  name: 'createSlice',
  initialState,
  reducers: {
    setStep(state, action: PayloadAction<number>) {
      state.step = action.payload
    },
    setMode(state, action: PayloadAction<EditorMode>) {
      state.mode = action.payload
    }
  },
})

export const selectStep = (state: RootState) => state.creatorReducer.step
export const selectMode = (state: RootState) => state.creatorReducer.mode

export const { setStep, setMode } = creatorSlice.actions
export default creatorSlice.reducer
